import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { IIngredient, ISearchIngredient } from "../../../../interfaces/ingredient";
import Switch from "react-switch";
import config from "../../../../utils/config";
import { truncate } from "../../../../utils/string";
import SuitabilityBar from "../../../../components/suitabilityBar";
import { IPaginateList } from "../../../../interfaces/pagination";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import { IApplicationState } from "../../../../redux/reducers";
import ingredientService from "../../../../services/ingredientService";
import { connect } from "react-redux";
import { TableSkeleton, ImageSkeleton } from "../../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	term: string | undefined;
	searchIngredients: ISearchIngredient[];
	ingredients: IPaginateList<IIngredient[]>;
	getPortfolioIngredients: (term?: string) => Promise<any>;
}

const IngredientResults: React.FC<IProps> = ({
	term,
	searchIngredients,
	ingredients,
	getPortfolioIngredients,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [showAllIngredients, setShowAllIngredients] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		getPortfolioIngredients(term).finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		setLoading(true);
		getPortfolioIngredients(term).then(() => setLoading(false));
	}, [term]);

	return (
		<Card>
			<CardTitle>
				<i className={"icon-nav-ingredients"} />
				<h4>Ingredients</h4>
				<span>Show All</span>
				<Switch
					onChange={(checked) => setShowAllIngredients(checked)}
					checked={showAllIngredients}
					onColor="#3F65F1"
					onHandleColor="#2693e6"
					handleDiameter={18}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={15}
					width={30}
				/>
			</CardTitle>
			<CardBody
				style={loading ? { display: "flex", alignItems: "center", justifyContent: "center" } : {}}
			>
				<Table className={"portfolio-table"}>
					<colgroup>
						<col className="img" />
						<col />
						<col className="persona-score" />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
						</tr>
					</thead>
					{loading ? (
						<TableSkeleton numCells={2} numRows={3} noAction />
					) : (
						<tbody>
							{term && !showAllIngredients
								? searchIngredients.map((i: ISearchIngredient, index: number) => {
										return (
											<tr
												key={index}
												onClick={() => props.history.push(`/app/ingredients/${i.id}`)}
											>
												<td>
													<ReactImageFallback
														src={config.api.endpoints.ingredients.ingredientImage(
															i.jf_display_name
														)}
														fallbackImage={getIngredientImagePlaceholder()}
														initialImage={<ImageSkeleton className={"table-img-ghost"} />}
														alt="Ingredient Image"
													/>
												</td>
												<td>{truncate(i.jf_display_name, 12)}</td>
											</tr>
										);
								  })
								: ingredients.list?.map((i: IIngredient, index: number) => {
										return (
											<tr
												key={index}
												onClick={() => props.history.push(`/app/ingredients/${i.id}`)}
											>
												<td>
													<ReactImageFallback
														src={config.api.endpoints.ingredients.ingredientImage(i.name)}
														fallbackImage={getIngredientImagePlaceholder()}
														initialImage={<ImageSkeleton className={"table-img-ghost"} />}
														alt="Ingredient Image"
													/>
												</td>
												<td>{truncate(i.name, 12)}</td>
											</tr>
										);
								  })}
						</tbody>
					)}
				</Table>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchIngredients: state.ingredient.searchIngredients,
	ingredients: state.ingredient.ingredients,
	term: state.search.term
});

const mapDispatchToProps = {
	getPortfolioIngredients: (term?: string) => ingredientService.getIngredients(term)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IngredientResults));
