import React, { useEffect } from "react";
import { DropdownMenu, DropdownItem } from "reactstrap";
import { INotification } from "../../../../interfaces/notification";
import { Link } from "react-router-dom";
import NotificationDropdownTile from "./NotificationDropdownTile";
import notificationService from "../../../../services/notificationService";
import { connect } from "react-redux";

interface IProps {
	notifications: INotification[];
	getAllNotifications: () => Promise<any>;
}

const NotificationDropdown: React.FC<IProps> = ({ notifications, getAllNotifications }) => {
	useEffect(() => {
		return () => {
			getAllNotifications();
		};
	}, []);

	const sortByDate = () => {
		return notifications.sort(function (a, b) {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
	};

	return (
		<div className="header-notification-container">
			<DropdownMenu>
				{
					<div className="dropdown__notifications">
						<>
							{notifications.length ? (
								// notifications.map(notification => ( ** TEMPORARILY FILTER 'New Ingredient Added' notifications
								sortByDate()
									// .concat(DEMO_NOTIFICATIONS.filter(n => n.message_type === 'Recommendation'))
									.filter((n: INotification) => n.message !== "New Ingredient Added" && !n.is_read)
									.map((notification) => <NotificationDropdownTile notification={notification} />)
							) : (
								<div className="empty">No Notifications</div>
							)}
							<DropdownItem>
								<span className="see-all">
									<Link to="/app/notifications">View All</Link>
								</span>
							</DropdownItem>
						</>
					</div>
				}
			</DropdownMenu>
		</div>
	);
};

const mapDispatchToProps = {
	getAllNotifications: () => notificationService.getAllNotifications()
};
export default connect(null, mapDispatchToProps)(NotificationDropdown);
