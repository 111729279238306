import React from "react";
import { ArrowRight } from "react-feather";
import { ImageSkeleton } from "../../../../utils/ghostUI";

const RecommendationLoader: React.FC = ({ ...props }) => {
	return (
		<>
			<div className="rec rec-ghost">
				<span>
					<ImageSkeleton className="dash-recommendation-img-ghost" />
					<p></p>
				</span>
				<ArrowRight size={32} />
				<span>
					<ImageSkeleton className="dash-recommendation-img-ghost" />
					<p></p>
				</span>
			</div>
			<div className="rec rec-ghost">
				<span>
					<ImageSkeleton className="dash-recommendation-img-ghost" />
					<p></p>
				</span>
				<ArrowRight size={32} />
				<span>
					<ImageSkeleton className="dash-recommendation-img-ghost" />
					<p></p>
				</span>
			</div>
		</>
	);
};

export default RecommendationLoader;
