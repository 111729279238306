import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import RegisterFormFIelds, { IRegisterFormElementsValues } from "./registerFormElements";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import { RegisterValidation } from "../../../../validations/registerValidation";
import { IRegisterRequest, IRegisterUserResponse } from "../../../../interfaces/user";

interface IRegisterForProps {
	register: (
		request: IRegisterRequest,
		resolve?: Function,
		reject?: Function
	) => Promise<IRegisterUserResponse>;
	setModal: (isOpen: boolean) => void;
}

const RegisterForm: React.FC<
	InjectedFormikProps<IRegisterForProps, IRegisterFormElementsValues>
> = (props) => {
	return (
		<Form className="mt-1" onSubmit={props.handleSubmit}>
			<RegisterFormFIelds {...props} />

			<Button
				type="submit"
				className="btn-green color-white"
				style={{ color: "white" }}
				loading={props.isSubmitting}
				disabled={!props.isValid}
			>
				Register
			</Button>
		</Form>
	);
};

const WrappedForm = withFormik<IRegisterForProps, IRegisterFormElementsValues>({
	enableReinitialize: true,
	validationSchema: RegisterValidation,
	mapPropsToValues: () => ({
		firstName: "",
		lastName: "",
		company: "",
		jobtitle: "",
		city: "",
		state: "",
		country: "",
		email: "",
		password: "",
		confirmPassword: ""
	}),
	handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
		try {
			setSubmitting(true);
			await props.register(values);
			props.setModal(true);
		} catch (e) {
			if (e.response?.status == 404) {
				setFieldError("email", "Email is already in use");
			}
		} finally {
			setSubmitting(false);
		}
	}
});

const mapDispatchToProps = (dispatch: any) => ({
	register: async (registerData: IRegisterRequest, resolve?: Function, reject?: Function) =>
		dispatch(userService.register(registerData, resolve, reject))
});

export default connect(null, mapDispatchToProps)(WrappedForm(RegisterForm));
