export const ITEMS_PER_PAGE = 20;

export const REPORTS_ALLOWED_FILE_EXTENSIONS = ["application/pdf", "image/*", ".csv", ".xlsx"];

export const PREFERENCE_TYPES = [
	"Ingredient Categories",
	"Sub Categories",
	"Form",
	"Storage Conditions",
	"Processes",
	"Dietary/Functional",
	"Allergens",
	"Harvest Type",
	"Season",
	"Regional",
	"Cost"
];
