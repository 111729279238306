import React, { useEffect, useState } from "react";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import Brand from "./components/brand/";
import Markets from "./components/markets";
import CompanyTab from "./components/company";
import userServices from "../../../services/userService";
import companyServices from "../../../services/companyService";
import { IUser } from "../../../interfaces/user";
import Loader from "../../../components/loader";
import Button from "../../../components/common/button";
import { ListGroup, ListGroupItem, Input, Table, Nav, NavItem } from "reactstrap";
import userService from "../../../services/userService";
import { Trash2, User } from "react-feather";
import { ICompany } from "../../../interfaces/company";
import productTypeService from "../../../services/productTypeService";

interface IProps {
	users: IUser[];
	getAllCompanyUsers: () => Promise<any>;
	addCompanyUser: (email: string) => Promise<any>;
	updateUserRole: (userId: string, role: string) => Promise<any>;
	removeUser: (userId: string) => Promise<any>;
	updateCompanyPlantBased: (plant_based: string) => Promise<any>;
	updateCompanyTypes: (types: { id: string }[]) => Promise<any>;
	removeCompanyType: (id: string) => Promise<any>;
}

const TABS = [
	{
		key: "brands",
		name: "Brands"
	},
	{
		key: "markets",
		name: "Markets"
	},
	{
		key: "company",
		name: "Company Type"
	},
	{
		key: "users",
		name: "Users"
	}
];

const Company: React.FC<IProps> = ({
	users,
	getAllCompanyUsers,
	addCompanyUser,
	updateUserRole,
	removeUser,
	updateCompanyPlantBased,
	removeCompanyType,
	updateCompanyTypes
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [updating, setUpdating] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [activeTab, setActiveTab] = useState<string>(TABS[0].key);

	useEffect(() => {
		setLoading(true);
		getAllCompanyUsers().finally(() => {
			setLoading(false);
		});
	}, []);

	const onAddCompanyUser = () => {
		setLoading(true);
		addCompanyUser(email).finally(() => {
			setEmail("");
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	const onUserRoleChanged = (userId: string, role: string) => {
		setLoading(true);
		updateUserRole(userId, role).finally(() => {
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	const onUserRemove = (userId: string) => {
		setLoading(true);
		removeUser(userId).finally(() => {
			getAllCompanyUsers().finally(() => {
				setLoading(false);
			});
		});
	};

	return (
		<div style={{ height: "100%" }}>
			<div className="tab-navigation" style={{ marginBottom: 22 }}>
				<Nav tabs>
					{TABS.map((t) => (
						<NavItem
							key={t.key}
							className={`nav-link h5 mb-0 ${t.key === activeTab ? "active" : ""}`}
							style={{ cursor: t.key !== activeTab ? "pointer" : "default" }}
							onClick={() => setActiveTab(t.key)}
						>
							{t.name}
						</NavItem>
					))}
				</Nav>
			</div>
			{activeTab === "brands" && <Brand />}
			{activeTab === "markets" && <Markets />}
			{activeTab === "company" && (
				<CompanyTab
					removeCompanyType={removeCompanyType}
					updateCompanyTypes={updateCompanyTypes}
					updateCompanyPlantBased={updateCompanyPlantBased}
				/>
			)}
			{activeTab === "users" && (
				<Loader isLoading={loading}>
					<div className="company-users">
						<ListGroup>
							<ListGroupItem className="d-flex invite-user">
								<Input
									placeholder="User Email"
									className="px-2"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<Button
									onClick={onAddCompanyUser}
									disabled={loading || email === ""}
									className="color-white"
								>
									Invite User
								</Button>
							</ListGroupItem>
						</ListGroup>

						<Table borderless>
							{/* <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>ROLE</th>
                                    <th>Approved</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th></th>
                                </tr>
                            </thead> */}
							<tbody>
								{users.map((user: IUser) => {
									return (
										<tr
											className={user.disable ? "text-muted" : ""}
											style={{ borderBottom: "1px solid #cedbe9" }}
										>
											<td style={{ paddingTop: 25 }}>
												<User style={{ marginRight: 20 }} />
												{user.first_name} {user.last_name}
											</td>
											<td style={{ paddingTop: 25 }}>{user.email}</td>
											{/* <td style={{paddingTop: 25}}>{user.role}</td> */}
											<td>
												<div className="role-dropdown">
													<select
														value={user.role}
														onChange={(e) => {
															onUserRoleChanged(user.id, e.target.value);
														}}
														className="form-control"
													>
														<option value="COMPANY_ADMIN">COMPANY_ADMIN</option>
														<option value="COMPANY_USER">COMPANY USER</option>
													</select>
												</div>
											</td>
											{/* <td>{user.is_active ? 'Yes' : 'No' }</td> */}
											{/* <td>{user.disable ? 'Disabled' : 'Enabled'}</td> */}
											<td style={{ paddingTop: 25 }}>{user.company?.name}</td>
											{/* <td>{moment(user.created_at).format('MMM DD YYYY')}</td> */}
											<td style={{ textAlign: "right" }}>
												<Button
													onClick={() => onUserRemove(user.id)}
													className="btn-danger text-white"
												>
													<Trash2 size={14} />
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				</Loader>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	users: state.user.users
});

const mapDispatchToProps = {
	getAllCompanyUsers: () => userServices.getAllCompanyUsers(),
	addCompanyUser: (email: string) => userService.addCompanyUser(email),
	updateUserRole: (userId: string, role: string) => userService.updateUserRole(userId, role),
	removeUser: (userId: string) => userService.removeUser(userId),
	updateCompanyPlantBased: (plant_based: string) =>
		companyServices.updateCompanyPlantBased(plant_based),
	updateCompanyTypes: (types: { id: string }[]) => companyServices.updateCompanyType(types),
	removeCompanyType: (id: string) => companyServices.removeCompanyType(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
