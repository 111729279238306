import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import LoginFormElements, { ILoginFormElementsValues } from "./loginFormElements";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import { LoginSchema } from "../../../../validations/loginValidation";
import Button from "../../../../components/common/button";
import userService  from "../../../../services/userService";
import { ILoginRequest, ILoginUserResponse } from "../../../../interfaces/user";

interface ILoginFormProps {

    login: (request: ILoginRequest, resolve?: Function, reject?: Function) => Promise<ILoginUserResponse>
}


const LoginForm: React.FC<
    InjectedFormikProps<ILoginFormProps, ILoginFormElementsValues>
> = (props) => {

    return (
        <Form onSubmit={props.handleSubmit}>
            <LoginFormElements {...props} />

            <Button type="submit" className="btn-green color-white" style={{color: "white"}} loading={props.isSubmitting} disabled={!props.isValid}>Login</Button>

        </Form>
    )
}

const WrappedForm = withFormik<ILoginFormProps, ILoginFormElementsValues>({
    enableReinitialize: true,
    validationSchema: LoginSchema,
    mapPropsToValues: () => ({
        username: '',
        password: ''
    }),
    handleSubmit: async ({
        username,
        password
    }, { props, setSubmitting }) => {

        setSubmitting(true)
        await props.login({
            password,
            username,
        });
        setSubmitting(false);


    }

})

const mapDispatchToProps = (dispatch: any) => ({
    login: ({ username, password }: ILoginRequest, resolve?: Function, reject?: Function) => dispatch(userService.login(username, password, resolve, reject))
})


export default connect(null, mapDispatchToProps)(WrappedForm(LoginForm));