import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/common/button";
import { Table } from "reactstrap";
import { IApplicationState } from "../../redux/reducers";
import adminService from "../../services/adminService";
import PermissionToggle from "./components/permissionToggle";
import { IUser } from "../../interfaces/user";
import { USER_ROLES } from "../../interfaces/roles";
import { COMPANY_PERMISSIONS } from "../../interfaces/permissions";
import {
    Input,
    Modal, 
    ModalHeader, 
    ModalFooter, 
    ModalBody,
    Form, 
    FormGroup, 
    Label,
    InputGroup,
    Button as ReactstrapButton,
    ButtonGroup,
    Row,
    Col,
} from 'reactstrap';

import {
    Circle,
    Globe,
    Loader,
    User,
    X,
} from 'react-feather';
import { route, user } from "../../redux/actions/actionContants";
import { ICompany } from "../../interfaces/company";
import companyService from "../../services/companyService";
import Select, { ValueType } from "react-select";
import { toCamelCase } from "../../utils/string";

interface IProps {
    companies: ICompany[]; 
    getCompanies: () => any;
    updateCompanyPermissions: (company: ICompany, permission: { [key: string]: string; }) => Promise<any>;
    updateCompanySubscription: (subscriptionType: string, companyId: string) => Promise<any>;
}


const CompaniesTable: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompany>();
    const [updating, setUpdating] = useState<boolean>(false)

    const selectCompany = (company: ICompany) => {
        setCompany(company)
        setModal(true)
    }

    const toggleModal = () => {
        setModal(!modal)
    }

    const onModifyCompany = (company: ICompany, permission: {}) => {
        setUpdating(true)
        setCompany({...company, ...permission})
        props.updateCompanyPermissions(company, permission).finally(() => {
            setUpdating(false);
        })
    }

    const onModifyCompanySubscription = (option: any) => {
        if(company && option) {
            setUpdating(true);
            props.updateCompanySubscription(option.value, company.id).finally(() => {
                setUpdating(false);
            })
        }
    }

    const handlePermissionChange = (company: ICompany, permission: COMPANY_PERMISSIONS, action: string) => {
        let updated_permission = { [permission.toLowerCase()]: action };
        onModifyCompany(company, updated_permission);
    }

    function companyHasPermission(company: ICompany, permission: COMPANY_PERMISSIONS) {
        const formatted_permission  = permission.toLowerCase();
        return companyService.companyHasPermission(company, formatted_permission);
    }

    useEffect(() => {
        setLoading(true)
        props.getCompanies().finally(() => {
          setLoading(false)
        })
    }, [])

    return (
        <div className="user-requests">

            <Table responsive hover>
                <thead>
                    <tr key='company-table-header'>
                        <th>Company</th>
                        <th>Email</th>
                        <th>ID (last 5 characters)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.companies.map((company, index) => (
                            <tr key={company.id}>
                                <td>{company.name}</td>
                                <td>{company.email}</td>
                                <td>{company.id.substring(company.id.length - 5)}</td>
                                <td>
                                    <div className="actions float-right">
                                        <Button className="color-white approve" icon="fa fa-edit" onClick={() => selectCompany(company)} />
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>

            { company && (
                <Modal isOpen={modal}>
                    <ModalHeader>
                        <Globe className="color-bg-blue mr-2"  size={24}/>
                        { `${company?.name}` }
                        <X className="float-right" onClick={toggleModal} />
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup className="mb-3">
                                <Label>Email</Label>
                                <Input 
                                    value={company.email}
                                    disabled={true}
                                    style={{ width: '100%', padding: '8px' }}
                                />
                            </FormGroup>
                            <Row className={'mb-3'}>
                                <Col md={10} className={'mb-3'}>
                                    <span className={'h5'}>Subscription</span>
                                    <Select 
                                        className={'mt-3'}
                                        defaultValue={{label: toCamelCase(company?.subscription_type), value: company?.subscription_type}}
                                        onChange={(option) => onModifyCompanySubscription(option)}
                                        options={[
                                            {label: 'Legacy', value: 'legacy'},
                                            {label: 'Supplier', value: 'supplier'},
                                            {label: 'Fresh', value: 'fresh'},
                                            {label: 'Growth', value: 'growth'},
                                            {label: 'Enterprise', value: 'enterprise'},
                                            {label: 'Group', value: 'group'},
                                            {label: 'None', value: ''},
                                        ]}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                zIndex: 5,
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                            {/* Feature Management */}
                            <Row className={'mb-3'}>
                                <Col md={10} className={'mb-3'}><span className={'h5'}>Feature Management</span></Col>
                                {Object.keys(COMPANY_PERMISSIONS).map(key => {
                                    return ( 
                                        <Col md={6} key={key}>
                                            <PermissionToggle company={company} permission={COMPANY_PERMISSIONS[key as COMPANY_PERMISSIONS]} handlePermissionChange={handlePermissionChange} companyHasPermission={companyHasPermission} />
                                        </Col>
                                    )
                                })}
                            </Row>  
                        </Form>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            )}


        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        companies: state.company.companies,
    }
}

const mapDispatchToProps = ({
    getCompanies: () => companyService.getCompanies(),
    updateCompanyPermissions: (company: ICompany, permission: { [key: string]: string; }) => companyService.updateCompanyPermissions(company, permission),
    updateCompanySubscription: (subscriptionType: string, companyId: string) => adminService.updateCompanySubscription(subscriptionType, companyId),
})



export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);