import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import AccountNotifications from "../components/accountNotifications";
import BarChart from "../components/barChart";
import ProductOverview from "../components/productOverview/productOverview";
import RecentlyViewed from "../components/recentlyViewed/recentlyViewed";
import PackagingCard from "../components/packaging";
import PersonasCard from "../components/personas";
import PortfolioScore from "../components/portfolioScore";
import CostChart from "../components/costAnalysis";
import Reccomendations from "../components/recommendations/recommendations";
import { IApplicationState } from "../../../redux/reducers";
import userService from "../../../services/userService";
import { AlertTriangle } from "react-feather";
import under_construction from "../../../assets/images/scientist_lab.png";

interface IProps extends RouteComponentProps {
	subscription_type: string | undefined;
	getUserProfile: () => Promise<any>;
}

const Dashboard: React.FC<IProps> = ({ ...props }) => {
	const [isLoading, setIsLoading] = useState<boolean>();

	useEffect(() => {
		if (!props.subscription_type) {
			setIsLoading(true);
			props.getUserProfile().finally(() => setIsLoading(false));
		}
	}, []);

	const companyHasAccess = () => {
		return props.subscription_type != "supplier";
	};

	return (
		<div className="dash-v2 d-flex justify-content-center">
			{isLoading ? (
				<></>
			) : !companyHasAccess() ? (
				<div
					className={"under-construction"}
					style={{ marginRight: "unset", marginLeft: "unset", marginTop: "10vh" }}
				>
					<span>
						<AlertTriangle size={24} color={"#f4a850"} />
						Under Construction
					</span>
					<p>We are hard at work to bring this feature to you and your team!</p>
					<img src={under_construction} height={100} width={100}></img>
					<p style={{ textAlign: "center" }}>
						Thank you for your patience as we work to <i style={{ color: "#3f65f1" }}>supply</i> our
						suppliers with the best dashboard experience possible. If you want to go the extra mile
						and <i style={{ color: "#3f65f1" }}>supply</i> us with your feeback on what you would
						like to see in a dashboard experience, we would love to hear from you at{" "}
						<a href="mailto:hello@journeyfoods.com?subject=Supplier Dashboard">
							{" "}
							hello@journeyfoods.com{" "}
						</a>
						.
					</p>
				</div>
			) : (
				<Row>
					{/* Right, Narrow Column */}
					<Col lg="3" className="col-side">
						<Row className={"mb-3"}>
							<AccountNotifications />
						</Row>
						<Row>
							<Reccomendations />
						</Row>
						<Row>
							<PackagingCard />
						</Row>
						<Row>
							<PersonasCard />
						</Row>
					</Col>
					{/* Middle, Wide Column */}
					<Col sm="auto" className="col-middle">
						<Row>
							<BarChart />
						</Row>
						{/* <Row>
							<CostChart />
						</Row> */}
						<Row>
							<RecentlyViewed />
						</Row>
					</Col>
					{/* Left, Narrow Column */}
					<Col lg="3" className="col-side right">
						<PortfolioScore />
						<ProductOverview />
					</Col>
				</Row>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	subscription_type: state.user.profile?.company.subscription_type
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
