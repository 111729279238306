import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";




export interface ILoginFormElementsValues {
    username: string;
    password: string;
}

interface ILoginFormElementsProps {

}



const LoginFormElements: React.FC<InjectedFormikProps<ILoginFormElementsProps, ILoginFormElementsValues>> = (props) => {

    const {
        values: { username, password },
        handleChange,
        touched,
        handleBlur,
        errors,
    } = props;


    return (

        <>
            <Input
                label="Email address"
                name="username"
                placeholder="Your Username"
                value={username}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                handleOnBlur={handleBlur}
            />
            <Input
                label="Password"
                type="password"
                name="password"
                placeholder="Your Password"
                value={password}
                handleChange={handleChange}
                errors={errors}
                handleOnBlur={handleBlur}
                touched={touched}
            />
        </>
    )

}

export default LoginFormElements;