import React from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "../routes";
import Labels from "./labels";

const LabelRouter: React.FC = () => (
    <Switch>
        <Route exact path={routes.LABELS} component={Labels}/>
    </Switch>
)

export default LabelRouter;