import { ILineData } from "./interfaces";

const monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec"
];

export const calculateLabels = (range: number) => {
	const currDate = new Date().getMonth() + 1;
	const startDate = currDate - range;
	const labels: string[] = [];

	for (let i = startDate; labels.length < range; i++) {
		let k = i; // Allow for circular iteration over month names
		if (k < 0) k = monthNames.length + k;
		if (k > 11) k = k - monthNames.length;
		labels.push(monthNames[k]);
	}
	return labels;
};

export const sampleCostData = (range: number = 6): ILineData => ({
	labels: calculateLabels(range),
	datasets: [
		{
			label: "Cost Savings",
			data: [33, 53, 85, 41, 44, 65, 64, 67, 70, 72, 80, 79].slice(0, range),
			fill: true,
			backgroundColor: "rgba(63,101,241,0.1)",
			borderColor: "rgba(63,101,241,1)"
		},
		{
			label: "Average",
			data: [33, 25, 35, 51, 54, 76, 74, 70, 81, 73, 82, 80].slice(0, range),
			fill: false,
			borderColor: "#cedbe9"
		}
	]
});

export const sampleSustainabilityData = (range: number = 6): ILineData => ({
	labels: calculateLabels(range),
	datasets: [
		{
			label: "Relative Sustainability Improvement",
			data: [15, 33, 40, 41, 48, 51, 55, 56, 50, 65, 66, 70].slice(0, range),
			fill: true,
			backgroundColor: "rgba(14,215,172,0.1)",
			borderColor: "rgba(14,215,172,1)"
		},
		{
			label: "Average User",
			data: [33, 25, 35, 51, 54, 76, 75, 70, 81, 79, 80, 85].slice(0, range),
			fill: false,
			borderColor: "#cedbe9"
		}
	]
});

export const samplePackageData: ILineData = {
	labels: ["Sept", "Oct", "Nov", "Dec", "Jan", "Feb"],
	datasets: [
		{
			label: "Relative Package Improvement",
			data: [15, 33, 40, 41, 48, 51],
			fill: true,
			backgroundColor: "rgba(14, 215, 172,0.1)",
			borderColor: "rgba(14, 215, 172,1)"
		},
		{
			label: "Average User",
			data: [33, 25, 35, 51, 54, 76],
			fill: false,
			borderColor: "#cedbe9"
		}
	]
};

export const samplePreferenceData = (range: number = 6): ILineData => ({
	labels: calculateLabels(range),
	datasets: [
		{
			label: "Relative Preference Improvement",
			data: [10, 15, 55, 50, 48, 60, 64, 71, 73, 70, 65, 80].slice(0, range),
			fill: true,
			backgroundColor: "rgba(124,74,169,0.1)",
			borderColor: "rgba(124,74,169,1)"
		},
		{
			label: "Average User",
			data: [33, 25, 35, 51, 54, 76, 75, 80, 82, 83, 85, 87].slice(0, range),
			fill: false,
			borderColor: "#cedbe9"
		}
	]
});

export const sampleProductChangesData = (range: number = 6): ILineData => ({
	labels: calculateLabels(range),
	datasets: [
		{
			label: "Product Changes Improvement",
			data: [10, 15, 14, 21, 22, 24, 36, 32, 28, 30, 31, 18].slice(0, range),
			fill: true,
			backgroundColor: "rgba(244, 168, 80,0.1)",
			borderColor: "rgba(244, 168, 80,1)"
		},
		{
			label: "Average User",
			data: [30, 25, 28, 44, 50, 51, 46, 45, 55, 35, 38, 42].slice(0, range),
			fill: false,
			borderColor: "#cedbe9"
		}
	]
});

export const sampleTimeSavedData = (range: number = 6): ILineData => ({
	labels: calculateLabels(range),
	datasets: [
		{
			label: "Relative Time Saved Improvement",
			data: [10, 15, 14, 21, 22, 24, 30, 32, 45, 47, 50, 52].slice(0, range),
			fill: true,
			backgroundColor: "rgba(63,101,241,0.1)",
			borderColor: "rgba(63,101,241,1)"
		},
		{
			label: "Average User",
			data: [30, 25, 28, 44, 50, 51, 52, 60, 61, 65, 65, 66].slice(0, range),
			fill: false,
			borderColor: "#cedbe9"
		}
	]
});
