import React from "react";
import Ingredients from "./ingredients";
import { Switch } from "react-router-dom";
import PermissionRoute from "../../components/hoc/PermissionRoute";
import IngredientDetail from "./ingredientDetail";
import { routes } from "../routes";



const IngredientRouter: React.FC = () => (

    <Switch>
        <PermissionRoute exact path={routes.INGREDIENTS} component={Ingredients} />
        <PermissionRoute exact path={routes.INGREDIENT_DETAIL} component={IngredientDetail} />
    </Switch>
)




export default IngredientRouter;