import React, { useState } from "react";
import Select from "react-select";
import { defaultStyles } from "./customStyles";

interface ISelectFieldProps {
	options: any;
	placeholder: string;
	width?: number;
	className?: string;
	onChange: (option: any) => void;
	title?: string;
	cssStyles?: React.CSSProperties;
	currentValue?: { label: string; value: any };
	isClearable?: boolean;
	isDisabled?: boolean;
}

const CustomSelect: React.FC<ISelectFieldProps> = ({
	options,
	placeholder,
	width,
	className,
	title,
	cssStyles,
	currentValue,
	isClearable,
	isDisabled,
	onChange
}) => {
	const [selected, setSelected] = useState<boolean>(false);

	return (
		<>
			{title && <h5>{title}</h5>}
			<Select
				value={currentValue}
				styles={defaultStyles(selected, width, cssStyles)}
				className={className || ""}
				options={options}
				placeholder={placeholder}
				isDisabled={isDisabled}
				isClearable={isClearable == undefined ? true : isClearable}
				hideSelectedOptions={false}
				onChange={(option: any) => onChange(option)}
			/>
		</>
	);
};

export default CustomSelect;
