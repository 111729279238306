import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner, Tooltip } from "reactstrap";
import VersionHistory from "../../../components/versionHistory";
import PersonaItem from "../../personas/components/persona.item";
import { Doughnut } from "react-chartjs-2";
import ProductIngredientItem from "./components/productIngredient.item";
import Button from "../../../components/common/button";
import SideForm from "../../../components/sideForm";
import { Edit2, ShoppingCart, Box, Copy, ArrowLeft, Info, Search } from "react-feather";
import Integrations from "../../../components/integrations";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import {
	IProduct,
	IProductPersonas,
	IProductIngredient,
	IProductType,
	IProductPackage
} from "../../../interfaces/products";
import { IPackage, IPackagePreference } from "../../../interfaces/package";
import productService from "../../../services/productService";
import EditProductForm from "./form/editForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/loader";
import { IPersona } from "../../../interfaces/persona";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { History } from "history";
import { routes } from "../../routes";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { ArrowRight, CheckCircle } from "react-feather";

import SuitabilityBar from "../../../components/suitabilityBar";
import { Nav, NavItem, NavLink, Modal, ModalFooter } from "reactstrap";
import PackageItem from "./components/package.item";
import packageImagePlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import packagingService from "../../../services/packagingService";
import ProductRecommendations from "../components/productRecommendations";
import { toCamelCase } from "../../../utils/string";
import recommendationService from "../../../services/recommendationService";
import { IRecommendation } from "../../../interfaces/recommendation";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import NutritionalInfo from "./components/nutritionalInfo";
import VersionComparison from "./components/versionComparison/versionComparison";

interface IProps extends RouteComponentProps<{ id: string }> {
	product: null | IProduct;
	product_types: IProductType[];
	profile: any;
	personas: IProductPersonas;
	packages: IPackage[];
	getProductById: (id: string) => Promise<any>;
	// getProductTypes: () => Promise<any>;
	getProductPersonasById: (id: string) => Promise<any>;
	getProductPackages: (productId: string) => Promise<any>;
	removeProduct: (productId: string) => Promise<any>;
	duplicateProduct: (productId: string) => Promise<any>;
	uploadProductImage: (file: File, productId: string) => Promise<any>;
	addRecentlyViewed: (id: string) => Promise<any>;
	getPackageById: (id: string) => Promise<any>;
	getAllRecommendations: () => Promise<any>;
	history: History;
	recommendations: IRecommendation[];
}

const ProductDetail: React.FC<IProps> = ({
	product,
	getProductById,
	uploadProductImage,
	getProductPackages,
	getProductPersonasById,
	duplicateProduct,
	removeProduct,
	addRecentlyViewed,
	getPackageById,
	getAllRecommendations,
	match,
	personas,
	recommendations,
	packages,
	history,
	profile
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [tab, setTab] = useState<string>("versions");

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const chartRef = useRef<HTMLDivElement>(null);
	const ingredientRef = useRef<HTMLDivElement>(null);
	const [duplication, setDuplication] = useState<boolean>(false);
	const [viewMore, setViewMore] = useState<boolean>(false);
	const [viewAllIngredient, setViewAllIngredient] = useState<boolean>(false);
	const [totalIngredientWeights, setTotalIngredientWeights] = useState<number>(0);
	// Tooltips
	const [duplicateTooltip, setDuplicateTooltip] = useState<boolean>(false);
	const [objectivesTooltip, setObjectivesTooltip] = useState<boolean>(false);
	const [compareTooltip, setCompareTooltip] = useState<boolean>(false);
	// Package views
	const [modalPackage, setModalPackage] = useState<boolean>(false);
	const [selectedPackage, setSelectedPackage] = useState<IPackage>();
	const [viewMorePackage, setViewMorePackage] = useState<boolean>(false);

	const [compareProducts, setCompareProducts] = useState<boolean>(false);

	function toggleForm() {
		setIsOpen(!isOpen);
	}
	const [uploadingProductImage, setUploadingProductImage] = useState<boolean>(false);

	function getChartData(): any[] {
		let total = 0;
		if (product?.ingredients && product?.ingredients.length) {
			product.ingredients.forEach((ingredient) => {
				total += ingredient.weight;
			});
			return product.ingredients.map((item) => ({
				angle: Math.round((item.weight / total) * 360),
				label: item.ingredient.jf_display_name
			}));
		}
		return [];
	}

	useEffect(() => {
		if (product && product.ingredients) {
			setTotalIngredientWeights(
				product.ingredients.map((i) => i.weight).reduce((prev, next) => prev + next, 0)
			);
		}
	}, [product?.ingredients]);

	useEffect(() => {
		const id = match.params.id;
		setLoading(true);
		Promise.all([getProductById(id), getProductPackages(id), getAllRecommendations()]).finally(
			() => {
				setLoading(false);
			}
		);
		addRecentlyViewed(id);
	}, []);

	useEffect(() => {
		if (product?.id) history.push(`${routes.PRODUCTS}/${product?.id}`);
	}, [product?.id]);

	const onDuplicate = () => {
		if (product?.id) {
			setDuplication(true);
			duplicateProduct(product.id).finally(() => {
				setDuplication(false);
			});
		}
	};

	const onCompare = () => {
		setCompareProducts(true);
	};

	function onRemoveHandler() {
		confirmAlert({
			title: "Remove Product",
			message: "Are you sure you want to remove this product?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						if (product?.id) {
							removeProduct(product.id).finally(() => {
								history.push(`${routes.PRODUCTS}`);
							});
						}
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	if (loading) {
		return <Loader isLoading={true} />;
	}

	if (!product) {
		return null;
	}

	const chartData = getChartData();

	function scroll(scroll: boolean) {
		if (ingredientRef && ingredientRef.current) {
			ingredientRef.current.scrollTo({
				top: scroll ? 100 : -100
			});
		}
	}

	const formatedIngredients = () => {
		const allIngredients = product?.ingredients?.map((ingredient: IProductIngredient) => {
			return ingredient.ingredient.dietary;
			// const dietary = ingredient.ingredient.dietary.replace(/\[|\]|\'/g, "").split(", ");
			// return dietary
		});
		const flattenedDietary = allIngredients?.flat(1);
		// Remove duplicates
		const uniqueDietary = flattenedDietary?.filter((c, index) => {
			return flattenedDietary.indexOf(c) === index;
		});
		return uniqueDietary != undefined && uniqueDietary != null ? uniqueDietary : [];
	};

	const onUpdateProductImage = ([{ file }]: ImageListType) => {
		if (!file) {
			return;
		}
		setUploadingProductImage(true);

		if (product)
			uploadProductImage(file, product.id).finally(() => {
				setUploadingProductImage(false);
			});
	};

	const selectPackage = async (p: IPackage | IProductPackage, currentMatch: boolean = false) => {
		setViewMorePackage(false);
		if (currentMatch) {
			await getPackageById(p.id).then((res) => {
				setSelectedPackage(res);
			});
		} else {
			setSelectedPackage(p as IPackage);
		}
		setModalPackage(true);
	};

	return (
		<>
			<div className="product-detail row">
				<div className="product-title col-md-12 pt-3">
					<h1 className="font-weight-semibold pl-2">
						{product.name || "Product"}
						<div className="product-brand mr-2">
							<strong>Company: </strong>
							<span>{product.company?.name || "N/A"}</span>
							<strong>Brand: </strong>
							<span>{product.brand?.name || "N/A"}</span>
							<strong>Version: </strong>
							<span>{product.version || "N/A"}</span>
						</div>
					</h1>

					<div className="info">
						{product.product_status ? (
							<Button color="primary" className={`btn-round ml-2 inactive`}>
								<ShoppingCart className="mr-2" size={18} />
								Retail
							</Button>
						) : (
							<Button color="warning" className={`btn-round ml-2 color-white inactive`}>
								<Box className="mr-2" size={18} />
								Concept
							</Button>
						)}
						<Button
							color="primary"
							onClick={onDuplicate}
							disabled={duplication}
							className="btn-outline-primary btn-round ml-2"
						>
							<Copy className="mr-2" size={18} />
							{duplication ? "Duplication" : "Duplicate"}
						</Button>
					</div>
				</div>
				<div className="detail col-lg-7 col-md-12 col-xs-12">
					<Row className="info">
						<Col className="image mt-2" sm={12} md={12} lg={3}>
							<ImageUploading onChange={onUpdateProductImage}>
								{({ imageList, onImageUpload }) => (
									<div
										data-tooltip={"Click to upload a product image"}
										className="image-upload-wrapper"
									>
										<div
											className="image-upload"
											style={{
												width: "200px",
												height: "200px",
												borderRadius: "8px"
											}}
											onClick={onImageUpload}
										>
											{uploadingProductImage && <Spinner type="grow" color="primary" />}
											{imageList.length ? (
												<img src={imageList[0].dataURL} />
											) : product?.image_uri !== "" ? (
												// <img src={product.image_uri} loading="lazy" />
												<ReactImageFallback
													style={{ borderRadius: 6 }}
													src={product.image_uri}
													fallbackImage={getIngredientImagePlaceholder()}
													alt="Product Image"
													loading="lazy"
												/>
											) : (
												// <img src={config.api.endpoints.ingredients.ingredientImage(product.name)} />
												<ReactImageFallback
													src={config.api.endpoints.ingredients.ingredientImage(product.name)}
													fallbackImage={getIngredientImagePlaceholder()}
													alt="Ingredient Image"
												/>
												// <Image />
											)}
										</div>
									</div>
								)}
							</ImageUploading>
						</Col>
						<Col className="description" sm={12} md={12} lg={9}>
							{product?.product_type && (
								<div className="product-type mt-2">
									<Box className="mr-2" size={18} />
									<span>{product?.product_type?.title}</span>
								</div>
							)}

							<div className="d-block mt-2">
								{formatedIngredients()?.map((dietary: string | undefined, index: number) => {
									return (
										<>
											{/* View More Button */}
											{index == 8 && !viewMore && (
												<div
													className="product-ingredient-dietary"
													style={{ cursor: "pointer" }}
													onClick={() => setViewMore(true)}
												>
													<span>View More</span>
													<ArrowRight className="ml-2" size={18} />
												</div>
											)}

											{/* Dietary Tag */}
											{(index < 8 || viewMore) && dietary && (
												<div className="product-ingredient-dietary">
													{/* <Box className="mr-2" size={18} /> */}
													<span>{dietary}</span>
												</div>
											)}
										</>
									);
								})}

								{/* Show Less Button */}
								{viewMore && (
									<div
										className="product-ingredient-dietary"
										style={{ cursor: "pointer" }}
										onClick={() => setViewMore(false)}
									>
										<span>Show Less</span>
										<ArrowLeft className="ml-2" size={18} />
									</div>
								)}
							</div>

							<div className="date">
								<div className="product-code mr-2">
									<strong>SKU/Code:</strong> {product.code}
								</div>
								<div className="created mr-2">
									<strong>Date Created:</strong> {moment(product.created_date).format("YYYY-MM-DD")}
								</div>
								<div className="fulfilment">
									<strong>Fulfilment Date:</strong>{" "}
									{moment(product.fulfillment_date).format("YYYY-MM-DD")}
								</div>
							</div>
						</Col>
					</Row>
					<Row className={"mt-4"}>
						<div className={"product-objectives mb"}>
							<div className={"d-flex flex-row align-items-center"}>
								<Tooltip
									className="simple-tooltip"
									isOpen={objectivesTooltip}
									placement="top"
									target="objectives"
									toggle={() => setObjectivesTooltip(!objectivesTooltip)}
								>
									Set product objectives to guide your team and receive ingredient recommendations
								</Tooltip>
								<h5 className={"mb-0 mr-2"}>
									Objectives <Info id="objectives" className={"ml-1"} size={18} />
								</h5>
							</div>
							{product.objective && (
								<div className={"mt-2"}>
									{product.objective.map((obj: string) => {
										return (
											<span
												style={{
													display: "inline-block",
													border: "2px solid #d0dcea",
													padding: "0.375rem 0.75rem",
													fontSize: "0.875rem",
													lineHeight: "1.5",
													textAlign: "center",
													whiteSpace: "nowrap",
													verticalAlign: "middle",
													fontWeight: 600,
													borderRadius: "6px",
													marginRight: "6px",
													marginBottom: "6px"
												}}
											>
												{toCamelCase(obj)}
											</span>
										);
									})}
								</div>
							)}
						</div>
					</Row>
					<div className="persona-stats p-4">
						<div>
							Ingredients:{" "}
							<strong className="font-weight-semibold font-18">
								{product.ingredients?.length || 0}
							</strong>
						</div>
						<div>
							Flavor:{" "}
							<strong className="font-weight-semibold font-18">{product.flavor || "N/A"}</strong>
						</div>
						<div>
							Serving Size:{" "}
							<strong className="font-weight-semibold font-18">{`${product.serving_size} ${product.unit_serving_size}`}</strong>
						</div>
						<div>
							Profit Margin:{" "}
							<strong className="font-weight-semibold font-18">{product.cost_margin} %</strong>
						</div>
						<div>
							Manufacturer:{" "}
							<strong className="font-weight-semibold font-18">
								{product.company?.name || "N/A"}
							</strong>
						</div>
					</div>
					<div style={{ marginBottom: 30 }}>
						<Integrations integrations={profile?.company.integrations} />
					</div>

					<Row>
						<Col md={12}>
							<div className="tab-navigation">
								<Nav tabs>
									<NavItem style={{ cursor: "pointer" }}>
										<NavLink
											onClick={() => setTab("versions")}
											activeClassName="active"
											className="nav-link h5 mb-0"
											active={tab == "versions"}
										>
											Version History
										</NavLink>
									</NavItem>
									<NavItem style={{ cursor: "pointer" }}>
										<NavLink
											onClick={() => setTab("nutrition")}
											activeClassName="active"
											className="nav-link h5 mb-0"
											active={tab == "nutrition"}
										>
											Nutrition
										</NavLink>
									</NavItem>
									<NavItem style={{ cursor: "pointer" }}>
										<NavLink
											onClick={() => setTab("personas")}
											activeClassName="active"
											className="nav-link h5 mb-0"
											active={tab == "personas"}
										>
											Matched Personas
										</NavLink>
									</NavItem>
									<NavItem style={{ cursor: "pointer" }}>
										<NavLink
											onClick={() => setTab("packaging")}
											activeClassName="active"
											className="nav-link h5 mb-0"
											active={tab == "packaging"}
										>
											Matched Packaging
										</NavLink>
									</NavItem>
								</Nav>
							</div>
						</Col>
					</Row>

					{tab === "versions" && <VersionHistory />}

					{tab === "personas" && (
						<div className="row pt-3">
							{(product.top_personas || []).map((persona: IPersona) => {
								return (
									<div style={{ marginRight: "20px", marginBottom: "20px" }}>
										<PersonaItem mini persona={persona} />
									</div>
								);
							})}
						</div>
					)}

					{tab === "packaging" && (
						<>
							<div className="row pt-3">
								{product.packaging && (
									<div
										style={{
											marginRight: "20px",
											marginBottom: "20px",
											cursor: "pointer"
										}}
									>
										<PackageItem selectPackage={selectPackage} current={product.packaging} />
									</div>
								)}
								{packages.map((p: any) => {
									return (
										<div
											onClick={() => selectPackage(p)}
											style={{
												marginRight: "20px",
												marginBottom: "20px",
												cursor: "pointer"
											}}
										>
											<PackageItem selectPackage={selectPackage} current={product.packaging} />
										</div>
									);
								})}
								{/* Demo package info
								{[
									"honey ketchup",
									"blend of veggies",
									"no sugar ketchup",
									"fodmap ketchup"
								].includes(product?.name.toLowerCase()) ? (
									<DemoPackages />
								) : (
									<></>
								)} */}
							</div>
						</>
					)}

					{tab == "nutrition" && <NutritionalInfo product={product} />}
				</div>

				<div className="chart col-lg-4 col-md-12 col-xs-12 pt-2" ref={chartRef}>
					<ProductRecommendations />
					<div className="mt-2">
						<Doughnut
							options={{
								legend: {
									display: false
								}
							}}
							data={{
								labels: (product.ingredients || []).map(
									(ingredient) => ingredient.ingredient.jf_display_name
								),
								datasets: [
									{
										data: (product.ingredients || []).map((ingredient) =>
											ingredient.weight != 0
												? ingredient.weight
												: ingredient.percentage
												? ingredient.percentage
												: 0
										),
										backgroundColor: ["#51C5AC", "#F4A850", "#3F65F1", "#CEDBE9"]
									}
								]
							}}
						/>
					</div>

					<div className="chart-ingredients">
						{product.ingredients?.map((ingredient, index) => {
							return (
								<React.Fragment>
									{(index < 5 || viewAllIngredient) && (
										<ProductIngredientItem
											key={index}
											productIngredient={ingredient}
											totalWeights={totalIngredientWeights}
											opacity={index == 4 && !viewAllIngredient ? 0.5 : 1}
										/>
									)}
								</React.Fragment>
							);
						})}

						{product && (product.ingredients?.length || 0) > 5 && (
							<div
								className="view-all"
								onClick={() => {
									setViewAllIngredient(!viewAllIngredient);
								}}
							>
								View {viewAllIngredient ? `Less` : "More"}
							</div>
						)}
					</div>
				</div>

				<span className="action-button-group">
					<div className={"action-button-row"}>
						<Tooltip
							className="simple-tooltip"
							isOpen={duplicateTooltip}
							placement="top"
							target="duplicate"
							toggle={() => setDuplicateTooltip(!duplicateTooltip)}
						>
							{duplication ? "Product already a duplicate" : "Duplicate Product"}
						</Tooltip>
						<Button
							id="duplicate"
							disabled={duplication}
							onClick={onDuplicate}
							className="btn-circle btn-duplicate"
						>
							<Copy size={18} />
						</Button>
					</div>

					<div className={"action-button-row"}>
						<Tooltip
							className="simple-tooltip"
							isOpen={compareTooltip}
							placement={"left"}
							target={"compare"}
							toggle={() => setCompareTooltip(!compareTooltip)}
						>
							{"Compare Product Versions"}
						</Tooltip>
						<Button id={"compare"} onClick={onCompare} className="btn-circle btn-compare">
							<Search size={18} />
						</Button>
						<Button className="btn-circle btn-search btn-secondary" onClick={toggleForm}>
							<Edit2 size={28} />
						</Button>
					</div>
				</span>
			</div>

			{compareProducts && <VersionComparison handleClose={() => setCompareProducts(false)} />}

			<SideForm isOpen={isOpen} toggleForm={toggleForm} title="Edit Product">
				<EditProductForm
					toggleForm={toggleForm}
					isOpen={isOpen}
					onRemoveHandler={onRemoveHandler}
				/>
			</SideForm>

			<Modal isOpen={modalPackage} className="package-modal">
				<Row className="package-header">
					<span className="cost">{`$ $ $`}</span>
					<span className={`label label-${selectedPackage?.status?.toLowerCase()}`}>
						{selectedPackage?.status?.toLowerCase()}
					</span>
				</Row>

				<Row>
					<div className="img-container">
						<img src={packageImagePlaceholder} />
					</div>
					<Col>
						<h3>{selectedPackage?.name}</h3>
						<Row className="info">
							<Col md={4}>
								<span>Market</span>
								<p>{selectedPackage?.region_available.join(", ")}</p>
							</Col>

							<Col md={8}>
								<span>Material</span>
								<p>{selectedPackage?.material.join(", ")}</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="tags m-3">
					<Col>
						{selectedPackage?.sustainability.map((s: IPackagePreference) => {
							return (
								<span className="tag">
									<CheckCircle size={17} className="mr-1" />
									{s.title}
								</span>
							);
						})}
						{selectedPackage?.filling_process.map((f: IPackagePreference) => {
							return (
								<span className="tag">
									<CheckCircle size={17} className="mr-1" />
									{f.title}
								</span>
							);
						})}
					</Col>
				</Row>

				<Row className="product-types m-3 pt-0 pb-0">
					<Col md={12} className="m-0 p-0">
						<h5 className="">Product Types</h5>
					</Col>
					<Col md={12} className="p-0">
						{selectedPackage?.product_type.map((pt: string, index: number) => {
							return (
								<React.Fragment>
									{(index < 3 || viewMorePackage) && (
										<span className="product-type">
											<Box size={17} color={"#3f65f1"} className="mr-1" />
											{pt}
										</span>
									)}
								</React.Fragment>
							);
						})}
					</Col>
					{(selectedPackage?.product_type || []).length > 3 && (
						<label
							style={{
								color: "#3f65f1",
								letterSpacing: "0.5px",
								fontSize: "14px",
								cursor: "pointer"
							}}
							onClick={() => setViewMorePackage(!viewMorePackage)}
						>
							View {viewMorePackage ? "Less" : "More"} »
						</label>
					)}
				</Row>

				<Row className="m-3 pt-0">
					<Col md={12} className="pt-0">
						<SuitabilityBar
							type={"packaging"}
							title={"Packaging score"}
							className="persona-suitability"
							value={selectedPackage?.packaging_score || 0}
						/>
					</Col>
				</Row>

				<ModalFooter>
					<div className="modal-close" onClick={() => setModalPackage(false)}>
						Close
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	product: state.product.selected,
	personas: state.product.selectedPersonas,
	packages: state.product.selectedPackages,
	profile: state.user.profile,
	recommendations: state.recommendations.all
});

const mapDispatchToProps = {
	getProductById: (id: string) => productService.getProductById(id),
	getProductPersonasById: (id: string) => productService.getProductPersonasById(id),
	getProductPackages: (productId: string) => productService.getProductPackages(productId),
	removeProduct: (productId: string) => productService.removeProduct(productId),
	duplicateProduct: (productId: string) => productService.duplicateProduct(productId),
	uploadProductImage: (file: File, productId: string) =>
		productService.uploadProductImage(file, productId),
	addRecentlyViewed: (id: string) => productService.addRecentlyViewed(id),
	getPackageById: (id: string) => packagingService.getPackageById(id),
	getAllRecommendations: () => recommendationService.getAllRecommendations()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductDetail));
