import React from "react";
import { Switch, Route, NavLink, RouteComponentProps } from "react-router-dom";
import { routes } from "../routes";
import Login from "./login";
import Register from "./register";
import ForgotPassword from "./forgotPassword";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import PortalWrapper from "../../components/common/portalWrapper";
import JF_LOGO from "../../assets/images/jf-logo-no-clip.png";

interface IProps extends RouteComponentProps {
	isLoggedIn: boolean;
}

const AuthRouter: React.FC<RouteComponentProps> = (props) => (
	<div className="auth-container">
		<PortalWrapper />
		<div className="header">
			<div className="title">
				<div className={"logo"}>
					<img src={JF_LOGO} alt="Journey Foods Logo" width={66} height={47} />
				</div>
				<p>
					Product Research and <br /> Development Portal
				</p>
			</div>
			<p className="sub-title">
				This platform is built exclusively for Journey <br /> Foods Team Members and Partners.
			</p>
		</div>

		<div className="route-container">
			<NavLink to={routes.LOGIN} activeClassName="active">
				LOGIN
			</NavLink>
			<NavLink to={routes.REGISTER} activeClassName="active">
				REGISTER
			</NavLink>
		</div>

		<Switch>
			<Route exact path={routes.LOGIN} component={Login} />
			<Route exact path={routes.REGISTER} component={Register} />
			<Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
		</Switch>
	</div>
);

const mapStateToProps = (state: IApplicationState) => ({
	isLoggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps, null)(AuthRouter);
