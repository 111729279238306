import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { History } from "history";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "../../components/common/header";
import Sidebar from "../../components/common/sidebar";
import AppRoutes from "../routing";
import { routes } from "../routes";
import PermissionRoute from "../../components/hoc/PermissionRoute";
import NotificationsRouter from "../Notifications";
import initializedFirebaseApp from "../../utils/firebase";
import firebase from "firebase";
import notificationService from "../../services/notificationService";
import { IApplicationState } from "../../redux/reducers";

import userService from "../../services/userService";
import { IRoute } from "../../interfaces/route";
import Search from "../search";
import Walkthrough from "../../components/common/walkthrough/walkthrough";
import profile from "../account/profile";
import PortalWrapper from "../../components/common/portalWrapper";

interface IAppProps {
	history: History;
	saveDeviceToken: (token: string) => Promise<any>;
}

interface IAppProps {
	history: History;
	profile: any;
	getUserProfile: () => Promise<any>;
}

const App: React.FC<IAppProps> = (props) => {
	/**
	 * Change the layout settings [Header, Sidebar] from here
	 */
	const [width, setWidth] = useState(window.innerWidth);
	const [requestedProfile, setRequestedProfile] = useState<boolean>();

	props.history.listen((location, action) => {
		if (
			window.innerHeight < 767 &&
			document.getElementById("main-wrapper")?.className.indexOf("show-sidebar") !== -1
		) {
			// document.getElementById("main-wrapper")?.classList.toggle('show-sidebar');
		}
	});

	useEffect(() => {
		if (!firebase.messaging.isSupported()) return;
		const messaging = initializedFirebaseApp.messaging();

		Notification.requestPermission()
			.then(function (permission) {
				// 1. granted
				// 2. denied
				// 3. default
				if (permission === "granted") {
					return messaging.getToken();
				}
			})
			.then(function (token) {
				if (token) props.saveDeviceToken(token);
			})
			.catch(function (error) {
				console.log("Error Occured.");
			});

		// messaging.getToken().then((currentToken) => {
		//     console.log("CURRENT TOKEN: ", currentToken);
		//     if (currentToken) {
		//         notificationService.saveDeviceToken(currentToken)
		//     } else {
		//         Notification.requestPermission().then(function(permission) {
		//             console.log("PERMISSION: ", permission);
		//         })
		//     }
		// })
	}, []);

	useEffect(() => {
		if (!requestedProfile) {
			Promise.all([props.getUserProfile()]).finally(() => {
				setRequestedProfile(true);
			});
		}

		const updateDimensions = () => {
			let element = document.getElementById("main-wrapper");

			setWidth(window.innerWidth);
			if (width < 1170) {
				element?.setAttribute("data-sidebartype", "mini-sidebar");
				element?.classList.add("mini-sidebar");
			} else {
				element?.setAttribute("data-sidebartype", "full");
				element?.classList.remove("mini-sidebar");
			}
		};
		window.addEventListener("load", updateDimensions);
		window.addEventListener("resize", updateDimensions);
		return () => {};
	}, [width, props.profile]);

	const userHasRouteAccess = (route: IRoute) => {
		if (route.permission_name && props.profile != undefined) {
			return (
				props.profile?.company[route.permission_name] !== "disable" &&
				!route.locked_for?.includes(props.profile?.company.subscription_type)
			);
		} else {
			return true;
		}
	};

	return (
		<div
			id="main-wrapper"
			data-theme="light"
			data-layout="vertical"
			data-sidebartype="full"
			data-sidebar-position="fixed-with-partner-wrapper"
			data-header-position="fixed"
			data-boxed-layout="full"
			className="show-sidebar"
		>
			<PortalWrapper />
			<div className="d-flex" style={{ width: "inherit" }}>
				<Sidebar location={props.history.location} routes={AppRoutes} />
				<div
					style={{
						width: "inherit"
						// flex: 1
					}}
				>
					<Header />
					<data className="page-wrapper d-block">
						<div className="page-content container-fluid">
							<Switch>
								<Route exact path={routes.NOTIFICATIONS} component={NotificationsRouter} />
								<Route exact path={routes.SEARCH} component={Search} />
								{AppRoutes.map((route) => {
									if (route.redirect) {
										return (
											<Redirect
												from={route.path}
												to={
													props.profile?.company?.subscription_type == "supplier"
														? routes.INGREDIENTS
														: route.redirect
												}
												key={route.path}
											/>
										);
									} else if (route.component) {
										const userHasAccess =
											userHasRouteAccess(route) ||
											props.profile.role == "JF_ADMIN" ||
											props.profile.role == "JF_STAFF";
										if (route.roles?.length) {
											// implement PermissionRoute based on permission_name field of IRoute; to prevent users from accessing routes they don't have upgrade for
											return (
												<PermissionRoute
													path={route.path}
													component={
														userHasAccess
															? route.component
															: route.upgrade_component
															? route.upgrade_component
															: route.component
													}
													componentRoles={route.roles}
													key={route.path}
												/>
											);
										}
										return (
											<Route
												path={route.path}
												component={userHasAccess ? route.component : route.upgrade_component}
												key={route.path}
											/>
										);
									} else {
										return null;
									}
								})}
							</Switch>
						</div>
					</data>
				</div>
			</div>
			{props.profile?.walkthrough ? <Walkthrough /> : <></>}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	role: state.user.role,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getUserProfile: () => userService.getUserProfile(),
	saveDeviceToken: (token: string) => notificationService.saveDeviceToken(token)
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
