import React, { useState } from "react";
import { Check, Info, ArrowLeft, ArrowDown } from "react-feather";
import { Button, Card, CardBody, Row } from "reactstrap";
import growth from "../../../../assets/images/subscription/growth.png";
import enterprise from "../../../../assets/images/subscription/enterprise.png";
import fresh from "../../../../assets/images/subscription/fresh.png";
import supplier from "../../../../assets/images/subscription/supplier.png";
import group from "../../../../assets/images/subscription/group.png";
import FeatureTable from "./featureTable";
import { IApplicationState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { IUser } from "../../../../interfaces/user";
import StripeClimate from "../../../../components/checkout/stripeClimate";

interface IProps {
	profile: IUser | undefined;
}

const SubscriptionOptions: React.FC<IProps> = (props) => {
	const [viewDetails, setViewDetails] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>();

	return (
		<div className="subscription-options">
			<h3 className={"mb-3"}>
				<span style={{ color: "#3f65f1" }}>Pricing</span>
			</h3>
			<Row className={"mt-4"}>
				<div className={"option"}>
					<img src={fresh} />
					<h4>Fresh</h4>
					<p className="price">
						$199 <br />
						per month
					</p>
					<hr style={{ borderTop: "3px solid #7c4aa9", width: "100%" }} />
					<p>
						Manage your brand with data <br />
					</p>
					{props.profile?.company.subscription_type == "fresh" ? (
						<a>
							<Button color="primary"> Active </Button>
						</a>
					) : (
						<a href="mailto:sales@journeyfoods.com?subject=Fresh Tier Subscription Inquiry">
							<Button color="secondary"> Contact Us </Button>
						</a>
					)}
				</div>
				<div className={"option"}>
					<img src={growth} />
					<h4>Growth</h4>
					<p className="price">
						$499 <br />
						per month
					</p>
					<hr style={{ borderTop: "3px solid #e85a73", width: "100%" }} />
					<p>Grow your products with insights</p>
					{props.profile?.company.subscription_type == "growth" ? (
						<a>
							<Button color="primary"> Active </Button>
						</a>
					) : (
						<a href="mailto:sales@journeyfoods.com?subject=Growth Tier Subscription Inquiry">
							<Button color="secondary"> Contact Us </Button>
						</a>
					)}
				</div>
				<div className={"option"}>
					<img src={supplier} />
					<h4>Supplier</h4>
					<p className="price">
						$60 <br />
						per year
					</p>
					<hr style={{ borderTop: "3px solid #3f65f1", width: "100%" }} />
					<p>Bring your products to Journey Foods</p>
					{props.profile?.company.subscription_type == "supplier" ? (
						<a>
							<Button color="primary"> Active </Button>
						</a>
					) : (
						<a href="mailto:sales@journeyfoods.com?subject=Supplier Tier Subscription Inquiry">
							<Button color="secondary"> Contact Us </Button>
						</a>
					)}
				</div>
				<div className={"option"}>
					<img src={group} />
					<h4>Group</h4>
					<p className="price">
						$999 <br />
						per month
					</p>
					<hr style={{ borderTop: "3px solid #0ed7ac", width: "100%" }} />
					<p>
						Improve your <br /> portfolio <br />
					</p>
					{props.profile?.company.subscription_type == "group" ? (
						<a>
							<Button color="primary"> Active </Button>
						</a>
					) : (
						<a href="mailto:sales@journeyfoods.com?subject=Group Tier Subscription Inquiry">
							<Button color="secondary"> Contact Us </Button>
						</a>
					)}
				</div>
				<div className={"option"}>
					<img src={enterprise} />
					<h4>Enterprise</h4>
					<p className="price">
						Custom <br /> <br />
					</p>
					<hr style={{ borderTop: "3px solid #f4a850", width: "100%" }} />
					<p>{"Boost your R&D team and beat competition"}</p>
					{props.profile?.company.subscription_type == "enterprise" ? (
						<a>
							<Button color="primary"> Active </Button>
						</a>
					) : (
						<a href="mailto:sales@journeyfoods.com?subject=Enterprise Tier Subscription Inquiry">
							<Button color="secondary"> Contact Us </Button>
						</a>
					)}
				</div>
			</Row>
			<div className={"d-flex justify-content-center mt-3 mb-5"}>
				<div className="view-features">
					<a href="#features">View All Features</a>
					<ArrowDown />
				</div>
			</div>
			<div id="features" style={{ display: "flex", flexDirection: "column" }}>
				<h3 style={{ alignSelf: "center", margin: "0.5rem" }}>All Features</h3>
				<FeatureTable />
			</div>
			<div className={"d-flex align-item-center justify-content-center pb-3"}>
				<div style={{ width: 500 }}>
					<StripeClimate />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps)(SubscriptionOptions);
