import React from "react";
import { InjectedFormikProps } from "formik";
import Input from "../../../../components/form/Input";
import { Row, Col } from "reactstrap";




export interface IRegisterFormElementsValues {
    firstName: string;
    lastName: string;
    company: string;
    jobtitle: string;
    city: string;
    state: string;
    country: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface ILoginFormElementsProps {

}



const RegisterFormFields: React.FC<InjectedFormikProps<ILoginFormElementsProps, IRegisterFormElementsValues>> = (props) => {

    const {
        values: {
            city,
            company,
            confirmPassword,
            country,
            email,
            firstName,
            lastName,
            password,
            state,
            jobtitle,
        },
        handleChange,
        touched,
        handleBlur,
        errors,
    } = props;


    return (

        <>
            <Row>
                <Col>
                    <Input
                        label="First Name"
                        name="firstName"
                        placeholder="John"
                        value={firstName}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
                <Col>
                    <Input
                        label="Last name"
                        name="lastName"
                        placeholder="Doe"
                        value={lastName}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    /></Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        label="Company"
                        name="company"
                        placeholder="Journey Foods"
                        value={company}
                        handleChange={handleChange}
                        errors={errors}
                        handleOnBlur={handleBlur}
                        touched={touched}
                    />
                </Col>
                <Col>
                    <Input
                        label="Job Title"
                        name="jobtitle"
                        placeholder="Scientist"
                        value={jobtitle}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        label="City"
                        name="city"
                        placeholder="Chicago"
                        value={city}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
                <Col>
                    <Input
                        label="State"
                        name="state"
                        placeholder="Ch"
                        value={state}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        label="Country"
                        name="country"
                        placeholder="United States"
                        value={country}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
                <Col>
                    <Input
                        label="Email"
                        name="email"
                        placeholder="example@example.com"
                        value={email}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        label="Password"
                        name="password"
                        placeholder="password"
                        value={password}
                        handleChange={handleChange}
                        errors={errors}
                        handleOnBlur={handleBlur}
                        touched={touched}
                        type="password"
                    />
                </Col>
                <Col>
                    <Input
                        label="Confirm Password"
                        name="confirmPassword"
                        placeholder="password"
                        value={confirmPassword}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleOnBlur={handleBlur}
                        type="password"
                    />
                </Col>
            </Row>

        </>
    )

}

export default RegisterFormFields;