import React, { useState } from 'react';
import { ArrowRight, Loader, Users } from 'react-feather';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Upgrade from '../../../components/common/upgrade';
import { IUser } from '../../../interfaces/user';
import { IApplicationState } from '../../../redux/reducers';
import { routes } from '../../routes';

interface IProps extends RouteComponentProps {
    profile?: IUser | undefined;
}

const PersonasCard: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>();
    return (
        <Card className='dashboard-personas'>
            <CardBody>
                <header>
                    <Users size={47}/>
                    {props.profile?.company?.subscription_type == 'supplier' ?
                        <Upgrade /> :
                        <div>
                            <h3>
                                { loading ? <Loader className='fa-spin' size={24}/> : 6}
                            </h3>
                            <h4>Matched Personas</h4>
                        </div>
                    }
                </header>
                <div className='actions'>
                    <div className='view packaging' onClick={() => props.history.push(routes.PERSONAS)}>
                        Personas <ArrowRight size={16}/>
                    </div>
                    <div className='view preferences' onClick={() => props.history.push(routes.PREFERENCES)}>
                        Preferences <ArrowRight size={16}/>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
const mapStateToProps =  (state: IApplicationState) => ({
    profile: state.user.profile,
})

export default connect(mapStateToProps, null)(withRouter(PersonasCard))