import {
	IGetActiveProductsResponse,
	IGetConceptProductsResponse,
	IGetProductDetailResponse,
	IGetProductPersonasResponse,
	IGetProductVersionsResponse,
	IGetProductPackagesResponse,
	IGetRecentlyViewedResponse
} from "../products/products.d";
import { product } from "../actionContants";
import { IPaginateList } from "../../../interfaces/pagination";
import { IProduct } from "../../../interfaces/products";

export function getActiveProductResponseAction(
	payload: any
): IGetActiveProductsResponse {
	return {
		type: product.GET_ACTIVE_PRODUCT_RESPONSE,
		payload
	};
}

export function getConceptProductResponseAction(
	payload: IPaginateList<IProduct[]>
): IGetConceptProductsResponse {
	return {
		type: product.GET_CONCEPT_PRODUCT_RESPONSE,
		payload
	};
}

export function getProductDetailResponseAction(
	payload: any
): IGetProductDetailResponse {
	return {
		type: product.GET_PRODUCT_DETAIL_RESPONSE,
		payload
	};
}

export function getProductPersonaResponseAction(
	payload: any
): IGetProductPersonasResponse {
	return {
		type: "GET_PRODUCT_PERSONA_RESPONSE",
		payload
	};
}

export function getProductVersionsResponseaAction(
	payload: IProduct[]
): IGetProductVersionsResponse {
	return {
		type: product.GET_PRODUCT_VERSIONS_RESPONSE,
		payload
	};
}

export function getProductPackagesAction(
	payload: any
): IGetProductPackagesResponse {
	return {
		type: product.GET_PRODUCT_PACKAGES_RESPONSE,
		payload
	};
}

export function getRecentlyViewedAction(
	payload: any
): IGetRecentlyViewedResponse {
	return {
		type: product.GET_RECENTLY_VIEWED_RESPONSE,
		payload
	};
}
