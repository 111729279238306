import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import {
	getAllReportsResponseAction,
	getAllReportRequestsResponseAction
} from "../redux/actions/reports/reports";
import { info } from "../utils/notification";
import { IReportNew } from "../interfaces/report";

const DEFAULT_REPORT_ORDER = {
	order_on: "request_date",
	order_by: "DESC"
};

class ReportService extends Api {
	getAllReportRequests() {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.reports.requests
				);
				dispatch(getAllReportRequestsResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Report Requests");
			}
		};
	}

	getAllReports() {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const {
					report: {
						reports: { pagination }
					}
				} = getState();

				const response = await this.http.get(config.api.endpoints.reports.all, {
					params: {
						offset: pagination.page,
						limit: pagination.size,
						...DEFAULT_REPORT_ORDER
					}
				});

				dispatch(
					getAllReportsResponseAction({
						pagination: response.data.meta,
						list: response.data.data
					})
				);
			} catch (error) {
				this.handleError(error, "All Reports");
			}
		};
	}

	addReport(
		report_type: string,
		report_file: File,
		description: string,
		requested_by?: string
	) {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			const formData = new FormData();
			formData.append("report_file", report_file);
			formData.append("report_type", report_type);
			formData.append("description", description);

			if (requested_by != undefined)
				formData.append("requested_by", requested_by);

			try {
				const response = await this.http.post(
					config.api.endpoints.reports.all,
					formData
				);
				info("Report successfully created.");
			} catch (error) {
				this.handleError(error, "Add Report Error");
			}
		};
	}

	addReportRequest(report_type: string, description: string) {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.post(
					config.api.endpoints.reports.requests,
					{ report_type, description }
				);
				info("Report Request successfully created.");
			} catch (error) {
				this.handleError(error, "Add Report Request error");
			}
		};
	}

	uploadReportFile(report_id: string, report_file: File) {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			const formData = new FormData();
			formData.append("report_id", report_id);
			formData.append("report_file", report_file);

			try {
				const response = await this.http.put(
					config.api.endpoints.reports.all,
					formData
				);
				info("Upload Report File Successful.");
			} catch (error) {
				this.handleError(error, "Upload Report File");
			}
		};
	}
}

export default new ReportService();
