import { Api } from "./api";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import config from "../utils/config";
import { IUser } from "../interfaces/user";
import {
	pendingUserResponseAction,
	fetchAdminProductResponseAction,
	selectProductAction,
	fetchAdminReportRequestsAction,
	fetchAdminReportsAction,
	fetchEmailScheduleResponse,
	allUsersResponseAction
} from "../redux/actions/admin/adminActions";
import notification, { info, product } from "../utils/notification";
import { IApplicationState } from "../redux/reducers";
import { IProduct } from "../interfaces/products";
import { IEmailSchedule } from "../interfaces/admin";

const DEFAULT_REPORT_ORDER = {
	order_on: "request_date",
	order_by: "DESC"
};

export class AdminService extends Api {
	getAdminProducts(
		all: boolean = false,
		companyId?: string,
		resolve?: Function,
		reject?: Function
	) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					admin: {
						products: { pagination }
					}
				} = getState();
				let params = companyId ? { company_id: companyId } : {};
				if (all) {
					const response = await this.http.get(config.api.endpoints.admin.products, {
						params: {
							...params
						}
					});
					dispatch(
						fetchAdminProductResponseAction({
							pagination: response.data.meta,
							products: response.data
						})
					);
				} else {
					const response = await this.http.get(config.api.endpoints.admin.products, {
						params: {
							...params,
							offset: pagination.page,
							limit: pagination.size
						}
					});
					dispatch(
						fetchAdminProductResponseAction({
							pagination: response.data.meta,
							products: response.data.data
						})
					);
				}
			} catch (error) {
				this.handleError(error, "Product Error");
				throw error;
			}
		};
	}

	getAdminReportRequests() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					admin: {
						requests: { pagination }
					}
				} = getState();
				const response = await this.http.get(config.api.endpoints.admin.requests, {
					params: {
						offset: pagination.page,
						limit: pagination.size,
						...DEFAULT_REPORT_ORDER
					}
				});

				dispatch(
					fetchAdminReportRequestsAction({
						pagination: response.data.meta,
						list: response.data.data
					})
				);
			} catch (error) {
				this.handleError(error, "Admin Report Requests");
			}
		};
	}

	getAdminReports() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const {
					admin: {
						reports: { pagination }
					}
				} = getState();
				const response = await this.http.get(config.api.endpoints.admin.reports, {
					params: {
						offset: pagination.page,
						limit: pagination.size,
						...DEFAULT_REPORT_ORDER
					}
				});

				dispatch(
					fetchAdminReportsAction({
						pagination: response.data.meta,
						list: response.data.data
					})
				);
			} catch (error) {
				this.handleError(error, "Admin Reports");
			}
		};
	}

	editAdminProduct(
		productId: string,
		productData: IProduct,
		resolve?: Function,
		reject?: Function
	) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				await this.http.put(config.api.endpoints.admin.editProduct(productId), productData);
				product("Product updated successfully");
			} catch (error) {
				this.handleError(error, "Edit Product");
			}
		};
	}

	getAllUsers(resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.admin.users);
				dispatch(allUsersResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "User requests");
				throw error;
			}
		};
	}

	getPendingUsers(resolve?: Function, reject?: Function) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response: AxiosResponse<IUser[]> = await this.http.get(
					config.api.endpoints.admin.users
				);

				dispatch(pendingUserResponseAction(response.data));
				if (resolve) {
					resolve(response);
				}
			} catch (error) {
				this.handleError(error, "Pending users");
				if (reject) reject(error);
			}
		};
	}

	updateUserById(id: string, userData: Partial<IUser>) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.put(config.api.endpoints.admin.editUser(id), userData);
			} catch (error) {
				this.handleError(error, "Update User");
			}
		};
	}

	approveOrRejectUser(userId: string, isApproved: boolean) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response: AxiosResponse<IUser> = await this.http.post(
					isApproved ? config.api.endpoints.admin.approve : config.api.endpoints.admin.reject,
					{
						user_id: userId
					}
				);
				info({
					title: "Approve User",
					description: `User ${response.data.first_name} has been ${
						isApproved ? "approved" : "rejected"
					} successfully`
				});
			} catch (error) {
				this.handleError(error);
			}
		};
	}

	modifyUser(userId: string, data: any) {
		return async (dispatch: Dispatch<any>) => {
			const response = await this.http.patch(config.api.endpoints.admin.modifyUser(userId), {
				...data
			});

			info({
				title: "User modify",
				description: "User successfully updated."
			});
		};
	}

	updateUserStatus(userId: string, status: boolean) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.patch(config.api.endpoints.admin.modifyUser(userId), {
					disable: status
				});

				console.log("RESPONSE: ", response.data);
				info({
					title: "User Status",
					description: `User status successfully changed.`
				});
			} catch (error) {
				this.handleError(error, "Update User Status");
			}
		};
	}

	selectProduct(product: IProduct) {
		return (dispatch: Dispatch<any>) => {
			dispatch(selectProductAction(product));
		};
	}

	getEmailSchedule() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.admin.email);
				dispatch(fetchEmailScheduleResponse(response.data[0]));
			} catch (error) {
				this.handleError(error, "Email Schedule");
			}
		};
	}

	updateEmailSchedule(schedule: IEmailSchedule) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.post<IEmailSchedule>(config.api.endpoints.admin.email, {
					...schedule,
					day: parseInt(schedule.day as any, 10)
				});

				dispatch(fetchEmailScheduleResponse(response.data));
				notification.info("Email schedule updated successfully");
			} catch (error) {
				this.handleError(error, "Email schedule");
			}
		};
	}

	updateCompanySubscription(subscriptionType: string, companyId: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.patch(
					config.api.endpoints.admin.updateCompanySubscription(companyId),
					{
						subscription_type: subscriptionType
					}
				);
				info({
					title: "Company Subscription",
					description: `Subscription successfully changed to ${subscriptionType}.`
				});
			} catch (error) {
				this.handleError(error, "Update Company Subscription");
			}
		};
	}
}

export default new AdminService();
