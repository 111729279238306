import React, { useEffect, useState } from 'react';
import { AlertCircle, ArrowLeft, CheckCircle, X } from 'react-feather';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { SnakeCaseToNormal, toCamelCase } from '../../../../utils/string';
import InvoicePaymentModal from '../../../../components/checkout/invoicePayment';
import moment from 'moment';
import { IInvoice } from '../../../../interfaces/invoice';
import paymentService from '../../../../services/paymentService';
import { IApplicationState } from '../../../../redux/reducers';
import { connect } from 'react-redux';

interface IProps  {
    id: string;
    invoice: IInvoice | undefined;
    handleNavBack: (paymentMade?:boolean) => void;
}

const InvoiceSingle: React.FC<IProps> = ({id, invoice, handleNavBack, ...props}) => {
    const [paymentModal, setPaymentModal] = useState<boolean>(false);
    const [paymentMade, setPaymentMade] = useState<boolean>(false);

    const onPaymentSuccess = async () => {
        setPaymentMade(true);
        setPaymentModal(false);
    }

    // Helper function until get invoice by id is fixed 
    const getPaidDate = () => {
        if (invoice?.created_at == invoice?.updated_at) {
            return moment((new Date).toString()).format('MM/DD/YYYY');
        } else {
            return moment(invoice?.updated_at).format('MM/DD/YYYY');
        }
    }

    return (
        <>
        { invoice ? 
            <>
                <Row className={'mt-2 mb-3'}>
                    <div className='nav-back mb-3' onClick={() => handleNavBack(paymentMade)}>
                        <ArrowLeft size={18}/>
                        All Invoices
                    </div>
                    <h3>{'Invoice for '  + toCamelCase(invoice?.meta_data?.service)}</h3>
                    <h4>
                        Status: 
                        {invoice?.invoice_status == 'open' && !paymentMade ?
                            <>
                                <span className={'invoice-status'}>Unpaid</span>
                                <AlertCircle color={'#f5a850'}/> 
                            </> :
                            <>
                                <span className={'invoice-status'}>Paid</span>
                                <CheckCircle color={'#0ed7ac'}/>
                            </>
                        }
                        <div className="invoice-created">
                            <span style={{color: '#8fa1b3', fontWeight: 50}}>Created on:</span>
                            <span>{moment(invoice?.created_at).format('MM/DD/YYYY')}</span>
                            <span style={{color: '#8fa1b3', fontWeight: 50}}>Paid on:</span>
                            <span>
                                {invoice?.invoice_status == 'open' && !paymentMade ?
                                    'Unpaid' :
                                    getPaidDate()
                                }
                            </span>
                        </div>
                    </h4>
                </Row>
                <Row className='invoice-details'>
                    <div className='details'>
                        <p>
                            <h6>Amount: </h6>
                            {invoice.meta_data?.price}
                        </p>
                        <p>
                            <h6>Description: </h6>
                            {invoice.meta_data?.description}
                        </p>
                    </div>
                    {invoice.invoice_status == 'open' ?
                        <Button className={'mt-5'} style={{backgroundColor: '#0ed7ac', color: '#fff', borderRadius: '6px', padding: '6px 12px', borderColor: '#0ed7ac'}}
                            onClick={() => setPaymentModal(true)}
                        >
                            Pay Invoice
                        </Button> :
                        <></>
                    }
                </Row>

                <Modal isOpen={paymentModal}>
                    <ModalHeader>
                        <div className={'modal-header-content'}>
                            <span>{'Paying Invoice for: ' + toCamelCase(invoice.meta_data?.service)}</span>
                            <X onClick={() => { 
                                setPaymentModal(false)
                            }}  className={'close-modal'} size={24}></X>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <InvoicePaymentModal invoiceId={invoice.id} onSuccess={onPaymentSuccess}/>
                    </ModalBody>
                </Modal> 
            </> :
            <></>
        }
        </>
    );
}

export default InvoiceSingle;