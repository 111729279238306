import { Api } from "./api";
import { Dispatch } from "redux";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";

import {
	getDashboardOverviewResponseAction,
	getDashboardActiveProductResponse,
	getDashboardConceptProductResponse,
	getDashboardProductAnalyticsAction
} from "../redux/actions/dashboard/dashboard";

export class DashboardService extends Api {
	getDashboardOverview() {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.dashboard.overview
				);
				dispatch(getDashboardOverviewResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Dashboard");
			}
		};
	}

	getDashboardProductAnalytics() {
		return async (
			dispatch: Dispatch<any>,
			getState: () => IApplicationState
		) => {
			try {
				const response = await this.http.get(
					config.api.endpoints.dashboard.productAnalytics
				);
				dispatch(getDashboardProductAnalyticsAction(response.data));
			} catch (error) {
				this.handleError(error, "Product Analytics");
			}
		};
	}

	getActiveProducts = async (page: Number, size: Number = 5) => {
		try {
			const response = await this.http.get(
				config.api.endpoints.products.active,
				{
					params: {
						product_status: true,
						offset: page,
						limit: size
					}
				}
			);
			return response.data.data;
		} catch (error) {
			if (error.response?.status === 404) {
				throw "NO_MORE_ITEMS";
			}
			this.handleError(error, "Dashboard");
			return [];
		}
	};

	getConceptProducts = async (page: Number, size: Number = 5) => {
		try {
			const response = await this.http.get(
				config.api.endpoints.products.concept,
				{
					params: {
						product_status: false,
						offset: page,
						limit: size
					}
				}
			);
			return response.data.data;
		} catch (error) {
			if (error.response?.status === 404) {
				throw "NO_MORE_ITEMS";
			}
			this.handleError(error, "Dashboard");
			return [];
		}
	};
}

export default new DashboardService();
