import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { Label } from "reactstrap";
import { IApplicationState } from "../../../redux/reducers";
import searchService from "../../../services/searchService";

interface IProps {
	show: boolean;
	showingResults?: boolean;
	searchFilter: any;
	isSupplier?: boolean;
	setSearchFilter: (filter: any) => Promise<any>;
}

const SearchFilter: React.FC<IProps> = ({ show, setSearchFilter, searchFilter, ...props }) => {
	useEffect(() => {
		const cleanup = () => {
			setSearchFilter(undefined);
		};
		return cleanup();
	}, []);

	const handleToggle = (name: string) => {
		const filter = searchFilter?.name == name ? undefined : { name: name, status: true };
		setSearchFilter(filter);
	};

	const filters = [
		{ label: "Product Application", value: "food_group" },
		{ label: "Ingredient Type", value: "category" }
		// { label: "Your Portfolio", value: "portfolio" },
		// { label: "Products", value: "products" }
	];

	return (
		<div
			className={`search-filter-container ${show ? "show" : ""} row mr-0 ml-0 ${
				props.showingResults ? "" : "no-results"
			}`}
		>
			<div className="col-12">
				<div className={"search-filter-header"}>
					<h5>Search By: </h5>
					<Link to="/app/search">
						<div className="advanced-search">Advanced Search</div>
					</Link>
				</div>

				<div className="search-filters">
					{filters.map((filter: { label: string; value: string }, index: number) => (
						<Label key={index}>
							<span className="mr-2">{filter.label}</span>
							<Switch
								disabled={!["category", "food_group"].includes(filter.value)}
								onChange={() => handleToggle(filter.value)}
								checked={searchFilter?.name == filter.value}
								onColor="#3F65F1"
								onHandleColor="#2693e6"
								handleDiameter={21}
								uncheckedIcon={false}
								checkedIcon={false}
								boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
								activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
								height={20}
								width={40}
							/>
						</Label>
					))}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchFilter: state.search.filter
});

const mapDispatchToProps = {
	setSearchFilter: (filter: any) => searchService.setSearchFilter(filter)
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
