import React, { useState } from "react";
import { Loader, Plus } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { IIngredient } from "../../../interfaces/ingredient";
import { IApplicationState } from "../../../redux/reducers";
import ingredientService from "../../../services/ingredientService";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { truncate } from "../../../utils/string";

interface IProps extends RouteComponentProps {
	result: any;
	addToPortfolio: (id: string) => Promise<any>;
}

const SearchResultCard: React.FC<IProps> = (props) => {
	const [showCountries, setShowCountries] = useState<boolean>(false);
	const [addLoading, setAddLoading] = useState<boolean>(false);
	const hasMultipleCountries = props.result.country_data?.slice(1)?.length > 0;

	function add(id: string) {
		setTimeout(() => {
			setAddLoading(true);
			props.addToPortfolio(id).finally(() => {
				setAddLoading(false);
			});
		}, 0);
	}

	return (
		<Card
			key={props.result.id.slice(props.result.id.length - 3, props.result.id.length)}
			className="search-card"
		>
			<CardBody>
				<ReactImageFallback
					src={config.api.endpoints.ingredients.ingredientImage(props.result.jf_display_name)}
					fallbackImage={getIngredientImagePlaceholder()}
					alt="Ingredient Image"
					className="mr-2 ml-2"
					onClick={() => {
						props.history.push(`/app/ingredients/${props.result.id}`);
					}}
				/>
				<div className="result-name">{props.result.jf_display_name}</div>
				<span className="add" onClick={() => add(props.result.id)}>
					{addLoading ? (
						<Loader className="fa-spin" size={18} color={"#fff"} />
					) : (
						<Plus size={18} color={"#3f65f1"} />
					)}
				</span>
				<div className="search-details ml-2 pt-2">
					<span>
						<span style={{ color: "#607d8b" }}>Manufacturer: </span>
						{truncate(props.result?.manufacturer?.name || "General", 30)}
					</span>
					<span>
						<span className={"country"} onClick={() => setShowCountries(!showCountries)}>
							{
								<i
									className={`${
										props.result.country_data?.country?.alpha_2?.toLowerCase() || "us"
									} flag list-flag`}
								></i>
							}
							{hasMultipleCountries ? <span className="arrow-down"></span> : <></>}
						</span>
						{showCountries && hasMultipleCountries ? (
							<span className="country-list">
								<ul>
									{props.result.country_data.slice(1).map((c: any) => {
										return (
											<li>
												<i
													className={`${c.country?.alpha_2?.toLowerCase() || "us"} flag list-flag`}
												></i>
											</li>
										);
									})}
								</ul>
							</span>
						) : (
							<></>
						)}
					</span>
				</div>
			</CardBody>
		</Card>
	);
};

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id)
};

export default connect(null, mapDispatchToProps)(withRouter(SearchResultCard));
