import { USER_ROLES } from "../interfaces/roles";

const API = "/api/v1";

export default {
	baseURI: process.env.REACT_APP_BASE_URL,
	api: {
		endpoints: {
			dashboard: {
				overview: API + "/dashboard/",
				productAnalytics: API + "/dashboard/product_analytics/"
			},
			company: {
				companies: API + "/companies/",
				plant_based: API + "/company/plant_based/",
				markets: {
					add: `${API}/company/markets/`,
					remove: (id: string) => `${API}/company/${id}/markets/`
				},
				integrations: (id: string) => `${API}/company/${id}/integrations/`,
				permissions: (id: string) => `${API}/company/${id}/`,
				update_company_types: `${API}/company/company_type/`,
				types: `${API}/profile_company_type/`,
				remove_company_type: (id: string) => `${API}/company/company_type/${id}`
			},
			search: {
				all: (name: string) => `${API}/ingredients/${encodeURIComponent(name)}/search/`,
				manufacturers: (name: string) =>
					`${API}/manufacturers/ingredient/${encodeURIComponent(name)}/search/`,
				manufacturerIngredients: `${API}/ingredients/filter/`,
				categories: `${API}/ingredients/filter/`,
				productsUsingIngredients: (term: string) =>
					`${API}/user/products/${encodeURIComponent(term)}/search/`,
				productsMatchingNameOrFlavor: () => `${API}/user/products/search/`,
				packaging: (term: string) => `${API}/packaging/${encodeURIComponent(term)}/search/`
			},
			login: API + "/auth/login/",
			refreshToken: process.env.REACT_APP_BASE_URL + "api/v1" + "/auth/new_token/",
			register: API + "/users/register/",
			admin: {
				users: API + "/users/",
				approve: API + "/auth/activate/",
				reject: API + "/auth/deactivate/",
				products: API + "/admin/products/",
				requests: API + "/reports/request/",
				reports: API + "/reports/",
				editProduct: (productId: string) => API + `/admin/products/${productId}/`,
				editUser: (userId: string) => `${API}/users/${userId}/`,
				email: API + "/emails/schedules/",
				updateUserStatus: (userId: string) => `${API}/user/${userId}/disable`,
				modifyUser: (userId: string) => `${API}/user/${userId}/modify/`,
				updateCompanySubscription: (companyId: string) => `${API}/company/${companyId}/`
			},
			common: {
				featureCompleteness: (featureName: string) =>
					`${API}/meta/feature_completeness/${featureName}/`,
				ingredient_features: API + "/meta/ingredient_features/",
				last_added_ingredient: API + "/meta/last_added_ingredient/",
				unique_ingredients: API + "/meta/unique_ingredients/",
				markets: `${API}/all/countries/`
			},
			user: {
				profile: API + "/users/",
				preferences: (userId: string): string => {
					return `${API}/users/${userId}/preferences/`;
				},
				profileImage: (userId: string): string => `${API}/users/${userId}/imageUpload/`,
				companyLogo: (userId: string, companyId: string): string =>
					`${API}/users/${userId}/company/${companyId}/imageUpload/`,
				brands: API + "/user/brands/",
				manufacturers: API + "/products/manufacturers/",
				addCompanyUser: API + "/company/users/add/",
				users: API + "/company/users/",
				updateUserRole: (userId: string) => `${API}/company/users/${userId}/role/`,
				removeUser: (userId: string) => `${API}/users/${userId}/`,
				walkthrough: (userId: string) => `${API}/users/${userId}/walkthrough/`
			},
			preferences: {
				portfolio: API + "/portfolio_preferences/",
				// product: API + "/profile_product_type/",
				product: API + "/product_preferences/",
				company: API + "/profile_company_type/",
				category: API + "/profile_categories/",
				packaging: API + "/packaging_preferences/",
				packagingPreferences: (userId: string) => `${API}/users/${userId}/packaging_preferences/`,
				productPreferences: (userId: string) => `${API}/users/${userId}/product_preferences/`
			},
			packaging: {
				all: (userId: string) => `${API}/users/${userId}/packaging/`,
				filter: `${API}/packaging/filter/`,
				id: (id: string) => `${API}/packaging/${id}/`
			},
			countries: {
				list: API + "/country/list"
			},
			currencies: {
				list: API + "/currency/list"
			},
			ingredients: {
				all: (role: string) =>
					`${API}/${role === USER_ROLES.ADMIN ? "admin" : "users"}/ingredients/`,
				// searchPortfolio: (role: string, term: string) => `${API}/users/ingredients/?search=${term}`,
				searchPortfolio: (role: string, term: string) =>
					`${API}/${role === USER_ROLES.ADMIN ? "admin" : "users"}/ingredients/?search=${term}`,
				ingredientImage: (name: string): string =>
					`${process.env.REACT_APP_BASE_URL}api/v1/ingredients/${name}/thumbnail/`,
				starred: API + "/ingredients/star/",
				id: (id: string, role: string) => `${API}/ingredients/${id}/`,
				ingredientPersonas: (id: string) => `${API}/ingredients/${id}/personas/`,
				similiarIngredients: (id: string) => `${API}/ingredients/${id}/similar_ingredients/`,
				recommendedIngredients: (id: string) => `${API}/ingredients/${id}/recommendations/`,
				search: (name: string) => `${API}/ingredients/${name}/search/`,
				addToPortfolio: API + "/users/ingredients/",
				removeIngredient: (id: string) => `${API}/users/ingredients/${id}/`,
				status: (id: string) => `${API}/ingredients/${id}/status/`,
				addRecentlyViewed: (id: string) => `${API}/user/ingredients/viewed/?ingredient_id=${id}`,
				recentlyViewed: `${API}/user/ingredients/viewed/`,
				edit: (ingredient_id: string) => `${API}/ingredients/${ingredient_id}/`,
				infoFileUpload: (ingredient_id: string) => `${API}/ingredient/fileupload/${ingredient_id}/`
			},
			products: {
				active: API + "/user/products/",
				concept: API + "/user/products/",
				id: (id: string) => `${API}/user/products/${id}/`,
				productPersona: (id: string) => `${API}/products/${id}/personas/`,
				productPackages: (productId: string) => `${API}/products/${productId}/packaging/`,
				addProduct: API + "/user/products/",
				removeProducts: (id: string) => `${API}/user/products/${id}/`,
				newProductVersion: (id: string) => `${API}/user/products/${id}/new_version/`,
				duplicate: (id: string) => `${API}/user/products/${id}/duplicate/`,
				imageUpload: (productId: string) => `${API}/products/${productId}/imageUpload/`,
				addRecentlyViewed: (id: string) => `${API}/user/products/viewed/?product_id=${id}`,
				recentlyViewed: `${API}/user/products/viewed/`
			},
			personas: {
				all: (userId: string) => `${API}/users/${userId}/personas/`
			},
			analytics: {
				reports: `${API}/users/`,
				timeSaved: `${API}/analytics/time_saved/`,
				productChanges: `${API}/analytics/product_changes/`
			},
			recommendations: {
				recommendations: `${API}/recommend/product/`,
				makeRecommendation: (id: string) => `${API}/recommend/product/${id}/`
			},
			reports: {
				all: API + "/reports/",
				requests: API + "/reports/request/"
			},
			product_types: {
				all: API + "/profile_product_type/"
			},
			integrations: {
				all: API + "/integrations/",
				companyIntegrations: (companyId: string) => `${API}/company/${companyId}/integrations/`,
				upload_request: `${API}/table_data/request/`,
				upload_tabular_data: `${API}/table_data/load/`,
				upload_gsheet: `${API}/data/load/gsheets/`
			},
			notifications: {
				token: API + "/notification/token/",
				all: API + "/notification/",
				readNotification: (notificationId: string) => `${API}/notification/read/${notificationId}/`
			},
			stripe: {
				paymentIntent: (tier: string) => `${API}/stripe/payment_intent/${tier}`, //Retrieves client secret Stripe key for Stripe.confirmCardPayment()
				uploadRequestPayment: (pay: string) => `${API}/stripe/payment/upload_request/${pay}/`,
				subscriptionPayment: (tier: string) => `${API}/stripe/subscription/${tier}/`,
				payInvoice: (invoice_id: string) => `${API}/invoice/pay/${invoice_id}/`,
				confirmAuthorizedPayment: (id: string) => `${API}/payment_intent/${id}/succeed/`,
				confirmSubscriptionPayment: (id: string, tier: string) =>
					`${API}/payment_intent/${id}/subscription/${tier}/succeed/`,
				paymentSuccess: () => `${API}/stripe/payment/success/`,
				allInvoices: `${API}/invoices/`,
				companyInvoices: `${API}/company/invoices/`,
				getInvoice: (invoice_id: string) => `${API}/invoices/${invoice_id}/`
			}
		}
	}
};
