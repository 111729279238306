import React, { useState } from "react";
import { SUBSCRIPTION } from "../../../../interfaces/subscription";
import SubscriptionItem from "../../components/subscriptionItem";
import { connect } from "react-redux";
import Button from "../../../../components/common/button";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import SubscriptionCheckoutModal from "../../../../components/checkout/subscriptionModal";
import productTypeService from "../../../../services/productTypeService";
import { defaultProps } from "react-select/src/Select";
import { IApplicationState } from "../../../../redux/reducers";
import { ArrowRight, Loader, X } from "react-feather";
import InvoiceTotal from "../components/invoiceTotal";
import { IUser } from "../../../../interfaces/user";
import { toCamelCase } from "../../../../utils/string";
import Upgrade from "../../../../components/common/upgrade";


interface IProps {
    active: SUBSCRIPTION;
    profile?: IUser | undefined;
}

const Subscription: React.FC<IProps> = (props) => {
    const [checkoutModal, setCheckoutModal] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<any>();

    const handleSelect = () => {
        setCheckoutModal(true);
        return;
    }

    const getPrice = (type: string) => {
        switch(type) {
            case 'supplier': return 60.00;
            case 'growth': return 499.00;
            case 'group': return 999.00;
            case 'fresh': return 199.00;
            default: return 0;
        }
    }

    return (
        <Col className="subscription invoices">
            <Row className={'mt-2 mb-3'}>
                <p className="font-16">Active: 
                    <span className="color-bg-blue font-16 ml-2" >
                        { props.profile?.company.subscription_type ? 
                            toCamelCase(props.profile?.company.subscription_type) :
                            <Loader className='fa-spin' size={16}/>
                        }
                    </span>
                </p>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <h3>Itemized Subscription</h3>
                    <div style={{marginLeft: 'auto', marginRight: 0}}>
                        <Upgrade/>
                    </div>
                </div>
            </Row>
            <Row>
                <Table className={'clean-table'}>
                    <thead>
                        <tr>
                            <th> Service </th>
                            <th> Type </th>
                            <th> Description </th>
                            <th> Status </th>
                            <th> Price </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> { toCamelCase(props.profile?.company?.subscription_type || '') } Subscription </td>
                            <td> Subscription </td>
                            <td> Monthly charge for supplier tier platform access</td>
                            <td> <span>Paid</span></td>
                            <td> {'$' + getPrice(props.profile?.company?.subscription_type || '')} </td>
                            <td>
                                <Button color="secondary" outline className="color-battleship-grey"
                                    onClick={() => setSelectedInvoice({
                                        amount: `$ + ${getPrice(props.profile?.company?.subscription_type || '')}  USD`,
                                        created: new Date(),
                                        payment_for: 'supplier_subscription',
                                        description: 'Monthly charge supplier tier platform access'
                                    })}
                                >
                                    <ArrowRight size={18} className="mr-2" />
                                    View
                                </Button>
                            </td>
                        </tr>
                        {/* <tr>
                            <td> Customer XI (CX.i) </td>
                            <td> Add On </td>
                            <td> Monthly charge for CX.i integration </td>
                            <td> Paid </td>
                            <td> $30 </td>
                            <td>
                                <Button color="secondary" outline className="color-battleship-grey"
                                    onClick={() => setSelectedInvoice({
                                        amount: '$70.00 USD',
                                        created: new Date(),
                                        payment_for: 'cxi_integration',
                                        description: 'Monthly charge for CX.i integration services'
                                    })}
                                >
                                    <ArrowRight size={18} className="mr-2" />
                                    View
                                </Button>
                            </td>
                        </tr> */}
                    </tbody>
                </Table>
                <InvoiceTotal subTotal={getPrice(props.profile?.company?.subscription_type || '')}/>
            </Row>
            <Modal isOpen={checkoutModal}>
                <ModalHeader>
                    <div className={'modal-header-content'}>
                        <span>Update Your Subscription</span>
                        <X onClick={() => setCheckoutModal(false)} className={'close-modal'} size={24}></X>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <SubscriptionCheckoutModal active={props.active}/>
                </ModalBody>
            </Modal> 
        </Col>
    )
}
const mapStateToProps  = (state: IApplicationState) => ({
    profile: state.user.profile,
})

export default connect(mapStateToProps)(Subscription);