import { SUBSCRIPTION } from "../../interfaces/subscription";
import { loadStripe, PaymentMethod, StripeError } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import paymentService from "../../services/paymentService";
import { IApplicationState } from "../../redux/reducers";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../routes/routes";
import { Loader } from "react-feather";
import StripeClimate from "./stripeClimate";

/* CheckForm contains Stripe Elements that are validated and accessed inside of a Stripe <Element> component through the exported CheckoutModal class which 
    passes in a Stripe object promise that handles validation and secure payment processing.
*/

interface CheckoutProps {
	processPayment: (
		paymentMethodId: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => Promise<any>;
}

const CheckoutForm: React.FC<CheckoutProps> = ({ processPayment }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState<StripeError | null>(null);
	const [cardComplete, setCardComplete] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
	const [billingDetails, setBillingDetails] = useState({
		email: "",
		phone: "",
		name: ""
	});
	const [paid, setPaid] = useState<boolean>();

	/*
	 * Returns a promise of either an error or resolved payment intent following card authorization with Stripe
	 * - Required when a card's initial payment attempt 'requires_action' (authentication)
	 *
	 * @param {number} pi_client_secret The payment Stripe client secret for a payment intent with 'requires_action' status
	 * @retunrs {Promise<any>} payment intent with a 'succeeded' status or Stripe error
	 */
	const authorizePaymentCallBack = async (pi_client_secret: string) => {
		try {
			const response = await stripe?.confirmCardPayment(pi_client_secret).then((res: any) => {
				if (res.error) {
					setError(res.error);
					setProcessing(false);
					return res;
				} else if (res.paymentIntent.status == "succeeded") {
					setPaid(true);
					return res.paymentIntent;
				}
			});
			return response;
		} catch (error) {
			return error;
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		if (error) {
			if (elements != null) {
				elements.getElement("card")?.focus();
			}
			return;
		}

		if (cardComplete) {
			setProcessing(true);
		}

		const card = elements.getElement(CardElement);
		let payment_method: any;
		if (card != null) {
			// Create a payment method; id is used for confirm card payment
			payment_method = await stripe.createPaymentMethod({
				type: "card",
				card: card,
				billing_details: billingDetails
			});
		}

		if (payment_method?.error && card != null) {
			setError(payment_method?.error);
		} else {
			if (payment_method != null) {
				try {
					await processPayment(payment_method.paymentMethod.id, authorizePaymentCallBack).then(
						(res: any) => {
							switch (res?.status) {
								case 500:
									throw Error("Please check that your entered the correct card information");
								case 200:
									setPaid(true);
									setProcessing(false);
									break;
								default:
									setProcessing(false);
									break;
							}
						}
					);
				} catch (error) {
					setError(error);
					setProcessing(false);
				}
			}
		}
	};

	const reset = () => {
		setError(null);
		setProcessing(false);
	};

	return (
		<div>
			{paymentMethod ? (
				<div className="Result">
					<div className="ResultTitle" role="alert">
						Payment successful
					</div>
					<div className="ResultMessage">
						Thanks for trying Stripe Elements. No money was charged, but we generated a
						PaymentMethod: {paymentMethod.id}
					</div>
					<Button onClick={reset}> Reset </Button>
				</div>
			) : (
				<>
					<Form className="checkout-form">
						<FormGroup>
							<Label for={"name"}>Name</Label>
							<Input
								className="checkout-form-input"
								type="text"
								name="name"
								id="name"
								placeholder="Jane Doe"
								autoComplete="name"
								value={billingDetails.name}
								onChange={(e) => {
									setBillingDetails({
										...billingDetails,
										name: e.target.value
									});
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label for={"email"}>Email</Label>
							<Input
								className="checkout-form-input"
								type="email"
								name="email"
								id="email"
								placeholder="example@gmail.com"
								autoComplete="email"
								value={billingDetails.email}
								onChange={(e) => {
									setBillingDetails({
										...billingDetails,
										email: e.target.value
									});
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label for={"phone"}>Phone</Label>
							<Input
								className="checkout-form-input"
								type="tel"
								name="phone"
								id="phone"
								placeholder="(941) 555-0123"
								autoComplete="tel"
								value={billingDetails.phone}
								onChange={(e) => {
									setBillingDetails({
										...billingDetails,
										phone: e.target.value
									});
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label>Credit Card Information</Label>
							<div className="checkout-card-input">
								<CardElement
									onChange={(e) => {
										e.error ? setError(e.error) : setError(null);
										setCardComplete(e.complete);
									}}
								/>
							</div>
						</FormGroup>
						{error ? <div style={{ color: "#e85a73" }}>{error.message}</div> : <></>}
						<div className={"d-flex justify-content-center align-items-center"}>
							<Button
								disabled={processing || !cardComplete || paid}
								className="checkout-form-submit"
								onClick={handleSubmit}
							>
								{processing ? <Loader className="fa-spin mr-2" size={18} color="#fff" /> : <></>}
								{processing ? "Processing Payment" : "Subscribe"}
							</Button>
						</div>
					</Form>
					<StripeClimate />
				</>
			)}
		</div>
	);
};

interface IProps extends RouteComponentProps {
	active?: SUBSCRIPTION;
	company_id: string | undefined;
	makeSubscriptionPayment: (tier: string, paymentMethodId: string) => Promise<any>;
	confirmSubscribe: (id: string, tier: string) => Promise<any>;
}

const SubscriptionCheckoutModal: React.FC<IProps> = ({
	active,
	company_id,
	confirmSubscribe,
	...props
}) => {
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

	const processPayment = async (
		paymentMethodId: string,
		authorizePaymentCallback?: (pi_client_secret: string) => Promise<any>
	) => {
		return await props
			.makeSubscriptionPayment("supplier", paymentMethodId)
			.then(async (res: any) => {
				switch (res.status) {
					case 200:
						props.history.push(routes.LOGIN);
						return res;
					case 500:
						return res;
					case "requires_action":
						// Initialize card authorization callback if required
						if (authorizePaymentCallback) {
							await authorizePaymentCallback(res.client_secret).then((res: any) => {
								if (res.status == "succeeded") {
									confirmSubscribe(res.id, "supplier").then((res: any) => {
										if (res.status == 200) {
											props.history.push(routes.LOGIN);
										}
										return;
									});
								}
							});
						}
						break;
				}
			});
	};

	return (
		<div className={"checkout-container"}>
			<Elements stripe={stripePromise}>
				<CheckoutForm processPayment={processPayment} />
			</Elements>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	company_id: state.user.profile?.company.id
});

const mapDispatchToProps = {
	makeSubscriptionPayment: (tier: string, paymentMethodId: string) =>
		paymentService.makeSubscriptionPayment(tier, paymentMethodId),
	confirmSubscribe: (id: string, tier: string) => paymentService.confirmSubscribe(id, tier)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscriptionCheckoutModal));
