import React from "react";
import { IManufacturer } from "../../../../interfaces/products";
import ManufacturerIngredients from "./manufacturerIngredients";

export interface ISearchManufacturer {
	name: string;
	id: string;
}

interface IProps {
	searchManufacturers: ISearchManufacturer[];
	display: string;
}

const ManufacturerResults: React.FC<IProps> = ({ searchManufacturers, ...props }) => {
	return (
		<>
			{searchManufacturers.map((manufacturer: ISearchManufacturer, index: number) => {
				return (
					<div key={index}>
						<ManufacturerIngredients display={props.display} manufacturer={manufacturer} />
					</div>
				);
			})}
		</>
	);
};

export default ManufacturerResults;
