import { USER_ROLES } from "../../interfaces/roles";
import { user } from "../actions/actionContants";
import storage from "../../utils/storage";
import JwtDecode from "jwt-decode";
import { IUser } from "../../interfaces/user";
import { DeepPartial } from "redux";
import { ActionType } from "../actions/action";
import { IPreference } from "../../interfaces/preference";
import { IBrand } from "../../interfaces/company";

export interface IUserState {
	email: string | null;
	role: string;
	user_id: string | null;
	isLoggedIn: boolean;
	userPreferences: IPreference[];
	profile?: IUser;
	users: IUser[];
	selected_brand: IBrand;
}

const INIT_STATE: IUserState = {
	email: "",
	role: "",
	user_id: "",
	isLoggedIn: false,
	userPreferences: [],
	users: [],
	selected_brand: {
		id: ""
	}
};

function getAuthState(): IUserState {
	const token = storage.getItem("access_token");

	if (!token) return INIT_STATE;

	try {
		const user = JwtDecode(token) as any;

		return {
			email: user.email,
			role: user.claims.role,
			isLoggedIn: !!user.claims.app_user_id,
			user_id: user.claims.app_user_id,
			userPreferences: [],
			users: [],
			selected_brand: { id: "" }
		};
	} catch {
		return INIT_STATE;
	}
}

export default function (state: IUserState = getAuthState(), action: ActionType): IUserState {
	switch (action.type) {
		case user.PROFILE_RESPONSE:
			return { ...state, profile: action.payload };
		case user.LOGIN_RESPONSE:
			return { ...state, ...getAuthState() };
		case user.USER_PREFERENCE_RESPONSE:
			return { ...state, userPreferences: action.payload };
		case user.LOGOUT_REQUEST:
			return {
				...INIT_STATE,
				isLoggedIn: false
			};
		case user.SELECT_BRAND:
			return {
				...state,
				selected_brand: action.payload
			};
		case user.GET_ALL_COMPANY_USERS:
			return { ...state, users: action.payload };
		default:
			return state;
	}
}
