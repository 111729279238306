import React, { useEffect, useState } from "react";
import { ArrowRight, Loader, Users } from "react-feather";
import { connect } from "react-redux";
import StateManager from "react-select";
import { Card, CardBody, Table } from "reactstrap";
import { IPackage } from "../../../interfaces/package";
import { IApplicationState } from "../../../redux/reducers";
import packagingService from "../../../services/packagingService";
import packageImagePlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../routes";
import Upgrade from "../../../components/common/upgrade";
import { IUser } from "../../../interfaces/user";
import { IPaginateList } from "../../../interfaces/pagination";

interface IProps extends RouteComponentProps {
	matchedPackages: IPaginateList<IPackage[]>;
	user_id: string | null;
	getMatchedPackages: (user_id: string) => Promise<any>;
	profile?: IUser | undefined;
}

const PackagesCard: React.FC<IProps> = (props) => {
	const [loading, setLoading] = useState<boolean>();

	useEffect(() => {
		setLoading(true);
		if (props.user_id) {
			props.getMatchedPackages(props.user_id).finally(() => {
				setLoading(false);
			});
		}
	}, [props.user_id]);

	return (
		<Card className="dashboard-packaging">
			<CardBody>
				<header>
					<i className="icon-icon-nav-packaging" />
					{props.profile?.company?.subscription_type == "supplier" ? (
						<Upgrade />
					) : (
						<div>
							<h3 className={loading ? "ghost" : ""}>
								{loading ? "" : props.matchedPackages?.pagination.total}
							</h3>
							<h4>Matched Packages</h4>
						</div>
					)}
				</header>
				<div className="actions">
					<div className="view packaging" onClick={() => props.history.push(routes.PACKAGING)}>
						Packaging <ArrowRight size={16} />
					</div>
					<div className="view preferences" onClick={() => props.history.push(routes.PREFERENCES)}>
						Preferences <ArrowRight size={16} />
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	matchedPackages: state.packaging.packaging,
	user_id: state.user.user_id,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getMatchedPackages: (user_id: string) => packagingService.getAllPackages(user_id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackagesCard));
