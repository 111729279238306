import React from "react";
import { Row, Col } from "reactstrap";

import SuitabilityIcon from "../../../assets/images/suitability-score-icon2.svg";
import PackagingIcon from "../../../assets/images/icon-nav-packaging.svg";
import SustainabilityScore from "../../../assets/images/sustainability-scale-icon.svg";
import { Users } from "react-feather";
import ProgressGradient from "../../../components/common/progressGradient";

const Scores = () => {
	return (
		<>
			<div style={{ marginBottom: 35 }}>
				<p className="font-18 font-weight-semibold mb-0">Company scoring</p>
				<small className="font-14 color-battleship-grey">
					These are your aggregated scores for each scoring type.
				</small>
			</div>
			<Row>
				<Col
					xs={5}
					style={{
						marginBottom: 30,
						marginRight: 27,
						display: "flex",
						alignItems: "center"
					}}
				>
					<img src={SuitabilityIcon} style={{ marginRight: 22, width: 38 }} />
					<ProgressGradient value={0} title={"Preference"} color={"#7c4aa9"} />
				</Col>
				<Col
					xs={5}
					style={{
						marginBottom: 30,
						marginRight: 27,
						display: "flex",
						alignItems: "center"
					}}
				>
					<img src={PackagingIcon} style={{ marginRight: 22, width: 38, fill: "red" }} />
					<ProgressGradient value={70} title={"Packaging"} color={"#3f65f1"} />
				</Col>
				<Col
					xs={5}
					style={{
						marginBottom: 30,
						marginRight: 27,
						display: "flex",
						alignItems: "center"
					}}
				>
					<img src={SustainabilityScore} style={{ marginRight: 22, width: 38, color: "red" }} />
					<ProgressGradient value={0} title={"Sustainability"} color={"#0ed7ac"} />
				</Col>
				<Col
					xs={5}
					style={{
						marginBottom: 30,
						marginRight: 27,
						display: "flex",
						alignItems: "center"
					}}
				>
					<Users style={{ marginRight: 22, width: 38, color: "#f4a850" }} />
					<ProgressGradient value={0} title={"Persona"} color={"#f4a850"} />
				</Col>
			</Row>
		</>
	);
};

export default Scores;
