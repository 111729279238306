import React, { MouseEventHandler } from "react";
import { Card, Row, Col } from "reactstrap";
import { PRODUCT_TYPE, IProduct } from "../../../interfaces/products";
import { Users, ShoppingCart, Trash } from "react-feather";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { routes } from "../../routes";
import { product } from "../../../redux/actions/actionContants";
import { CurrencyFormat } from "../../../utils/currency";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";

interface IProps extends RouteComponentProps {
	productType: PRODUCT_TYPE;
	onClick?: MouseEventHandler;
	product: IProduct;
	onRemoveHandler?: (productId: string) => void;
}

const ProductItem: React.FunctionComponent<IProps> = (props) => {
	return (
		<Card className="product-item">
			<div className="header">
				<span className={props.productType + " version"}>v{props.product.version}</span>
				<span
					style={{
						backgroundColor: "#8fa1b3",
						borderRadius: "6px",
						fontSize: "12px",
						fontWeight: 600
					}}
				>
					AI
				</span>
				{props.productType === PRODUCT_TYPE.ACTIVE && (
					<ShoppingCart size={18} className="color-bg-slate" />
				)}
				{props.onRemoveHandler && (
					<Trash
						size={16}
						onClick={() =>
							props.onRemoveHandler ? props.onRemoveHandler(props.product.id || "") : null
						}
						style={{ position: "absolute", right: "45px" }}
					/>
				)}
				<i className={`${props.product.country?.alpha_2?.toLowerCase() || "us"} flag`}></i>
			</div>

			<div
				className="detail"
				onClick={
					props.onClick
						? props.onClick
						: () => props.history.push(`${routes.PRODUCTS}/${props.product.id}`)
				}
			>
				<div className="product-image">
					{props.product.image_uri !== "" ? (
						// <img style={{borderRadius: 6}} src={props.product.image_uri} />
						<ReactImageFallback
							style={{ borderRadius: 6 }}
							src={props.product.image_uri}
							fallbackImage={getIngredientImagePlaceholder()}
							alt="Product Image"
						/>
					) : (
						<img
							style={{ borderRadius: 6 }}
							src="https://drive.google.com/uc?id=1u6E7OSOTZzqdkMk3Tr-xd_9eK9ueiCDN"
							alt=""
						/>
					)}
				</div>

				<div className="product-description">
					<h5>{props.product.name}</h5>
					<Row>
						<Col>
							<small className="color-battleship-grey">Size:</small>
							<span className="font-16">{props.product.serving_size}</span>
							<span className="mt-1 d-flex align-items-center">
								<Users className="mr-1 color-bg-slate" size={19} />
								<small className="h5 mb-0 font-weight-semibold">
									+{props.product.top_personas?.length || 0}
								</small>
							</span>
						</Col>
						<Col>
							<small className="color-battleship-grey">Margin:</small>
							<span className="font-16">
								{CurrencyFormat(
									props.product.cost_margin,
									props.product.currency?.alpha_3 || "USD"
								)}
							</span>
							<span className="mt-1 d-flex align-items-center">
								<i className="icon-nav-ingredients fa-lg mr-1 color-bg-slate"></i>
								{props.product.ingredients && (
									<small className="h5 mb-0 font-weight-semibold">
										+{props.product.ingredients?.length || 0}
									</small>
								)}
							</span>
						</Col>
					</Row>
				</div>
			</div>
		</Card>
	);
};

export default withRouter(ProductItem);
