import React, { useState } from "react";
import { HelpCircle } from "react-feather";
import { Tooltip } from "reactstrap";

interface IProps {
	message: string;
	style?: React.CSSProperties;
	id: string;
}

const HelpTip: React.FC<IProps> = ({ message, id, ...props }) => {
	const [show, setShow] = useState<boolean>(false);

	return (
		<div
			className="help-tip"
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
		>
			<Tooltip className="helptip" isOpen={show} target={id}>
				{message}
			</Tooltip>
			<HelpCircle id={id} size={18} style={props.style} />
		</div>
	);
};

export default HelpTip;
