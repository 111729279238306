import React, { useState } from "react";
import { Button, Row } from "reactstrap";
import { ISearchIngredient } from "../../../interfaces/ingredient";
import { Plus, Loader, Minus } from "react-feather";
import config from "../../../utils/config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ingredientService from "../../../services/ingredientService";
import { IUser } from "../../../interfaces/user";
import { IApplicationState } from "../../../redux/reducers";
import Upgrade from "../upgrade";
import { IProduct } from "../../../interfaces/products";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { IPackage } from "../../../interfaces/package";
import PackageItemPlaceholder from "../../../assets/images/product-packaging-placeholder.png";
import { truncate } from "../../../utils/string";
import { ImageSkeleton } from "../../../utils/ghostUI";

interface ISearchResultProps {
	addToPortfolio: (id: string) => Promise<any>;
	removeFromPortfolio: (id: string) => Promise<any>;
	profile: IUser | undefined;
	ingredients: ISearchIngredient[];
	portfolioIngredients: ISearchIngredient[];
	products: IProduct[];
	packaging: IPackage[];
	loading: boolean;
	show: boolean;
	handleIngredientPagination: () => boolean;
	handlePackagingPagination: () => boolean;
}

const SearchResults: React.FC<ISearchResultProps> = ({
	ingredients,
	portfolioIngredients,
	products,
	packaging,
	show,
	addToPortfolio,
	removeFromPortfolio,
	profile,
	handleIngredientPagination,
	handlePackagingPagination
}) => {
	const [addLoading, setAddLoading] = useState<{ [key: string]: boolean }>({});
	const [removeLoading, setRemoveLoading] = useState<{ [key: string]: boolean }>({});

	function add(id: string) {
		setTimeout(() => {
			setAddLoading({ ...addLoading, [id]: true });
			addToPortfolio(id).finally(() => {
				setAddLoading({ ...addLoading, [id]: false });
			});
		}, 0);
	}

	function remove(id: string) {
		setTimeout(() => {
			setRemoveLoading({ ...removeLoading, [id]: true });
			removeFromPortfolio(id).finally(() => {
				setRemoveLoading({ ...removeLoading, [id]: false });
			});
		}, 0);
	}

	function isAdding(id: string) {
		return addLoading[id] && addLoading[id] === true;
	}

	function isRemoving(id: string) {
		return removeLoading[id] && removeLoading[id] === true;
	}

	const isPortfolioIngredient = (id: string) => {
		if (!portfolioIngredients) return false;
		return portfolioIngredients.find((i: ISearchIngredient) => i.id == id) != undefined;
	};

	return (
		<div
			className={`search-result-container ${
				show && (products.length || ingredients.length) ? "show" : ""
			}`}
		>
			<Row className="mr-0 ml-0">
				{ingredients.length > 0 && (
					<div className="ingredients">
						<p>Ingredients</p>
						<ul className="list">
							{ingredients.map((item, index: number) => (
								<li key={index} className="ingredient-search-item">
									<ReactImageFallback
										src={config.api.endpoints.ingredients.ingredientImage(item.jf_display_name)}
										fallbackImage={getIngredientImagePlaceholder()}
										initialImage={
											<ImageSkeleton
												className={"img-ghost mr-2 ml-2"}
												style={{ width: 24, height: 24, borderRadius: 6 }}
											/>
										}
										alt="Ingredient Image"
										className="mr-2 ml-2"
									/>
									<Link to={`/app/ingredients/${item.id}`}>
										{truncate(item.jf_display_name || "", 25)}
									</Link>

									{isPortfolioIngredient(item.id) ? (
										<div
											className={`rounded-circle remove ${isRemoving(item.id) ? "loading" : ""}`}
											style={{ backgroundColor: "#e85a73" }}
											onClick={() => remove(item.id)}
										>
											{isRemoving(item.id) ? (
												<Loader className="fa-spin" color={"#fff"} />
											) : (
												<>
													<Minus color="#fff" />
													<span className="remove-text">Remove from Porfolio</span>
												</>
											)}
										</div>
									) : (
										<div
											className={`bg-very-light-blue rounded-circle add ${
												isAdding(item.id) ? "loading" : ""
											}`}
											onClick={() => add(item.id)}
										>
											{isAdding(item.id) ? (
												<Loader className="fa-spin" color="#3f65f1" />
											) : (
												<>
													<Plus color="#3f65f1" />
													<span className="add-text">Add to Portfolio</span>
												</>
											)}
										</div>
									)}
								</li>
							))}
						</ul>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								paddingBottom: 15
							}}
						>
							<Button
								style={{ width: "max-content" }}
								onClick={() => {
									handleIngredientPagination();
								}}
							>
								Load More
							</Button>
						</div>
					</div>
				)}

				{packaging.length > 0 && (
					<div className="packaging">
						<p>Packaging</p>
						<ul className="list">
							{packaging.map((item: IPackage) => (
								<li className="ingredient-search-item">
									<ReactImageFallback
										src={item.image_uri ? item.image_uri : ""}
										fallbackImage={PackageItemPlaceholder}
										alt="Ingredient Image"
										initialImage={
											<ImageSkeleton
												className={"img-ghost mr-2 ml-2"}
												style={{ width: 24, height: 24, borderRadius: 6 }}
											/>
										}
									/>
									<Link to={`/app/packaging/${item.id}`}>{truncate(item.name || "", 25)}</Link>
								</li>
							))}
						</ul>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								paddingBottom: 15
							}}
						>
							<Button
								style={{ width: "max-content" }}
								onClick={() => {
									handlePackagingPagination();
								}}
							>
								Load More
							</Button>
						</div>
					</div>
				)}
				{products.length > 0 && (
					<div className="products" style={{ display: "flex", flexDirection: "column" }}>
						<p>Products</p>

						{profile?.company?.subscription_type == "supplier" ? (
							<div style={{ margin: "5px", justifySelf: "center", marginTop: 30 }}>
								<Upgrade />
								<p style={{ textAlign: "center", margin: 0 }}>To discover Journey AI results</p>
							</div>
						) : (
							<ul className="list">
								{products.map((item: IProduct) => (
									<li className="ingredient-search-item">
										<ReactImageFallback
											src={config.api.endpoints.ingredients.ingredientImage(item?.name)}
											fallbackImage={getIngredientImagePlaceholder()}
											initialImage={
												<ImageSkeleton
													className={"img-ghost mr-2 ml-2"}
													style={{ width: 24, height: 24, borderRadius: 6 }}
												/>
											}
											alt="Product Image"
											className="mr-2 ml-2"
										/>
										<Link to={`/app/products/${item.id}`}>{truncate(item?.name || "", 25)}</Link>
										{/* Placeholder div for consistent spacing */}
										<div className={`rounded-circle add}`} />
									</li>
								))}
							</ul>
						)}
					</div>
				)}
			</Row>
		</div>
	);
};

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	removeFromPortfolio: (id: string) => ingredientService.removeIngredient(id)
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
