import { IProduct, IProductPersonas } from "../../interfaces/products";
import { IPackage } from "../../interfaces/package";
import { ActionType } from "../actions/action";
import { access } from "fs";
import { product } from "../actions/actionContants";
import { IPagination, IPaginateList } from "../../interfaces/pagination";
import moment from "moment";

export interface IProductState {
	concept: IPaginateList<IProduct[]>;
	active: IPaginateList<IProduct[]>;
	selected: IProduct | null;
	recentlyViewed: any[];
	selectedVersions: IProduct[];
	selectedPackages: IPackage[];
	selectedPersonas: IProductPersonas;
}

const INIT_STATE: IProductState = {
	concept: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 30,
			total: 0
		}
	},
	recentlyViewed: [],
	active: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 30,
			total: 0
		}
	},
	selected: {
		insertion_date: "",
		id: "",
		active: false,
		standard_of_identity: "",
		code: "",
		master_product_id: "",
		optimized_feature: {},
		version: 0,
		product_class: "",
		product_form: "",
		image_uri: "",
		product_type: {
			category: "",
			id: "",
			sub_category: "",
			title: ""
		},
		cost_margin: 0,
		cost: 0,
		markup: 0,
		name: "Editable Product",
		company: {
			company_goal: "",
			company_objective: "",
			id: "",
			name: "",
			email: ""
		},
		serving_size: 0,
		fulfillment_date: moment().format("YYYY-MM-DD"),
		created_date: moment().format("YYYY-MM-DD"),
		manufacturer: {
			company: {
				company_goal: "",
				company_objective: "",
				id: "",
				name: "",
				email: "'"
			},
			id: "",
			name: ""
		},
		notes: "",
		product_status: false,
		ingredients: [],
		flavor: "",
		brand: {
			company: {
				company_goal: "",
				company_objective: "",
				id: "",
				name: "",
				email: "'"
			},
			id: "",
			name: ""
		},
		currency: {
			id: "",
			name: "",
			alpha_3: "",
			numberic: ""
		},
		country: {
			id: "",
			numberic: "",
			name: "",
			alpha_2: "",
			alpha_3: "",
			currency: {
				id: "",
				name: "",
				alpha_3: "",
				numberic: ""
			}
		},
		packaging: undefined
	},
	selectedVersions: [],
	selectedPackages: [],
	selectedPersonas: {}
};

export default function (state: IProductState = INIT_STATE, action: ActionType) {
	switch (action.type) {
		case product.GET_ACTIVE_PRODUCT_RESPONSE:
			return {
				...state,
				active: action.payload
			};
		case product.GET_CONCEPT_PRODUCT_RESPONSE:
			return {
				...state,
				concept: action.payload
			};
		case product.PAGINATE_ACTIVE_PRODUCT:
			return {
				...state,
				active: {
					...state.active,
					pagination: {
						...state.active.pagination,
						page: action.payload
					}
				}
			};
		case product.PAGINATE_CONCEPT_PRODUCT:
			return {
				...state,
				concept: {
					...state.concept,
					pagination: {
						...state.concept.pagination,
						page: action.payload
					}
				}
			};
		case product.GET_PRODUCT_DETAIL_RESPONSE:
			return {
				...state,
				selected: action.payload
			};
		case product.GET_PRODUCT_PERSONA_RESPONSE:
			return {
				...state,
				selectedPersonas: action.payload
			};
		case product.GET_PRODUCT_VERSIONS_RESPONSE:
			return {
				...state,
				selectedVersions: action.payload
			};
		case product.GET_PRODUCT_PACKAGES_RESPONSE:
			return {
				...state,
				selectedPackages: action.payload.slice(0, 5)
			};
		case product.GET_RECENTLY_VIEWED_RESPONSE:
			return {
				...state,
				recentlyViewed: action.payload.slice(0, 10)
			};
	}

	return state;
}
