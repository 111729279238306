import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../redux/reducers";
import { IIngredient } from "../../../interfaces/ingredient";
import IngredientItem from "./ingredientItem";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { routes } from "../../routes";
import { Col } from "reactstrap";

import Pagination from "../../../components/pagination";
import ingredientService from "../../../services/ingredientService";
import { IPaginateList } from "../../../interfaces/pagination";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import PerfectScroll from "react-perfect-scrollbar";
import { ingredients } from "../../../redux/actions/actionContants";
import Loader from "../../../components/loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface IProps extends RouteComponentProps {
	ingredients: IPaginateList<IIngredient[]>;
	history: History;
	fetchIngredients: () => Promise<any>;
	paginate: (page: number) => void;
	searchIngredient: (name: string) => Promise<any>;
	removeIngredient: (ingredientId: string) => Promise<any>;
}

const AllIngredients: React.FC<IProps> = ({
	ingredients: { list, pagination },
	history,
	fetchIngredients,
	paginate,
	removeIngredient
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [removing, setRemoving] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		fetchIngredients().finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	function onRemoveHandler(ingredientId: string) {
		setRemoving(true);

		confirmAlert({
			title: "Remove Ingredients",
			message: "Are you sure you want to remove this ingredient?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						removeIngredient(ingredientId).finally(() => {
							setLoading(true);
							fetchIngredients().finally(() => {
								setLoading(false);
							});
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	return (
		<>
			<div
				className="all-ingredients"
				style={{ paddingTop: 45, paddingRight: 45 }}
			>
				<span className="title mb-2">
					<h4 className="mr-2 font-weight-semibold">All Ingredients</h4>
					<h4 className="count color-bg-blue font-weight-normal">
						{pagination.total}
					</h4>
					<Pagination
						pages={pagination.pages}
						page={pagination.page}
						size={pagination.size}
						total={pagination.total}
						handlePageChange={paginate}
					/>
				</span>

				<Loader isLoading={loading}>
					<Col xl="12" className="all-ingredients-list col-lg-12 scroll-y">
						{list.map((ingredient) => (
							<IngredientItem
								manufacturer={ingredient.manufacturer}
								key={ingredient.id}
								rank={ingredient.rank}
								id={ingredient.id}
								name={ingredient.name}
								suitability={ingredient.suitability_score || 0}
								onClick={() => {
									history.push(routes.INGREDIENTS + `/${ingredient.id}`);
								}}
								onRemoveHandler={onRemoveHandler}
							/>
						))}
					</Col>
				</Loader>
			</div>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	ingredients: state.ingredient.ingredients
});

const mapDispatchToProps = {
	fetchIngredients: () => ingredientService.getIngredients(),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(ingredients.PAGINATE_INGREDIENTS, page));
	},
	searchIngredient: (name: string) => ingredientService.searchIngredient(name),
	removeIngredient: (ingredientId: string) =>
		ingredientService.removeIngredient(ingredientId)
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AllIngredients));
