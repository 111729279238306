import React from 'react';
import { Check } from 'react-feather';
import { Table } from 'reactstrap';

const FeatureTable: React.FC = (props) => {
    return (
        <Table className='feature-table'>
            <thead>
                <tr>
                    <th></th>
                    <th>Supplier <br/> $60/year</th>
                    <th>Fresh <br/> $199</th>
                    <th>Growth <br/> $499</th>
                    <th>Group <br/> $999</th>
                    <th>Enterprise <br/> Custom </th>
                </tr>
            </thead>
            <tbody>
                <tr className='sub-header'>
                    <td>USAGE</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className='striped'>
                    <td>Users</td>
                    <td>1</td>
                    <td>1</td>
                    <td>2</td>
                    <td>10+</td>
                    <td>10+</td>
                </tr>
                <tr>
                    <td>Customized Email Insights</td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Dashboard</td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Ingredients Tool</td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Packaging Tool</td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Packaging Tools</td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Products Tool</td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Management Help</td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='sub-header'>
                    <td>ADD-ONS</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className='striped'>
                    <td>Packaging Engine</td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>

                </tr>
                <tr>
                    <td>Cost Insights</td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Regulatory Insights</td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>

                </tr>
                <tr>
                    <td>Sustainability Insights</td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Persona Insights</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Persona Insights</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Ingredient Engine</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Product Optimization AI</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                </tr>
                <tr className='sub-header'>
                    <td>ADVANCED</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr className='striped'>
                    <td>Live Charts</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                </tr>
                <tr>
                    <td>Custom Reporting</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                </tr>
                <tr className='striped'>
                    <td>Integrations</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Check/></td>
                </tr>
            </tbody>
        </Table>
    )

}

export default FeatureTable;