import React, { useEffect, useRef, useState } from "react";
import { Loader, Plus, Star } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { IPackage } from "../../../interfaces/package";
import ingredientService from "../../../services/ingredientService";
import { IPackageFilters } from "../../../services/packagingService";
import searchService from "../../../services/searchService";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import {
	ImageSkeleton,
	SearchTableRowsSkeleton,
	SearchTableSkeleton
} from "../../../utils/ghostUI";
import { truncate } from "../../../utils/string";
import { routes } from "../../routes";
import { IApplicationState } from "../../../redux/reducers";

interface IProps extends RouteComponentProps {
	searchResults: IPackage[];
	searchLoading: boolean;
	searchPackaging: (term: string, filter: any) => Promise<any>;
	small?: boolean;
	term?: string;
}

const PackagingResultList: React.FC<IProps> = ({ searchResults, ...props }) => {
	const [isNewSearch, setIsNewSearch] = useState<boolean>();
	const termRef = useRef<string>();

	/* 	Check the search term every time search state is in loading -
	 *	If the term is unchanged, but 'Load More' action has been executed,
	 *	search page should show additional ghost ui instead of temporarily
	 * 	replacing the entire list with a ghost ui table
	 */
	useEffect(() => {
		if (termRef.current == props.term) {
			setIsNewSearch(false);
			return;
		}
		termRef.current = props.term;
		setIsNewSearch(true);
	}, [props.searchLoading]);

	const handleIngredientNav = (id: string) => {
		// Clean up pagination before navigation
		props.searchPackaging("", undefined).finally(() => {
			props.history.push(routes.PACKAGING + "/" + id);
		});
	};

	return (
		<>
			<Table className={`search-result-list ${props.small ? "small-table" : ""}`}>
				<colgroup>
					<col className="i" />
					<col className="n" />
					<col className="rec" />
					{props.small ? <></> : <col className="m" />}
					<col className="cu" />
					<col className="co" />
					<col className="ad" />
				</colgroup>
				<thead>
					<tr>
						{!props.searchLoading && searchResults?.length > 0 ? (
							<>
								<th></th>
								<th>
									<span>Name</span>
								</th>
								<th></th>
								{props.small ? (
									<></>
								) : (
									<th>
										<span>Manufacturer</span>
									</th>
								)}
								<th>
									<span>Country</span>
								</th>
								<th>
									<span>Cost</span>
								</th>
								<th></th>
							</>
						) : (
							<>
								<th />
								<th />
								<th />
								<th />
								<th />
								<th />
								<th />
							</>
						)}
					</tr>
				</thead>
				{props.searchLoading && isNewSearch ? (
					<SearchTableSkeleton numRows={5} />
				) : (
					<tbody>
						{searchResults.map((item: IPackage, index: number) => {
							return (
								<tr
									key={index}
									className={index < 3 ? "recommend" : ""}
									onClick={() => handleIngredientNav(item.id)}
								>
									<td>
										<div className="left">
											<ReactImageFallback
												src={item.image_uri ? item.image_uri : ""}
												fallbackImage={getIngredientImagePlaceholder()}
												initialImage={<ImageSkeleton className={"table-img-ghost"} />}
												alt="Ingredient Image"
												style={{ height: 40, width: 40, borderRadius: 6 }}
											/>
										</div>
									</td>
									<td data-tooltip={item.name?.length > 12 ? item.name : undefined}>
										{item.name ? truncate(item.name, 12) : ""}
									</td>
									<td className={index < 3 ? "top-pick" : ""}>
										{index < 3 ? (
											<>
												<span>
													<Star size={14} />
													Journey AI
												</span>
											</>
										) : (
											<> </>
										)}
									</td>
									{props.small ? <></> : <td>{"Manufacturer" || "General"}</td>}
									<td>{<i className={`${item.region_available || "us"} flag list-flag`}></i>}</td>
									<td>
										<span className="cost">$ $ $</span>
									</td>
									<td>
										<div className="right packaging"></div>
									</td>
								</tr>
							);
						})}
						{props.searchLoading && <SearchTableRowsSkeleton />}
					</tbody>
				)}
			</Table>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchLoading: state.packaging.search.loading,
	term: state.packaging.search.term
});

const mapDispatchToProps = {
	searchPackaging: (term: string, filters?: IPackageFilters | undefined) =>
		searchService.getPackagingSearchResults(term, filters)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackagingResultList));
