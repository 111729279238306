import React, { useEffect, useState } from "react";
import { Settings, Share2, Upload, User, X } from "react-feather";
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { IUser } from "../../../interfaces/user";
import { IApplicationState } from "../../../redux/reducers";
import { routes } from "../../../routes/routes";
import userService from "../../../services/userService";

interface IProps extends RouteComponentProps {
    profile: IUser | undefined;
    getUserProfile: () => Promise<any>;
    completeWalkthrough: (id: string) => Promise<any>;
}

const ToolTipTitle = (title: string, icon: any) => {
    return (
        <span className={'d-flex align-items-center justify-content-center'}>
            {icon}
            {title}
        </span>
    )
}

const Walkthrough: React.FC<IProps> = ({profile, ...props}) => {
    const [stepIndex, setStepIndex] = useState<number>(0);
    const [run, setRun] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(true);
    const [complete, setComplete] = useState<boolean>(false);

    const steps:Step[] = [
        {
            target: "#dashboard-joyride",
            title: ToolTipTitle('Dashboard', <i style={{color: '#0ed7ac'}} className="icon-nav-dashboard mr-2"/>),
            content: "Once you build your profile and products, your dashboard will give you a glance at your portfolio and the progress of your team's goals",
            // disableOverlayClose: true,
            isFixed: true,
            disableBeacon: true,
            offset: 0,
        },
        {
            target: '#ingredients-joyride',
            title: ToolTipTitle('Ingredients Portfolio',<i style={{color:'#f4a850'}}  className="icon-nav-ingredients mr-2"/>) ,
            content: 'Here you will find the ingredients your team has added. Upgrade your account at any time to gain access to our ingredient database of over 100,000 ingredients!',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        },
        {
            target: '#packaging-joyride',
            title: ToolTipTitle('Packaging Portfolio', <i style={{color:'#7593ff'}} className="icon-icon-nav-packaging mr-2"/>),
            content: 'Here you will find the packaging your team has added. Upgrade your account at any time to gain access to our packaging database, development tools, and recommendation engine!',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        },
        {
            target: "#account-joyride",
            title: ToolTipTitle('Account', <User color={'#3f65f1'} className={'mr-2'} />),
            content: 'Get started by completing your account and exploring your company settings and preferences',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        },
        {
            target: "#company-settings-joyride",
            title: ToolTipTitle('Tune your Profile', <Settings color={'#3f65f1'} className={'mr-2'}/>),
            content: 'Start by adding brands to your company, setting your target market for development, and bringing your team to the platform',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        },
        { 
            target: "#integrations-joyride",
            title: ToolTipTitle('Integrations', <Share2 color={'#607d8b'} className={'mr-2'}/>),
            content: 'Explore our integrations to get your ingredient data onto the platform',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        },
        { 
            target: "#csv-joyride",
            title: ToolTipTitle('Upload your Data', <Upload color={'#3f65f1'} className={'mr-2'}/>),
            content: 'Jump start your portfolio management by bringing your Excel or CSV ingredient and packaging data onto the platform!',
            // disableOverlayClose: true,
            disableBeacon: true,
            isFixed: true,
            offset: 0,
        }
    ];

    const handleJoyrideCallback = (data: any) => {
        const { action, index, status, type, lifecycle } = data;
        let navTimeOut = 0;

        if (action == 'close') {
            setComplete(true);
            if(profile?.id) props.completeWalkthrough(profile.id);
            setModal(true);
            setRun(false);
            return;
        }
        // Handle nav on step lifecyle completion
        if (lifecycle == 'complete') {
            switch (data.index) {
                case 3: 
                    if (props.location.pathname != routes.ACCOUNT ) props.history.push(routes.ACCOUNT); 
                    navTimeOut = 500;
                    break;
                case 4: 
                    if (props.location.pathname != routes.COMPANY) props.history.push(routes.COMPANY);
                    break;
                case 5: 
                    if (props.location.pathname != routes.INTEGRATIONS) props.history.push(routes.INTEGRATIONS);
                    navTimeOut = 500;
                    break;
                default: break;
            }
        }

        setTimeout(() => {
            if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                // Need to set our running state to false, so we can restart if we click start again.
                setComplete(true);
                if(profile?.id) props.completeWalkthrough(profile.id);
                setModal(true);
                setRun(false);
            }
        }, navTimeOut)
    
        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();

    };

    const onWelcomeDismiss = () => {
        setModal(false);
        if (!complete) setRun(true);
    }
    
    return (
        <>
            <Joyride
                run={run} 
                callback={handleJoyrideCallback}
                stepIndex={stepIndex}
                steps={steps}
                continuous={true}
                showProgress={true}
                styles={{
                    options: {
                        overlayColor: 'rgba(0, 0, 0, .5)',
                        primaryColor: '#3f65f1',
                        textColor: '#2c3e50',
                    }
                }}
            />
            <Modal isOpen={modal} centered>
                <ModalBody>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p className="d-flex flex-column align-items-center mb-1 mt-1" style={{textAlign: 'center'}}>
                            {!complete ? 
                                <>
                                    <h3>
                                        <i className={'icon-nav-ingredients mr-2'} style={{color: "#f4a850"}}/>
                                        Welcome to Journey Foods, {profile?.first_name || 'Ian'}!
                                        <i className={'icon-nav-ingredients ml-2'} style={{color: "#f4a850"}}/>
                                    </h3> <br/>
                                    <span style={{fontSize: '12pt'}}>
                                        Let's begin by walking you through your new space for <br/>
                                        {profile?.company.name || 'Journey Foods'}
                                    </span>
                                </> : 
                                <>
                                    <h3 className='mt-2 mb-3'>🎉  You're all set!  🎉</h3>
                                    <p style={{textAlign: 'center', fontSize: '12pt'}}>
                                        As you start to bring your data onto the platform and manage your team's portfolio, our team members will be available to help at every step.
                                        <br/><br/>
                                        At any point, you can revisit this tour via the <Link to={routes.KNOWLEDGE_HUB}>Knowledge Hub</Link>
                                        <br></br>
                                        The Knowledge Hub is also a great resource for FAQ's but our team can always be reached at <br/>
                                        <a href="mailto:help@journeyfoods.com">hello@journeyfoods.com</a>
                                    </p>

                                </>
                            }

                        </p>
                        <Button className="mt-3" style={{backgroundColor: '#3f65f1', borderColor: "#3f65f1", color: '#fff'}}
                            onClick={onWelcomeDismiss}
                        >
                            Get Started
                        </Button>
                    </div>

                </ModalBody>
            </Modal>
        </>

    )
}

const mapStateToProps = (state: IApplicationState) => ({
    profile: state.user.profile,
})

const mapDispatchToProps = ({
    getUserProfile: () => userService.getUserProfile(),
    completeWalkthrough: (id: string) => userService.setWalkthroughComplete(id),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(React.memo(Walkthrough)));