import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Sliders } from "react-feather";
import searchService from "../../../services/searchService";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";

interface IProps {
	setSearchFilter: (filter: any) => void;
	searchFilter: (filter: any) => void;
}

const SearchFilters: React.FC<IProps> = ({ setSearchFilter, searchFilter }) => {
	useEffect(() => {
		const cleanup = () => {
			setSearchFilter(undefined);
		};
		return cleanup();
	}, []);

	const handleToggle = (name: string) => {
		const filter = searchFilter?.name == name ? undefined : { name: name, status: true };
		setSearchFilter(filter);
	};

	const filters = [
		{ label: "Product Application", value: "category" },
		{ label: "Manufacturer", value: "manufacturer" },
		{ label: "Ingredient Type", value: "food_group" }
	];

	return (
		<div className="advanced-search-filter-container mb-3 ">
			<div className="filters-title">
				<Sliders size={24} />
				<h6>Search By:</h6>
			</div>
			<div className={"advanced-search-filters"}>
				{filters.map((filter: { label: string; value: string }, index: number) => (
					<div className="filter">
						<h6> {filter.label} </h6>
						<Switch
							disabled={!["category", "manufacturer", "food_group"].includes(filter.value)}
							onChange={() => handleToggle(filter.value)}
							checked={searchFilter?.name == filter.value}
							onColor="#3F65F1"
							onHandleColor="#2693e6"
							handleDiameter={21}
							uncheckedIcon={false}
							checkedIcon={false}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
							height={20}
							width={40}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchFilter: state.search.filter
});

const mapDispatchToProps = {
	setSearchFilter: (filter: any) => searchService.setSearchFilter(filter)
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchFilters);
