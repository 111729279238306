import React from "react";
import { Card } from "reactstrap";
import ForgotPasswordForm from "./forms/forgotPasswordForm";




const ForgotPassword: React.FC<any> = (props) => {



    return (
        <Card className="login">
            {/* <LoginForm /> */}
            <ForgotPasswordForm />
        </Card>

    )
}


export default ForgotPassword;