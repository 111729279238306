import { ISimiliarIngredient } from "../../../interfaces/ingredient";

export const similarSugars: ISimiliarIngredient[] = [
	{
		ingredient: {
			jf_display_name: "Dextrose",
			id: "603e59d587642e513abf0853"
		},
		popularity: 50.0,
		similarity: 75.0
	},
	{
		ingredient: {
			jf_display_name: "Maltodextrin",
			id: "603e59d587642e513abefeef"
		},
		popularity: 60.0,
		similarity: 50.0
	},
	{
		ingredient: {
			jf_display_name: "Corn Syrup Solids",
			id: "5ef16c98b3a431e6718481e0"
		},
		popularity: 65.0,
		similarity: 60.0
	},
	{
		ingredient: {
			jf_display_name: "Maple Sugar",
			id: "5ef16897b3a431e671847f67"
		},
		popularity: 45.0,
		similarity: 55.0
	},
	{
		ingredient: {
			jf_display_name: "Maple Sugar",
			id: "5ef16897b3a431e671847f67"
		},
		popularity: 45.0,
		similarity: 55.0
	},
	{
		ingredient: {
			jf_display_name: "D-Mannose",
			id: "603e59d587642e513abf0847"
		},
		popularity: 61.0,
		similarity: 57.0
	},
	{
		ingredient: {
			jf_display_name: "Brown Sugar -Fine Brown",
			id: "603e59d587642e513abf07cd"
		},
		popularity: 80.0,
		similarity: 71.0
	}
];
