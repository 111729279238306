import React, { useEffect, useState } from "react";
import SuitabilityBar from "../../../components/suitabilityBar";
import { connect } from "react-redux";
import { Table, Tooltip } from "reactstrap";
import {
	ArrowRight,
	ChevronDown,
	Sliders,
	Star,
	Info,
	Share2,
	Loader as LoaderIcon
} from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import Loader from "../../../components/loader";
import { IIngredient } from "../../../interfaces/ingredient";
import { IPaginateList } from "../../../interfaces/pagination";
import ingredientImagePlaceholder from "../../../assets/images/olive-ingredient-icon.png";
import "../../../assets/scss/pages/dashboard/_dashlist.scss";
import ingredientService from "../../../services/ingredientService";
import config from "../../../utils/config";
import { routes } from "../../routes";
import Pagination from "../../../components/pagination";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import { ingredients } from "../../../redux/actions/actionContants";
import { sampleIngredients } from "../exampleData/ingredientSample";
import ProgressGradient from "../../../components/common/progressGradient";
import { toCamelCase } from "../../../utils/string";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { ImageSkeleton, TableSkeleton } from "../../../utils/ghostUI";
import userService from "../../../services/userService";
import { IUser } from "../../../interfaces/user";

interface IProps extends RouteComponentProps {
	starredIngredients: any[];
	profile: IUser | undefined;
	ingredients: IPaginateList<IIngredient[]>;
	allIngredients?: boolean;
	getUserProfile: () => Promise<any>;
	getStarredIngredients: () => Promise<any>;
	fetchIngredients: () => Promise<any>;
	paginate: (page: number) => void;
}

const ALLERGENS = [
	"ShellFish",
	"Eggs",
	"Milk",
	"Soya",
	"Tree Nuts",
	"Fish",
	"Peanut",
	"Wheat",
	"Sesame",
	"Mustard",
	"Sulphites"
];

const CATEGORIES = [
	"Additives",
	"Blends",
	"Dairy-based",
	"Fats, Oils",
	"Flavors & Salts",
	"Fruits & Vegetables",
	"Functional",
	"Grains, Fiber, Flours",
	"Nutrients",
	"Processing Aids",
	"Proteins",
	"Sweeteners"
];

const IngredientList: React.FC<IProps> = ({
	ingredients: { list, pagination },
	paginate,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [filterMenu, toggleFilterMenu] = useState<string>();
	const [marketFilters, setMarketFilters] = useState<string[]>([]);
	const [manufacturerFilters, setManufacturerFilters] = useState<string[]>([]);
	const [costFilter, setCostFilter] = useState<string | undefined>();
	const [allergenFilters, setAllergenFilters] = useState<string[]>([]);
	const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
	const [plantBasedFilter, setPlantBasedFilter] = useState<string | undefined>();
	const [roiTooltip, setRoiTooltip] = useState<boolean>(false);
	const [isSupplier, setIsSupplier] = useState<boolean>(
		props.profile?.company.subscription_type == "supplier"
	);
	// Setting copy of packages for cost demo
	const [ingredientsWCost, setIngredientsWCost] = useState<IIngredient[]>();

	useEffect(() => {
		setLoading(true);
		Promise.all([
			props.getStarredIngredients(),
			props.allIngredients ? props.fetchIngredients() : () => {}
		]).finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	useEffect(() => {
		if (!props.profile) {
			props.getUserProfile();
		}
	}, []);

	const handleFilterChange = (filter: string, type: string) => {
		switch (type) {
			case "cost":
				costFilter != filter ? setCostFilter(filter) : setCostFilter(undefined);
				break;
			case "plant-based":
				plantBasedFilter != filter ? setPlantBasedFilter(filter) : setPlantBasedFilter(undefined);
				break;
			case "allergens":
				!allergenFilters?.includes(filter)
					? setAllergenFilters(allergenFilters?.concat(filter))
					: setAllergenFilters(allergenFilters?.filter((f) => f != filter));
				break;
			case "category":
				!categoryFilters?.includes(filter)
					? setCategoryFilters(categoryFilters?.concat(filter))
					: setCategoryFilters(categoryFilters?.filter((f) => f != filter));
				break;
		}
	};

	// Currently just generates a random number of dollar signs 1-3
	const getDollarSigns = (num: number) => {
		let signs = "";
		for (let i = 0; i <= num; i++) {
			signs += " $ ";
		}
		return <span className={"cost"}>{signs}</span>;
	};

	const setIngredientDemoCosts = () => {
		const wCost = list;
		wCost.forEach((item) => {
			item.roi = parseInt(Math.random().toFixed(2));
			item.cost = Math.floor(Math.random() * 3).toPrecision(2);
			item.price_per_unit = Math.floor(Math.random() * (1000 - 100) + 100) / 100;
		});
		setIngredientsWCost(wCost);
	};

	// For sample ingredients right now
	const filterIngredients = () => {
		let filtered = sampleIngredients;

		if (costFilter) {
			filtered = filtered.sort((a: any, b: any) => {
				if (costFilter == "Premium") return b?.cost - a?.cost;
				return a?.cost - b?.cost;
			});
		}
		if (categoryFilters.length > 0) {
			filtered = filtered.filter((p) => {
				let hasCategory = false;
				p.categories?.forEach((r) => {
					if (categoryFilters.includes(r)) hasCategory = true;
				});
				return hasCategory;
			});
		}
		if (allergenFilters.length > 0) {
			filtered = filtered.filter((p) => {
				let hasAllergen = false;
				p.allergens?.forEach((r) => {
					if (allergenFilters.includes(r)) hasAllergen = true;
				});
				return !hasAllergen;
			});
		}
		if (plantBasedFilter) {
			filtered = filtered.filter((p) => {
				return plantBasedFilter == "No" ? !p.plant_based : p.plant_based;
			});
		}
		return filtered;
	};

	return (
		<div className="dash-list">
			{/* Display filters and pagination if on ingredients page showing all ingredients */}
			{props.allIngredients ? (
				<div style={{ paddingTop: 45 }}>
					<span className="title mb-2">
						<h4 className="mr-2 font-weight-semibold">All Ingredients</h4>
						<h4 className="count color-bg-blue font-weight-normal">
							{loading ? <LoaderIcon className={"fa-spin"} /> : pagination.total}
						</h4>
						<Pagination
							pages={pagination.pages}
							page={pagination.page}
							size={pagination.size}
							total={pagination.total}
							handlePageChange={paginate}
						/>
					</span>
					<span className={"filters"}>
						<span className={"filter-title"}>
							<Sliders color={"#657280"} size={24} /> Filters:{" "}
						</span>
						{/* Market Filter */}
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("cost")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={costFilter != undefined ? "filter-menu active" : "filter-menu"}>
								Cost
								<ChevronDown size={16} />
							</span>
							{filterMenu == "cost" ? (
								<ul className="list-filters">
									<li
										className={costFilter == "Premium" ? "active" : ""}
										onClick={(e) => {
											handleFilterChange(e.currentTarget.innerHTML, "cost");
										}}
									>
										Premium
									</li>
									<li
										className={costFilter == "Low Cost" ? "active" : ""}
										onClick={(e) => {
											handleFilterChange(e.currentTarget.innerHTML, "cost");
										}}
									>
										Low Cost
									</li>
								</ul>
							) : (
								""
							)}
						</div>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("plant-based")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span
								className={plantBasedFilter != undefined ? "filter-menu active" : "filter-menu"}
							>
								Plant Based
								<ChevronDown size={16} />
							</span>
							{filterMenu == "plant-based" ? (
								<ul className="list-filters">
									<li
										className={plantBasedFilter == "Yes" ? "active" : ""}
										onClick={(e) => {
											handleFilterChange(e.currentTarget.innerHTML, "plant-based");
										}}
									>
										Yes
									</li>
									<li
										className={plantBasedFilter == "No" ? "active" : ""}
										onClick={(e) => {
											handleFilterChange(e.currentTarget.innerHTML, "plant-based");
										}}
									>
										No
									</li>
								</ul>
							) : (
								""
							)}
						</div>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("allergens")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={allergenFilters.length > 0 ? "filter-menu active" : "filter-menu"}>
								Allergen
								<ChevronDown size={16} />
							</span>
							{filterMenu == "allergens" ? (
								<ul className="list-filters">
									{ALLERGENS.map((a, index: number) => (
										<li
											key={index}
											className={allergenFilters.includes(a) ? "active" : ""}
											onClick={(e) => {
												handleFilterChange(e.currentTarget.innerHTML, "allergens");
											}}
										>
											{a}
										</li>
									))}
								</ul>
							) : (
								""
							)}
						</div>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("category")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={categoryFilters.length > 0 ? "filter-menu active" : "filter-menu"}>
								Category
								<ChevronDown size={16} />
							</span>
							{filterMenu == "category" ? (
								<ul className="list-filters">
									{CATEGORIES.map((c, index: number) => (
										<li
											key={index}
											className={categoryFilters.includes(c) ? "active" : ""}
											onClick={(e) => {
												handleFilterChange(e.currentTarget.innerHTML, "category");
											}}
										>
											{c}
										</li>
									))}
								</ul>
							) : (
								""
							)}
						</div>
					</span>
				</div>
			) : (
				<></>
			)}
			<Table borderless>
				<thead>
					<tr key="ingredient-list-header">
						<th></th>
						<th>Name</th>
						<th></th>
						<th>Manufacturer</th>
						<th>Price/Unit</th>
						<th>
							ROI
							<Info
								id="roi"
								href="#"
								size={16}
								style={{
									marginLeft: 5,
									color: "#fff",
									borderRadius: "50%",
									backgroundColor: "#3f65f1"
								}}
							/>
							<Tooltip
								className="simple-tooltip"
								isOpen={roiTooltip}
								placement="top"
								target="roi"
								toggle={() => setRoiTooltip(!roiTooltip)}
							>
								Proprietary cost-benefit based on consumer spend against supply chain action
							</Tooltip>
						</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				{loading ? (
					<TableSkeleton />
				) : (
					<tbody>
						{/* { (props.allIngredients ? list : props.starredIngredients).map((i: any, index: any) =>  ( */}
						{(!(
							costFilter != undefined ||
							plantBasedFilter != undefined ||
							allergenFilters.length > 0 ||
							categoryFilters.length > 0
						)
							? props.allIngredients
								? ingredientsWCost
									? ingredientsWCost
									: list
								: props.starredIngredients
							: []
						)?.map((i: any, index: number) => (
							<tr
								onClick={() => props.history.push(`${routes.INGREDIENTS}/${i.id}`)}
								className="list-row"
								key={i.id + index}
							>
								<td>
									<ReactImageFallback
										initialImage={<ImageSkeleton className={"table-img-ghost"} />}
										src={config.api.endpoints.ingredients.ingredientImage(i.jf_display_name)}
										fallbackImage={getIngredientImagePlaceholder()}
										alt="Ingredient Image"
										className="mr-2 ml-2"
										style={{ borderRadius: 6 }}
									/>
								</td>
								<td>{toCamelCase(i.name)}</td>
								<td>
									{props.starredIngredients.find((item) => item.id === i.id) ? (
										<Star className="star" color="#f4a850" size={16} fill="#f4a850" />
									) : (
										<Star className="star" color="#657280" size={16} />
									)}
								</td>
								<td>{i.manufacturer?.name}</td>
								<td
									onClick={(e) => {
										e.stopPropagation();
										if (!(i.price || i.price_per_unit)) props.history.push(routes.INTEGRATIONS);
									}}
									style={{
										color: `${i.price || i.price_per_unit ? "default" : "#cedbe9"}`,
										cursor: "pointer"
									}}
								>
									{i.price || i.price_per_unit ? (
										`$${i.price || i.price_per_unit}`
									) : (
										<Share2 size={16} color={"#cedbe9"} />
									)}
								</td>
								<td>
									<span style={{ color: "#65728085" }}>Coming Soon</span>
								</td>
								<td>
									<i className={`${i.country?.alpha_2?.toLowerCase() || "us"} flag list-flag`}></i>
								</td>
								<td align="right">
									<span className="action">
										<ArrowRight size={17} color={"#4974f3"} className="mr-1" />
										View
									</span>
								</td>
							</tr>
						))}
					</tbody>
				)}
			</Table>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	starredIngredients: state.ingredient.starred,
	ingredients: state.ingredient.ingredients,
	profile: state.user.profile
});

const mapDispatchToProps = {
	getStarredIngredients: () => ingredientService.getStarredIngredients(),
	fetchIngredients: () => ingredientService.getIngredients(),
	paginate: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(ingredients.PAGINATE_INGREDIENTS, page));
	},
	getUserProfile: () => userService.getUserProfile()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IngredientList));
