import React, { useEffect, useState } from "react";
import { Box, Loader } from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, CardBody, CardFooter, CardTitle, Table } from "reactstrap";
import { IProduct } from "../../../../interfaces/products";
import { truncate } from "../../../../utils/string";
import Switch from "react-switch";
import Upgrade from "../../../../components/common/upgrade";
import { IUser } from "../../../../interfaces/user";
import { IApplicationState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { IPaginateList } from "../../../../interfaces/pagination";
import { uniqBy } from "../../../../utils/array";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { TableSkeleton, ImageSkeleton } from "../../../../utils/ghostUI";
import activeProducts from "../../../products/components/activeProducts";
import ingredientService from "../../../../services/ingredientService";
import productService from "../../../../services/productService";
import searchService from "../../../../services/searchService";

interface IProductSearchResult extends IProduct {
	usesIngredient?: boolean;
}

interface IProps extends RouteComponentProps {
	term?: string;
	profile: IUser | undefined;
	portfolioProductSearchResults: IProduct[];
	recentlyViewed: IProduct[];
	getRecentlyViewed: () => Promise<any>;
}

const ProductResults: React.FC<IProps> = ({
	portfolioProductSearchResults,
	recentlyViewed,
	term,
	getRecentlyViewed,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>();
	const [productUsingIngredientSearchResults, setProductUsingIngredientSearchResults] = useState<
		IProductSearchResult[]
	>([]);
	const [combinedProductSearchResults, setCombinedProductSearchResults] = useState<
		IProductSearchResult[]
	>([]);

	useEffect(() => {
		if (
			!term &&
			portfolioProductSearchResults?.length == 0 &&
			productUsingIngredientSearchResults?.length == 0
		) {
			setLoading(true);
			Promise.all([getRecentlyViewed()]).finally(() => {
				setLoading(false);
			});
		}
	}, []);

	useEffect(() => {
		if (term) {
			setLoading(true);
			searchService
				.getProductsUsingIngredient(term)
				.then((res: any) => {
					if (res.length > 0) {
						setProductUsingIngredientSearchResults(tagIngredientUsage(res));
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setCombinedProductSearchResults(recentlyViewed);
		}
	}, [term]);

	useEffect(() => {
		if (
			portfolioProductSearchResults?.length == 0 &&
			productUsingIngredientSearchResults?.length == 0 &&
			!term
		) {
			setCombinedProductSearchResults(recentlyViewed);
			return;
		}
		setCombinedProductSearchResults(
			uniqBy(
				[...portfolioProductSearchResults, ...productUsingIngredientSearchResults],
				(p: IProductSearchResult) => p.id
			)
		);
	}, [productUsingIngredientSearchResults, portfolioProductSearchResults, recentlyViewed]);

	// Tag products results found as a result of matching searched ingredient
	const tagIngredientUsage = (products: IProductSearchResult[]) => {
		if (!productUsingIngredientSearchResults) return products; // Don't attempt to tag if no products found to use ingredient
		const tagged = products.map((p: IProductSearchResult) => {
			const usesIngredient =
				productUsingIngredientSearchResults.findIndex(
					(result: IProductSearchResult) => result.id == p.id
				) >= 0;
			return { ...p, usesIngredient: usesIngredient };
		});
		return tagged;
	};

	return (
		<Card>
			<CardTitle>
				<Box size={24} color={"#3f65f1"} />
				<h4>Products</h4>
			</CardTitle>
			<CardBody
				style={loading ? { display: "flex", alignItems: "center", justifyContent: "center" } : {}}
			>
				{props.profile?.company?.subscription_type == "supplier" ? (
					<Upgrade />
				) : (
					<>
						<Table className={"portfolio-table"}>
							<colgroup>
								<col />
								<col />
								<col className="persona-score" />
							</colgroup>
							<thead>
								<tr>
									<th></th>
									<th>Name</th>
									<th></th>
								</tr>
							</thead>
							{loading ? (
								<TableSkeleton numCells={2} numRows={3} noAction />
							) : (
								<tbody>
									{combinedProductSearchResults?.map((p: IProductSearchResult, index: number) => {
										return (
											<tr key={index} onClick={() => props.history.push(`/app/products/${p.id}`)}>
												<td>
													{p?.usesIngredient ? (
														<div className="result-tag">
															<i className={"icon-nav-ingredients"} />
														</div>
													) : (
														<></>
													)}
													<ReactImageFallback
														src={p?.image_uri}
														fallbackImage={getIngredientImagePlaceholder()}
														initialImage={<ImageSkeleton className={"table-img-ghost"} />}
													/>
												</td>
												<td>{`${truncate(p.name, p.version ? 12 : 25)}`}</td>
												<td>
													{p.version && (
														<span
															style={{
																backgroundColor: "#657280",
																padding: "3px 8px",
																borderRadius: 6,
																color: "#fff",
																fontSize: 12
															}}
														>
															{`V${p.version}`}
														</span>
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							)}
						</Table>
					</>
				)}
			</CardBody>
			<CardFooter>
				<div className={"d-flex align-items-center"}>
					<div className="result-tag legend">
						<i className={"icon-nav-ingredients"} />
					</div>
					<span className={"ml-1"} style={{ color: "#2c3e50" }}>
						= Uses ingredient matching search
					</span>
				</div>
			</CardFooter>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile,
	activeProducts: state.product.active,
	conceptProducts: state.product.concept,
	term: state.search.term,
	portfolioProductSearchResults: state.search.portfolio_products,
	recentlyViewed: state.product.recentlyViewed
});

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	getRecentlyViewed: () => productService.getRecentlyViewed()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductResults));
