import { Api } from "./api";
import config from "../utils/config";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import { setSearchResult } from "../redux/actions/searchActions";
import {
	getPackagingSearchResponseAction,
	getPaginatedSearchResultsResponseAction,
	getSearchResponseAction,
	searchPortfolioProductsResponseAction,
	setPackageSearchLoadingAction,
	setPackageSearchTermAction,
	setSearchFilterAction,
	setSearchLoadingAction,
	setSearchTermAction
} from "../redux/actions/search/search";
import { IApplicationState } from "../redux/reducers";
import { IPackageFilters } from "./packagingService";

export class SearchApi extends Api {
	// Similar functionality to search but maps search term to store for display on advanced search page
	getSearchResults(searchText: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response: AxiosResponse = await this.http.get(
					config.api.endpoints.search.all(searchText),
					{
						params: {
							search: searchText
						}
					}
				);

				dispatch(getSearchResponseAction(response.data, searchText));
			} catch (error) {
				this.handleError(error, "Search");
			}
		};
	}

	paginateSearch(searchText: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			this.setSearchTerm(searchText)(dispatch, getState);

			if (searchText == "") {
				//Reset pagination on empty text
				dispatch(
					getPaginatedSearchResultsResponseAction({
						list: [],
						pagination: {
							page: 1,
							pages: 0,
							size: 10,
							total: 0
						}
					})
				);
			} else {
				try {
					const {
						search: {
							paginated_results: { pagination },
							filter
						}
					} = getState();

					let url = config.api.endpoints.search.all(searchText);
					let params: any = {
						limit: pagination.size,
						offset: pagination.page
					};
					if (filter) {
						switch (filter.name) {
							case "manufacturer":
								url = config.api.endpoints.search.manufacturers(searchText);
								break;
							case "food_group":
								params = { ...params, food_group: searchText };
							case "category":
								url = config.api.endpoints.search.categories;
								params = { ...params, categories: searchText };
								break;
						}
					}
					this.setSearchLoading(true)(dispatch, getState);
					const response = await this.http.get(url, {
						params: params
					});
					this.setSearchLoading(false)(dispatch, getState);
					dispatch(
						getPaginatedSearchResultsResponseAction({
							list: response.data.data,
							pagination: response.data.meta
						})
					);
				} catch (error) {
					this.handleError(error, "Paginate Search Results");
				}
			}
		};
	}

	setSearchFilter(filter?: any) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				dispatch(setSearchFilterAction(filter));
			} catch (error) {
				this.handleError(error, "Setting Search Filter");
			}
		};
	}

	setSearchTerm(term: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			dispatch(setSearchTermAction(term));
		};
	}

	setSearchLoading(loading: boolean) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			dispatch(setSearchLoadingAction(loading));
		};
	}

	async getManufacturerIngredients(id: string) {
		try {
			const response = await this.http.get(config.api.endpoints.search.manufacturerIngredients, {
				params: {
					manufacturer: id
				}
			});
			return response.data;
		} catch (error) {
			this.handleError(error, "Manufacturer Ingredients");
		}
	}

	getPackagingSearchResults(term: string, filters?: IPackageFilters | undefined) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			this.setPackageSearchTerm(term)(dispatch, getState);
			if (term == "") {
				//Reset pagination on empty text
				dispatch(
					getPackagingSearchResponseAction(
						{
							list: [],
							pagination: {
								page: 1,
								pages: 0,
								size: 10,
								total: 0
							}
						},
						undefined
					)
				);
			} else {
				try {
					const {
						packaging: {
							search: {
								paginated_results: { pagination }
							}
						}
					} = getState();

					const filterParams = {
						sustainability: filters?.sustainability_id,
						filling_process: filters?.filling_process_id,
						end_user_benfits: filters?.end_user_benefits_id,
						form: filters?.form_id,
						material_family: filters?.material_family_id
					};

					let url = config.api.endpoints.search.packaging(term);
					let params: any = {
						limit: pagination.size,
						offset: pagination.page,
						...filterParams
					};
					this.setPackageSearchLoading(true)(dispatch, getState);
					const response = await this.http.get(url, {
						params: params
					});
					this.setPackageSearchLoading(false)(dispatch, getState);

					dispatch(
						getPackagingSearchResponseAction({
							list: response.data.data,
							pagination: response.data.meta
						})
					);
				} catch (error) {
					this.handleError(error, "Packaging Search Results");
				}
			}
		};
	}

	setPackageSearchTerm(term: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			dispatch(setPackageSearchTermAction(term));
		};
	}

	setPackageSearchLoading(loading: boolean) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			dispatch(setPackageSearchLoadingAction(loading));
		};
	}

	async getProductsUsingIngredient(term: string) {
		try {
			const response = await this.http.get(
				config.api.endpoints.search.productsUsingIngredients(term)
			);
			return response.data;
		} catch (error) {
			this.handleError(error, "Products Using Ingredient");
		}
	}

	searchProductsByNameOrFlavor(searchTerm?: string) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			const {
				search: { term }
			} = getState();

			if (!term && !searchTerm) return dispatch(searchPortfolioProductsResponseAction([]));
			try {
				const response = await this.http.get(
					config.api.endpoints.search.productsMatchingNameOrFlavor(),
					{
						params: {
							search_term: term
						}
					}
				);
				return dispatch(searchPortfolioProductsResponseAction(response.data));
			} catch (error) {
				this.handleError(error, "Products Matching Name or Flavor");
			}
		};
	}
}

export default new SearchApi();
