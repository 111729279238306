import React, { useEffect, useState } from "react";
import { Box, Loader } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Row, Table } from "reactstrap";
import Upgrade from "../../../../components/common/upgrade";
import { IBrand } from "../../../../interfaces/company";
import { IIngredient } from "../../../../interfaces/ingredient";
import { IPaginateList } from "../../../../interfaces/pagination";
import { IProduct } from "../../../../interfaces/products";
import { IUser } from "../../../../interfaces/user";
import { IApplicationState } from "../../../../redux/reducers";
import ingredientService from "../../../../services/ingredientService";
import productService from "../../../../services/productService";
import { uniqBy } from "../../../../utils/array";
import config from "../../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import { truncate } from "../../../../utils/string";
import { routes } from "../../../routes";
import RecentlyViewedLoader from "./recentlyViewedLoader";
import RecentTable from "./recentTable";
import { ImageSkeleton } from "../../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	getRecentProducts: () => Promise<any>;
	getRecentIngredients: () => Promise<any>;
	profile?: IUser | undefined;
	selected_brand: IBrand;
}

const RecentlyViewed: React.FC<IProps> = ({
	getRecentIngredients,
	getRecentProducts,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>();
	const [recentIngredients, setRecentIngredients] = useState<any[]>([]);
	const [recentProducts, setRecentProducts] = useState<any[]>([]);

	useEffect(() => {
		setLoading(true);
		Promise.all([
			getRecentIngredients().then((res) => {
				setRecentIngredients(uniqBy(res, (i: any) => i.id));
			}),
			getRecentProducts().then((res) => {
				setRecentProducts(uniqBy(res, (i: any) => i.id));
			})
		]).finally(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		setLoading(true);
		getRecentProducts().then((res: any) => {
			setRecentProducts(uniqBy(res, (i: any) => i.id));
			setLoading(false);
		});
	}, [props.selected_brand]);

	return (
		<Card className="recent-card">
			<CardBody>
				<h4 className={"mb-3"}>Recently Viewed</h4>
				<div className="recent-grid">
					{loading ? (
						// <Loader className="fa-spin" style={{}} />
						<Row>
							<RecentlyViewedLoader numItems={6} />
						</Row>
					) : (
						<>
							<Row>
								{recentIngredients.slice(0, 4).map((i: any, index: number) => (
									<div
										key={i.id + index}
										className="recent-card"
										onClick={() => props.history.push(routes.INGREDIENTS + "/" + i.id)}
									>
										<span className="tag ingredient">
											<i className={"icon-nav-ingredients"} />
										</span>
										{/* <img
											src={config.api.endpoints.ingredients.ingredientImage(i.jf_display_name)}
										/> */}
										<ReactImageFallback
											initialImage={<ImageSkeleton className={"recent-img-ghost"} />}
											src={config.api.endpoints.ingredients.ingredientImage(i.jf_display_name)}
											fallbackImage={getIngredientImagePlaceholder()}
											alt="Ingredient Image"
										/>
										<h5 style={{ textAlign: "center" }}>{truncate(i.jf_display_name, 10)}</h5>
									</div>
								))}
								{recentProducts.slice(0, 4).map((i: IProduct, index: number) => (
									<div
										key={i.id + index}
										className="recent-card"
										onClick={() => props.history.push(routes.PRODUCTS + "/" + i.id)}
									>
										<span className="tag product">
											<Box size={24} />
										</span>
										{/* <img
											src={i.image_uri || config.api.endpoints.ingredients.ingredientImage(i.name)}
										/> */}
										<ReactImageFallback
											initialImage={<ImageSkeleton className={"recent-img-ghost"} />}
											src={config.api.endpoints.ingredients.ingredientImage(i.name)}
											fallbackImage={getIngredientImagePlaceholder()}
											alt="Ingredient Image"
										/>
										<h5 style={{ textAlign: "center" }}>{truncate(i.name, 10)}</h5>
									</div>
								))}
							</Row>
						</>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	activeProducts: state.product.active,
	ingredients: state.ingredient.ingredients,
	profile: state.user.profile,
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getRecentProducts: () => productService.getRecentlyViewed(),
	getRecentIngredients: () => ingredientService.getRecentlyViewed()
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withRouter(RecentlyViewed)));
