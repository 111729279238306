import React from 'react';

interface IProps {
    subTotal: number;
}

const InvoiceTotal: React.FC<IProps> = (props) => {
    return (
        <div className='invoice-total'>
            <p>
                <span> Subtotal </span>
                <span> {'$' + props.subTotal}</span>
            </p>
            {/* <p>
                <span> Tax 10% </span>
                <span> {'$' + props.subTotal * .1} </span>
            </p> */}
            {/* <p>
                <span> Total </span>
                <span> {'$' + (props.subTotal + (props.subTotal * .1))} </span>
            </p> */}
            <p>
                <span> Total </span>
                <span> {'$' + (props.subTotal)} </span>
            </p>
        </div>
    )
}

export default InvoiceTotal;