import React from "react";
import { Card } from "reactstrap";
import LoginForm from "./form/loginForm";
import { Link } from "react-router-dom";
import { routes } from "../../routes";


const Login: React.FC = () => (

    <Card className="login">
        <LoginForm />

        <div className="auth-action">
            <p>Forgot your password?</p>
            <Link to={routes.FORGOT_PASSWORD}>Click Here</Link>
        </div>
    </Card>

)


export default Login;