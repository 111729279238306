import { ICompany, IBrand } from "./company";
import { IPersona } from "./persona";
import { ICountry } from "./country";
import { ICurrency } from "./currency";

export enum PRODUCT_TYPE {
	ACTIVE = "active",
	CONCEPT = "concept"
}

export interface IProductIngredient {
	weight: number;
	base: number;
	percentage?: number;
	ingredient: {
		id: string;
		jf_display_name: string;
		allergens: string;
		dietary: string[];
	};
	unit: any;
}

export interface IProductPackage {
	name: string;
	id: string;
	image_uri: string;
}

export interface IProduct {
	id: string;
	active: boolean;
	name: string;
	flavor: string;
	code: string;
	standard_of_identity: string;
	product_class: string;
	product_form: string;
	product_type: IProductType;
	company: ICompany;
	brand: IBrand;
	product_status: false;
	serving_size: number;
	cost_margin: number;
	cost: number;
	markup: number;
	created_date: string;
	fulfillment_date: string;
	optimized_feature: {
		[key: string]: number;
	};
	insertion_date: string;
	version: number;
	master_product_id: string;
	weight_class?: any;
	functional_tags?: any[];
	weight?: number;
	ingredients?: IProductIngredient[];
	manufacturer: IManufacturer;
	notes: string;
	top_personas?: IPersona[];
	image_uri: string;
	country: ICountry;
	currency: ICurrency;
	packaging?: IProductPackage;
	objective?: string[];
	unit_serving_size?: string;
}

export interface IProdusct {
	id: string;
	code: string;
	cost_margin: string;
	name: string;
	company: ICompany;
	version: number;
	ingredients: {
		[key: string]: IProductIngredient[];
	};
	flavor: string[];
	optimized_feature: {
		[key: string]: number;
	};
	product_class: string;
	product_form: string;
	product_status: boolean;
	fulfillment_date: string;
	created_date: string;
	serving_size: string;
	product_type: {
		category_code: string;
		id: string;
		product_type: string;
	};
	standard_of_identity: string;
}

export interface IProductPersonas {
	[flavorKey: string]: IPersona[];
}

// OLD ProductType interface
// export interface IProductType {
//     id: string;
//     product_type: string;
//     category_code: string;
// }

export interface IProductType {
	category: string;
	id: string;
	sub_category: string;
	title: string;
}

export interface IBradnd {}

export interface IManufacturer {
	id: string;
	name: string;
	company: ICompany;
}
