import React, { useEffect, useState } from "react";
import { ListGroup, Modal, ModalHeader, ModalBody, Input, Container, Row, Table } from "reactstrap";
import { connect } from "react-redux";
import { IBrand } from "../../../../../interfaces/company";
import { X, Edit2 as Edit, Trash } from "react-feather";
import userService from "../../../../../services/userService";
import Button from "../../../../../components/common/button";
import Loader from "../../../../../components/loader";
import CreateCard from "./createCard";
import BrandTable from "./brandTable";
import CustomSelect from "../../../../../components/common/customSelect";
// import Input from "../../../../components/form/Input";

interface IProps {
	getBrands: () => Promise<any[]>;
	removeBrand: (id: string) => Promise<any>;
	addBrand: (id: string) => Promise<any>;
	editBrand: (id: string, name: string) => Promise<any>;
}

const Brand: React.FC<IProps> = ({ getBrands, addBrand, removeBrand, editBrand }) => {
	const [brands, setBrands] = useState<IBrand[]>([]);

	const [isLoading, setLoading] = useState<boolean>(false);
	const [isRemoveLoading, setRemoveLoading] = useState<boolean>(false);
	const [isAddLoading, setAddLoading] = useState<boolean>(false);
	const [isEditLoading, setEditLoading] = useState<boolean>(false);

	const [selectedBrand, setSelectedBrand] = useState<IBrand | null>(null);
	const [removeModal, setRemoveModal] = useState<boolean>(false);
	const [editModal, setEditModal] = useState<boolean>(false);

	const [newBrandText, setBrandText] = useState<string>("");
	const [editBrandText, setEditText] = useState<string>("");

	useEffect(() => {
		setLoading(true);
		getBrands()
			.then(setBrands)
			.finally(() => {
				setLoading(false);
			});
	}, []);

	function deleteBrand() {
		setRemoveLoading(true);
		removeBrand(selectedBrand?.id || "")
			.then(() => {
				setBrands(brands.filter((item) => item.id !== selectedBrand?.id));
				setBrandText("");
			})
			.finally(() => {
				setSelectedBrand(null);
				setRemoveLoading(false);
				setRemoveModal(false);
			});
	}

	function updateBrand() {
		setEditLoading(true);
		editBrand(selectedBrand?.id || "", editBrandText)
			.then((res: IBrand) => {
				if (res?.id && selectedBrand) {
					const brandIndex = brands.indexOf(selectedBrand);
					let updatedBrands = brands;
					updatedBrands[brandIndex] = res;
					setBrands(updatedBrands);
				}
			})
			.finally(() => {
				setSelectedBrand(null);
				setEditModal(false);
				setEditLoading(false);
			});
	}

	const handleBrandRemoveSelect = (brand: IBrand) => {
		setSelectedBrand(brand);
		setRemoveModal(true);
	};

	const handleBrandEditSelect = (brand: IBrand) => {
		setSelectedBrand(brand);
		setEditModal(true);
		setEditText(brand.name || "");
	};

	const mapBrandsToSelectOptions = () => {
		if (!brands) return [];
		return brands.map((brand: IBrand) => ({
			label: brand.name,
			value: brand
		}));
	};

	const handleBrandParentSelect = (option: any) => {
		console.log(option);
	};

	async function add(name: string) {
		if (name)
			return addBrand(name).then(() => {
				getBrands().then(setBrands);
			});
	}

	return (
		<>
			<Container style={{ marginLeft: 0 }}>
				<Row style={{ flexWrap: "nowrap", justifyContent: "flex-start" }}>
					<CreateCard style={{ width: "75%" }} title={"Brand"} addBrand={add} />
				</Row>
			</Container>

			<Loader isLoading={isLoading}>
				<div className="company-brand">
					{brands && (
						<BrandTable
							brands={brands}
							handleBrandEditSelect={handleBrandEditSelect}
							handleBrandRemoveSelect={handleBrandRemoveSelect}
						/>
					)}
				</div>

				<Modal
					className="brand-modal"
					isOpen={!!selectedBrand && removeModal}
					size="md"
					toggle={() => setSelectedBrand(null)}
				>
					<ModalHeader>Remove Brand</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to remove brand "{selectedBrand?.name}"?</p>
						<Button
							color="danger"
							onClick={deleteBrand}
							className="color-white"
							loading={isRemoveLoading}
							style={{ float: "right" }}
						>
							Remove Brand
						</Button>
						<Button
							color="secondary"
							className="mr-2"
							onClick={() => setSelectedBrand(null)}
							style={{ float: "right" }}
						>
							Cancel
						</Button>
					</ModalBody>
				</Modal>

				<Modal
					className="brand-modal"
					isOpen={!!selectedBrand && editModal}
					size="md"
					toggle={() => setEditModal(false)}
				>
					<ModalHeader>Edit Brand </ModalHeader>
					<ModalBody>
						<h5>Brand Name</h5>
						<Input
							placeholde="Brand Name"
							onChange={(e) => setEditText(e.target.value)}
							value={editBrandText}
							className={"mb-3"}
							style={{ width: "100%", height: 40 }}
						/>
						<Button
							color="primary"
							onClick={updateBrand}
							className="color-white"
							loading={isEditLoading}
							style={{ float: "right" }}
						>
							Edit Brand
						</Button>
						<Button
							color="secondary"
							className="mr-2"
							onClick={() => setEditModal(false)}
							style={{ float: "right" }}
						>
							Cancel
						</Button>
					</ModalBody>
				</Modal>
			</Loader>
		</>
	);
};

const mapDispatchToProps = {
	getBrands: () => userService.getBrands(),
	removeBrand: (id: string) => userService.removeBrand(id),
	addBrand: (name: string) => userService.addBrand(name),
	editBrand: (id: string, name: string) => userService.editBrand(id, name)
};

export default connect(null, mapDispatchToProps)(Brand);
