import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { ArrowRight, Box, Loader, ShoppingCart } from 'react-feather';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Upgrade from '../../../../components/common/upgrade';
import { ICompany } from '../../../../interfaces/company';
import { IUser } from '../../../../interfaces/user';
import { IApplicationState } from '../../../../redux/reducers';
import { IDashboardState } from '../../../../redux/reducers/dashboardReducer';
import dashboardService from '../../../../services/dashboardService';
import userService from '../../../../services/userService';
import company from '../../../account/company';
import activeProducts from '../../../products/components/activeProducts';
import { routes } from '../../../routes';

interface IProps extends RouteComponentProps {
    dashboard: IDashboardState;
    profile: IUser | undefined;
    fetchDashboardOverview: () => Promise<any>;
    getDashboardProductAnalytics: () => Promise<any>;
}

const ProductOverview: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        setLoading(true);
        Promise.all([props.fetchDashboardOverview(), props.getDashboardProductAnalytics()]).finally(() =>{
            setLoading(false);
        })
    }, []);
    
    const dummyAverageCount = () => {
        return Math.floor((props.dashboard?.active_products_count + props.dashboard?.concept_products_count) * .25);
    }

    return (
        <Card className='product-overview-card'>
            <CardBody>
                <h4>Product Overview</h4>
                {props.profile?.company?.subscription_type == 'supplier' ? 
                    <Upgrade /> :
                    <>
                        {loading  ? 
                            <Loader className='fa-spin'/> :
                            <div className='chart-wrapper'>
                                <Pie
                                    data={{
                                        labels: ['Active Products', 'Concept Products'],
                                        datasets: [{
                                            data: [props.dashboard.active_products_count, props.dashboard.concept_products_count],
                                            backgroundColor: [
                                                '#0ed7ac',
                                                '#7593ff',
                                            ],
                                        }]
                                    }}
                                    legend= {{
                                        display: false,
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </div>
                        }
                        <div className='comparison-card'>
                            <div className='comparison'>
                                <div className='count'>
                                    <span>
                                        <span className='icon-active'><ShoppingCart size={18} /></span>
                                        {loading ? <Loader className='fa-spin' size={16} /> : props.dashboard?.active_products_count}
                                    </span>
                                    <span>
                                        <span className='icon-concept'><Box size={18} /></span>
                                        {loading ? <Loader className='fa-spin' size={16} /> : props.dashboard?.concept_products_count}
                                    </span>
                                </div>
                                <div className='stats company'>
                                    <h5>{props.profile?.company?.name}</h5>
                                    <div>
                                        <p>This Month <br/>
                                            { loading ? 
                                                <Loader className='fa-spin' size={16} /> :
                                                (props.dashboard?.active_products_count + props.dashboard?.concept_products_count)
                                            }
                                        </p>
                                        <p>Last Month 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>  
                }
                <div className='view' onClick={() => props.history.push(routes.ANALYSIS)}>
                    Analytics <ArrowRight size={16} />
                </div>
            </CardBody>
        </Card>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    dashboard: state.dashboard,
    profile: state.user.profile,
})
  
const mapDispatchToProps = {
    fetchDashboardOverview: () => dashboardService.getDashboardOverview(),
    getDashboardProductAnalytics: () => dashboardService.getDashboardProductAnalytics(),
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductOverview))