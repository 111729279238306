import { Api } from "./api";
import { Dispatch } from "redux";
import config from "../utils/config";
import { info } from "../utils/notification";
import {
	getAllInvoicesAction,
	getCompanyInvoicesAction,
	getInvoiceAction
} from "../redux/actions/billing/billing";
import { AxiosResponse } from "axios";
import { REQUEST_FILE_TYPES } from "../components/checkout/uploadRequestModal";

export enum PaymentType {
	NOW = "now",
	LATER = "later"
}

class PaymentService extends Api {
	makeUploadRequestPayment(pay: PaymentType, csvId: string, paymentMethodID?: string) {
		return async () => {
			let response: AxiosResponse;
			try {
				switch (pay) {
					case PaymentType.NOW:
						response = await this.http.post(config.api.endpoints.stripe.uploadRequestPayment(pay), {
							upload_request_id: csvId,
							payment_method_id: paymentMethodID
						});
						if (response?.data?.status === "requires_action") {
							return response.data;
						} else {
							info(`CSV Request Payment Successful`);
							return response;
						}
					case PaymentType.LATER:
						response = await this.http.post(config.api.endpoints.stripe.uploadRequestPayment(pay), {
							upload_request_id: csvId
						});
						return response;
				}
			} catch (error) {
				this.handleError(error, "CSV Request Payment Error");
				return {
					status: 500,
					message: "Card Error"
				};
			}
		};
	}

	makeSubscriptionPayment(tier: string, paymentMethodID: string) {
		return async () => {
			try {
				const body = {
					payment_method_id: paymentMethodID
				};
				const response = await this.http.post(
					config.api.endpoints.stripe.subscriptionPayment(tier),
					body
				);
				if (response?.data?.status === "requires_action") {
					return response.data;
				} else {
					info(`Payment for ${tier} Subscription Successful`);
					return response;
				}
			} catch (error) {
				this.handleError(error, "Subscription Payment Error");
				return {
					status: 500,
					message: "Card Error"
				};
			}
		};
	}

	payInvoice(id: string, paymentMethodID: string) {
		return async () => {
			try {
				const body = {
					payment_method_id: paymentMethodID
				};
				const response = await this.http.put(config.api.endpoints.stripe.payInvoice(id), body);
				if (response?.data?.status === "requires_action") {
					return response.data;
				} else {
					info(`Invoice Payment Successful`);
					return response;
				}
			} catch (error) {
				this.handleError(error, "Error Paying Invoice");
				return {
					status: 500,
					message: "Card Error"
				};
			}
		};
	}

	confirmPayment(id: string) {
		return async () => {
			try {
				const response = await this.http.put(
					config.api.endpoints.stripe.confirmAuthorizedPayment(id)
				);
				info("Payment Successful");
				return response;
			} catch (error) {
				this.handleError(error, "Payment Error");
				return error;
			}
		};
	}

	confirmSubscribe(id: string, tier: string) {
		return async () => {
			try {
				const response = await this.http.put(
					config.api.endpoints.stripe.confirmSubscriptionPayment(id, tier)
				);
				info("Subscription Payment Successful");
				return response;
			} catch (error) {
				this.handleError(error, "Subscription Payment Error");
				return error;
			}
		};
	}

	getAllInvoices() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.stripe.allInvoices);
				dispatch(getAllInvoicesAction(response.data));
			} catch (error) {
				this.handleError(error, "Error Retrieving Invoices");
			}
		};
	}

	getCompanyInvoices() {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.stripe.companyInvoices);
				dispatch(getCompanyInvoicesAction(response.data));
			} catch (error) {
				this.handleError(error, "Error Retrieving Invoices");
			}
		};
	}

	getInvoiceById(id: string) {
		return async (dispatch: Dispatch<any>) => {
			try {
				const response = await this.http.get(config.api.endpoints.stripe.getInvoice(id));
				dispatch(getInvoiceAction(response.data));
			} catch (error) {
				this.handleError(error, "Error Retrieving Invoice");
			}
		};
	}
}

export default new PaymentService();
