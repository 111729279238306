import React from "react";
import { ImageSkeleton } from "../../../../utils/ghostUI";

interface IProps {
	numItems: number;
}

const RecentlyViewedLoader: React.FC<IProps> = ({ numItems, ...props }) => {
	const generateGhostUI = (numItems: number) => {
		let elements = [];
		for (let i = 0; i < numItems; i++) {
			elements.push(
				<div key={i} className="recent-card recent-ghost">
					{<ImageSkeleton className={"recent-img-ghost"} />}
					<h5 className="ghost" style={{ textAlign: "center" }}></h5>
				</div>
			);
		}
		return elements;
	};

	return <>{generateGhostUI(numItems)}</>;
};

export default RecentlyViewedLoader;
