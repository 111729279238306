import React, { useState, useEffect } from "react";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import Button from "../../../components/common/button";
import { Row, Col, Progress, Table } from "reactstrap";
import {
	Star,
	Users,
	Loader as LoaderIcon,
	CheckCircle,
	Award,
	PieChart,
	FileText,
	X,
	Share2,
	ChevronUp,
	ChevronDown,
	Edit2,
	AlertTriangle
} from "react-feather";
import ingredientService from "../../../services/ingredientService";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { routes } from "../../routes";
import notification from "../../../utils/notification";
import config from "../../../utils/config";
import { sanitizeNutrient } from "../../../utils/string";
import Slider from "../../../components/slider";
import { IPersona } from "../../../interfaces/persona";
import PersonaItem from "../../personas/components/persona.item";
import { getIngredientImagePlaceholder, IngredientNutrientsArray } from "../../../utils/ingredient";
import { BarColor } from "../../../components/suitabilityBar";
import { ICountryData, IIngredient, ISimiliarIngredient } from "../../../interfaces/ingredient";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../../components/loader";
import { USER_ROLES } from "../../../interfaces/roles";

import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/scss/react-flags-select.scss";

import costScaleIcon from "../../../assets/images/cost-scale-icon.svg";
import suitabilityScoreIcon from "../../../assets/images/suitability-score-icon.svg";
import sustainabilityScaleIcon from "../../../assets/images/sustainability-scale-icon.svg";
import IngredientInfoModal from "../components/ingredientInfoModal";
import Select from "react-select";

// Grocer Images
import whole_foods from "../../../assets/images/grocers/whole-foods.png";
import walmart from "../../../assets/images/grocers/walmart.png";
import costco from "../../../assets/images/grocers/costco.png";
import amazon from "../../../assets/images/grocers/amazon.png";

//Overide react select for grocer selection
import { components } from "react-select";
import Upgrade from "../../../components/common/upgrade";
import { similarSugars } from "../exampleData/similarIngredientsSample";
import ProgressGradient from "../../../components/common/progressGradient";
import ReactImageFallback from "react-image-fallback";
import SideForm from "../../../components/sideForm";
import EditIngredientForm from "./form/editForm";
import { IUser } from "../../../interfaces/user";
import { renderCostScore } from "../../../utils/icons";

const { Option } = components;
const IconOption = (props: any) => (
	<Option {...props}>
		<div>
			<img height={16} width={16} style={{ marginRight: 5 }} src={props.data.img} />
			{props.data.label}
		</div>
		{/* {suitabilityScoreIcon} */}
	</Option>
);
const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<div className="grocer-dropdown"></div>
		</components.DropdownIndicator>
	);
};

const SUSTAINABILITY_MAX = parseInt(((5000 + 60) / 2).toFixed(0));

interface IProps extends RouteComponentProps<{ id: string }> {
	isStarred: boolean;
	selected: IIngredient | null;
	selectedStatus: any;
	profile: IUser | undefined;
	starIngredient: (id: string, isStarred: boolean) => Promise<any>;
	getIngredientById: (id: string) => Promise<any>;
	getIngredientStatus: (id: string) => Promise<any>;
	getIngredientPersona: (id: string) => Promise<any>;
	getSimiliarIngredients: (id: string) => Promise<any>;
	personas: IPersona[];
	similiarIngredients: ISimiliarIngredient[];
	removeIngredient: (id: string) => Promise<any>;
	addIngredientToPortfolio: (id: string) => Promise<any>;
	addRecentlyViewed: (id: string) => Promise<any>;
	getRecommendedIngredients: (id: string) => Promise<any>;
	history: History;
	role: any;
}

const IngredientDetail: React.FC<IProps> = ({
	isStarred,
	profile,
	starIngredient,
	getIngredientById,
	getIngredientStatus,
	getIngredientPersona,
	getSimiliarIngredients,
	removeIngredient,
	addIngredientToPortfolio,
	addRecentlyViewed,
	selected,
	selectedStatus,
	personas,
	similiarIngredients,
	history,
	role,
	match: {
		params: { id }
	}
}) => {
	const [starredLoading, setStarredLoading] = useState<boolean>(false);
	const [isPersonaLoading, setPersonaLoading] = useState<boolean>(false);
	const [isSimiliarIngredientsLoading, setSimiliarIngredientsLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [removing, setRemoving] = useState<boolean>(false);
	const [statusLoading, setStatusLoading] = useState<boolean>(false);
	const [adding, setAdding] = useState<boolean>(false);
	const [infoModal, setInfoModal] = useState<boolean>(false);
	const [infoModalType, setInfoModalType] = useState<any>("regulation");
	const [regulationVisible, setRegulationVisible] = useState<boolean>(false);
	const [showSustainability, setShowSustainabiilty] = useState<boolean>();
	const [editOpen, setEditOpen] = useState<boolean>(false);
	const [isUserIngredient, setUserIngredient] = useState<boolean>();
	const [country, setCountry] = useState<string>("");
	const [claim, setClaim] = useState<any>();
	const [datasheet, setDatasheet] = useState<any>();
	const [regulationLinks, setRegulationLinks] = useState<any>();

	async function handleStarIngredient() {
		setStarredLoading(true);
		try {
			await starIngredient(id, !isStarred);
			notification.ingredient(
				`Ingredient ${isStarred ? "removed from" : "added as"} starred ingredients`
			);
		} finally {
			setStarredLoading(false);
		}
	}

	const toggleEditForm = () => {
		setEditOpen(!editOpen);
	};

	function onRemoveHandler() {
		setRemoving(true);

		confirmAlert({
			title: "Remove Ingredients",
			message: "Are you sure you want to remove this ingredient?",
			buttons: [
				{
					label: "Remove",
					onClick: () => {
						removeIngredient(id).finally(() => {
							setRemoving(false);
							history.push(`${routes.INGREDIENTS}`);
						});
					}
				},
				{
					label: "Cancel",
					onClick: () => {}
				}
			]
		});
	}

	function onAddIngredientToPortfolioHandler() {
		setAdding(true);
		addIngredientToPortfolio(id).finally(() => {
			setAdding(false);
			setStatusLoading(true);
			getIngredientStatus(id).finally(() => {
				setStatusLoading(false);
			});
		});
	}

	useEffect(() => {
		if (!id) return;

		setLoading(true);
		getIngredientById(id).finally(() => {
			setLoading(false);
		});

		setStatusLoading(true);
		getIngredientStatus(id).finally(() => {
			setStatusLoading(false);
		});

		setPersonaLoading(true);
		getIngredientPersona(id).finally(() => {
			setPersonaLoading(false);
		});

		setSimiliarIngredientsLoading(true);
		getSimiliarIngredients(id).finally(() => {
			setSimiliarIngredientsLoading(false);
		});

		addRecentlyViewed(id);
	}, [id]);

	useEffect(() => {
		if (selected) {
			setCountry(selected?.country_data?.map((c: any) => c.country.alpha_2)[0]);
			setClaim(
				country
					? selected.country_data?.filter((c: any) => c.country.alpha_2 === country)[0].claim
					: undefined
			);
		}
	}, [selected]);

	// Check if selected ingredient was uploaded by current user
	useEffect(() => {
		setUserIngredient(profile?.company?.id == selected?.company?.id);
	}, [selected, profile]);

	const handleSelectCountry = (countryCode: string) => {
		setCountry(countryCode);
	};

	const handleDocumentRequest = (type: string) => {
		if (country && country == "CA") {
			// Canadian regulation documents are accessibly formatted so they are displayed in an info modal
			setInfoModalType(type);
			setInfoModal(true);
		} else if (country && country == "US") {
			// United States regulation documents are only available through links so those links are displayed in a smaller popup
			// Make sure there is country data and regulation info
			const countryData = selected?.country_data?.filter((c: any) => {
				return c.country.alpha_2 == country && c.regulations;
			});
			if (countryData && !regulationVisible && countryData.length > 0) {
				setRegulationLinks(countryData[0].regulations);
				setRegulationVisible(true);
			} else {
				setRegulationVisible(false);
			}
		}
	};

	const hasRegulationData = () => {
		if (country && country == "US") {
			// Make sure there is country data and regulation info
			const countryData = selected?.country_data?.filter((c: any) => {
				return (
					c.country.alpha_2 == country &&
					c.regulations.find((r: any) => r.link != undefined && r.link != "")
				);
			});
			return countryData && countryData.length > 0;
		}
		return true;
	};

	const getSelectedCountryDataSheet = () => {
		if (country) {
			// Make sure there is country data and regulation info
			const countryDataSheet = selected?.country_data?.find((c: any) => {
				return c.country.alpha_2 == country && c.datasheet;
			})?.datasheet;

			return countryDataSheet;
		}
		return;
	};

	const getSelectedCountryClaim = () => {
		if (country) {
			// Make sure there is country data and regulation info
			const countryClaim = selected?.country_data?.find((c: any) => {
				return c.country.alpha_2 == country && c.claim;
			})?.claim;

			return countryClaim;
		}
		return;
	};

	const formatSustainabilityScore = () => {
		const sustainabilityScore = selected?.sustainability_score
			? selected.sustainability_score
			: selected?.greenhouse_gas_emissions && selected?.water_footprint
			? (parseFloat(selected?.greenhouse_gas_emissions) + parseFloat(selected?.water_footprint)) / 2
			: 0;
		if (sustainabilityScore > SUSTAINABILITY_MAX || sustainabilityScore == 0) return 0;
		const percentOfMax = ((selected?.sustainability_score || 0) / SUSTAINABILITY_MAX) * 100;
		const sustainabilityScorePercent = 100 - percentOfMax;
		return parseFloat(sustainabilityScorePercent.toFixed(2));
	};

	const mapCountryDataToAllergens = () => {
		if (!selected) return;
		return selected?.country_data
			?.filter((data: ICountryData) => data.allergens != null && data.country.alpha_2 == country)
			?.map((data: ICountryData) => data.allergens)
			.flat(1)
			.filter((data) => data?.name != "");
	};

	const mapCountryDataToCertifications = () => {
		if (!selected) return;
		return selected?.country_data
			?.filter(
				(data: ICountryData) => data.certification != null && data.country.alpha_2 == country
			)
			?.map((data: ICountryData) => data.certification)
			.flat(1)
			.filter((data) => data?.name != "" && data?.link != "");
	};

	return (
		<Loader isLoading={loading}>
			{selected && (
				<div className="ingredient-single">
					<section className="ingredient-single__details">
						<Row>
							<Col md="7" className="pt-2">
								<div className="ingredient-single__name">
									<div className="d-flex" style={{ alignItems: "center" }}>
										<h1 className="mr-3 mb-0 font-weight-semibold">{selected.jf_display_name}</h1>
										<div style={{ marginTop: "auto", marginBottom: 5 }}>
											<h4
												className={"font-weight-normal"}
												style={{ marginBottom: 0, color: "#657280" }}
											>
												<span> Last Updated: </span>
												<span>
													{new Date(selected.updated_at || selected.created_date).toDateString()}
												</span>
											</h4>
										</div>
										{selected.is_verified && (
											<div className="color-bg-green ml-3 d-flex mt-auto mb-2">
												<CheckCircle size={22} className="mr-2" />
												<span style={{ fontSize: "14px", fontWeight: "bold" }}>Verified</span>
											</div>
										)}
									</div>
									<h6 className="font-weight-normal mt-2 color-battleship-grey">
										Manufactured By: {selected?.manufacturer?.name}
									</h6>
								</div>
							</Col>
							<Col
								md="5"
								className="pt-2"
								style={{ justifyContent: "flex-end", alignItems: "center" }}
							>
								<div className="d-flex" style={{ justifyContent: "flex-end", marginBottom: 6 }}>
									{profile?.company?.subscription_type == "supplier" && !isUserIngredient ? (
										<></>
									) : (
										<>
											<Select
												className={"mr-2 grocer-select"}
												classNamePrefix={"grocer-select"}
												placeholder={"Retailer..."}
												isClearable={true}
												options={[
													{ value: "Whole Foods", label: "Whole Foods", img: whole_foods },
													{ value: "Walmart", label: "Walmart", img: walmart },
													{ value: "Costco", label: "Costco", img: costco },
													{ value: "Amazon", label: "Amazon", img: amazon }
												]}
												components={{ DropdownIndicator, Option: IconOption }}
											/>
										</>
									)}
								</div>
								<div
									className="d-flex mt-2"
									style={{ justifyContent: "flex-end", marginRight: -5 }}
								>
									{/* <Integrations integrations={profile?.company.integrations} /> */}
									<div
										className={"integration-item"}
										style={{ justifyContent: "flex-end", marginRight: "0 !important" }}
									>
										<Share2 className="mr-4" />
										{
											(profile?.company?.integrations || []).map((i: any, index: any) => {
												return (
													<img
														className={"inactive"}
														key={index}
														src={i.thumbnail}
														alt={i.name}
														height={25}
														style={{ marginRight: 7 }}
													/>
												);
											})[0]
										}
									</div>
								</div>
							</Col>
						</Row>
						<Row style={{ justifyContent: "space-between" }}>
							<Col md="2">
								<Row className={"ingredient-single__image__container mb-2"}>
									<ReactImageFallback
										src={config.api.endpoints.ingredients.ingredientImage(selected.jf_display_name)}
										fallbackImage={getIngredientImagePlaceholder()}
										alt="Ingredient Image"
										className="ingredient-single__image"
									/>
								</Row>

								<Row>
									<div
										className="d-flex mt-2"
										style={{ justifyContent: "flex-end", marginRight: 6 }}
									>
										{role === USER_ROLES.COMPANY_USER ||
											(role === USER_ROLES.COMPANY_ADMIN && (
												<>
													{selectedStatus ? (
														<Button
															loading={statusLoading}
															className="btn-danger color-white font-weight-semibod mr-2"
															style={{ height: 40, padding: 8, borderRadius: 5 }}
															onClick={onRemoveHandler}
														>
															Remove from Portfolio
														</Button>
													) : (
														<Button
															loading={statusLoading}
															className="color-white font-weight-semibod mr-2"
															onClick={onAddIngredientToPortfolioHandler}
														>
															Add to Portfolio
														</Button>
													)}
													<div
														className=""
														style={{ cursor: "pointer", height: "40px", marginRight: "5px" }}
														onClick={handleStarIngredient}
													>
														{starredLoading ? (
															<LoaderIcon className="fa-spin" size={26} />
														) : isStarred ? (
															<div
																className="d-flex"
																style={{
																	color: "white",
																	border: "1px solid #f4a850",
																	backgroundColor: "#f4a850",
																	alignItems: "center",
																	borderRadius: 5,
																	padding: 8
																}}
															>
																<Star className="star" size={22} fill="#f4a850" color="white" />
																&nbsp;
																<span>Starred</span>
															</div>
														) : (
															<div
																className="d-flex"
																style={{
																	color: "#f4a850",
																	border: "1px solid #f4a850",
																	alignItems: "center",
																	borderRadius: 5,
																	padding: 8
																}}
															>
																<Star className="star" size={22} fill="white" color="#f4a850" />
																&nbsp;
																<span>Star</span>
															</div>
														)}
													</div>
												</>
											))}
									</div>
								</Row>
							</Col>
							<Col md={`${isUserIngredient ? "6" : "9"}`}>
								<Row className={`justify-content-end border-radius-6 p-4 custom-shadow mt-3`}>
									{!isUserIngredient && (
										<Col md="4">
											<div className="d-flex">
												{/* change icon/bar to purple  */}
												<img src={suitabilityScoreIcon} style={{ stroke: "#7c4aa9" }} />
												<div className="ml-3" style={{ flex: 1 }}>
													<h5 className="mb-3">Preference Score:</h5>
													{profile?.company?.subscription_type == "supplier" ? (
														<div
															className={"d-flex justify-content-start"}
															style={{ width: "max-content", marginTop: -10 }}
														>
															<Upgrade />
														</div>
													) : (
														<div
															data-tooltip={
																selected.suitability_score != 0 &&
																selected.suitability_score != undefined
																	? selected.suitability_score
																	: 70
															}
														>
															<ProgressGradient
																color="#7c4aa9"
																value={selected.suitability_score || 70}
															/>
														</div>
													)}
												</div>
											</div>
										</Col>
									)}
									<Col md={`${isUserIngredient ? "7" : "4"}`}>
										<div className="d-flex">
											<img src={sustainabilityScaleIcon} />
											<div className="ml-3" style={{ flex: 1, position: "relative" }}>
												<h5 className="mb-3">Sustainability Score:</h5>
												<ProgressGradient color="#0ed7ac" value={formatSustainabilityScore()} />
												{showSustainability && (
													<div
														style={{
															position: "absolute",
															padding: 20,
															backgroundColor: "#fff",
															boxShadow: "1px 5px 10px rgba(0, 0, 0, 0.08)",
															borderRadius: 6,
															zIndex: 10
														}}
													>
														<span>GHG Emissions (kg CO2e/kg)</span>
														<div className={"mt-1 mb-1"}>
															<ProgressGradient
																color="#0ed7ac"
																actualValue={
																	selected?.greenhouse_gas_emissions
																		? parseFloat(selected?.greenhouse_gas_emissions?.split(" ")[0])
																		: 0
																}
																value={
																	selected?.greenhouse_gas_emissions
																		? (parseFloat(
																				selected.greenhouse_gas_emissions?.split(" ")[0]
																		  ) /
																				60) *
																		  100
																		: 0
																}
																height={10}
																decimalPoints={2}
															/>
														</div>
														<span className={"mt-1 mb-1"}>Water Usage (gal/kg)</span>
														<div className={"mt-1 mb-1"}>
															{/* 5000 gal/kg is 1000 gal/kg more than beef, which tends to top emissions charts */}
															<ProgressGradient
																color="#0ed7ac"
																actualValue={
																	selected?.water_footprint
																		? parseFloat(selected?.water_footprint?.split(" ")[0])
																		: 0
																}
																value={
																	selected?.water_footprint
																		? (parseFloat(selected?.water_footprint?.split(" ")[0]) /
																				5000) *
																		  100
																		: 0
																}
																height={10}
																decimalPoints={2}
															/>
														</div>
													</div>
												)}
											</div>
											<div
												className={"ml-2 mr-2"}
												style={{ marginTop: "auto", marginBottom: 0, position: "relative" }}
												onClick={() => setShowSustainabiilty(!showSustainability)}
											>
												{showSustainability ? (
													<ChevronUp style={{ position: "absolute", bottom: -5 }} />
												) : (
													<ChevronDown style={{ position: "absolute", bottom: -5 }} />
												)}
											</div>
										</div>
									</Col>
									<Col md={`${isUserIngredient ? "5" : "4"}`}>
										<div className="d-flex">
											<img src={costScaleIcon} />
											<div className="ml-3" style={{ flex: 1 }}>
												<h5 className="mb-3">Cost Score:</h5>
												<div className="d-flex">{renderCostScore(selected.cost)}</div>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</section>

					{/* minerals proportion */}
					<div className="row">
						<ul className="col-md-6 list-style-none d-flex flex-wrap">
							{!loading &&
								selected &&
								IngredientNutrientsArray.map((item) => {
									const value = selected[item] ? Number(selected[item]) : 0;
									const color: BarColor =
										value >= 50 ? "success" : value >= 25 ? "warning" : "danger";
									return (
										<li className="col-md-3 mt-4" key={item}>
											<div className="mt-2">
												<h5 className="font-weight-normal">{sanitizeNutrient(item)}</h5>
												<Progress color={color} value={value} />
											</div>
										</li>
									);
								})}
						</ul>
						<div className="col-md-6">
							<div className={"d-flex align-items-center"}>
								<strong className="font-18 text-nowrap">Ingredient info:</strong>
								<div
									className="d-flex flex-nowrap justify-content-end"
									style={{ marginBottom: 6, width: "100%" }}
								>
									{profile?.company?.subscription_type == "supplier" && !isUserIngredient ? (
										<></>
									) : (
										<>
											<ReactFlagsSelect
												defaultCountry={
													selected?.country_data?.map((c: any) => c.country.alpha_2)[0]
												}
												countries={selected?.country_data?.map((c: any) => c.country.alpha_2)}
												customLabels={selected?.country_data
													?.map((c: any) => c.country.alpha_2)
													.reduce((ac: any, a: any) => ({ ...ac, [a]: a }), {})}
												showSelectedLabel={true}
												onSelect={handleSelectCountry}
											/>
										</>
									)}
								</div>
							</div>
							<Table
								responsive
								hover
								borderless
								className="color-battleship-grey"
								style={{ overflowX: "visible" }}
							>
								<tbody style={{ overflowY: "visible" }}>
									<tr className="ingredient-info-row">
										<td>Document</td>
										<td>
											{profile?.company?.subscription_type == "supplier" && !isUserIngredient ? (
												<></>
											) : (
												<>
													<a
														{...(hasRegulationData()
															? { onClick: () => handleDocumentRequest("regulation") }
															: { "data-tooltip": "Regulation Unavailable for Selected Market" })}
														target="_blank"
														className="ingredient-info-pill"
														style={{ color: "#8fa1b3", cursor: "pointer" }}
													>
														<Award className="mr-2" size={18} />
														<span>Reg</span>
													</a>
													{regulationLinks && regulationVisible ? (
														<div className="regulation-links">
															<p className="regulation-link-header">
																Regulations{" "}
																<X
																	onClick={() => setRegulationVisible(false)}
																	size={20}
																	style={{ float: "right", paddingTop: 3 }}
																/>
															</p>
															{regulationLinks.map((regulation: any) => {
																return (
																	<p>
																		{regulation.used_for.charAt(0).toUpperCase() +
																			regulation.used_for.slice(1)}{" "}
																		<br />
																		<a href={regulation.link}>Source</a>
																	</p>
																);
															})}
														</div>
													) : (
														<></>
													)}
												</>
											)}
											<a
												{...(getSelectedCountryDataSheet()
													? { href: getSelectedCountryDataSheet(), target: "_blank" }
													: { "data-tooltip": "Datasheet Unavailable" })}
												className="ingredient-info-pill"
												style={{ color: "#8fa1b3" }}
											>
												<PieChart className="mr-2" size={18} />
												<span>Datasheet</span>
											</a>
											<a
												{...(getSelectedCountryClaim()
													? { href: getSelectedCountryClaim() }
													: { "data-tooltip": "Claim Unavailable" })}
												target={getSelectedCountryClaim() ? "_blank" : ""}
												className={`ingredient-info-pill`}
												style={{
													color: "#8fa1b3",
													cursor: getSelectedCountryClaim() ? "pointer" : "default"
												}}
											>
												<FileText className="mr-2" size={18} />
												<span>Claim</span>
											</a>
										</td>
									</tr>
									<tr className="ingredient-info-row">
										<td>Allergen Statements</td>
										<td>
											{mapCountryDataToAllergens()?.map(
												(data: { link: string; name: string; country: string }, index: number) => (
													<a href={data.link} target={"_blank"}>
														<div key={index} className="ingredient-info-pill">
															<AlertTriangle className="mr-2 text-danger" size={18} />
															<span>{data.name}</span>
														</div>
													</a>
												)
											)}
											{/* <div className="ingredient-info-pill">
												<LoaderIcon className="mr-2 fa-spin" size={18} />
												<span>Data Processing</span>
											</div> */}
										</td>
									</tr>
									<tr className="ingredient-info-row">
										<td>Certification</td>
										<td>
											{mapCountryDataToCertifications()?.map(
												(data: { link: string; name: string; country: string }, index: number) => (
													<a href={data.link} target={"_blank"}>
														<div key={index} className="ingredient-info-pill">
															<CheckCircle className="mr-2 text-warning" size={18} />
															<span>{data.name}</span>
														</div>
													</a>
												)
											)}
											{/* <div className="ingredient-info-pill">
												<LoaderIcon className="mr-2 fa-spin" size={18} />
												<span>Data Processing</span>
											</div> */}
										</td>
									</tr>
									<tr className="ingredient-info-row" style={{ borderBottom: "none" }}>
										<td>Shelf life</td>
										<td style={{ display: "flex", justifyContent: "flex-end" }}>
											{selected.shelf_life || ""} {/*3 months (90days)*/}
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</div>
					{!isUserIngredient ? (
						<>
							{/* Similar Ingredients */}
							<Row className="mt-5">
								<Col md="12">
									<Slider
										items={
											selected.jf_display_name === "Sugar" ? similarSugars : similiarIngredients
										}
										render={(item: ISimiliarIngredient) => (
											<>
												<div
													key={item.ingredient.id}
													className="p-4 border-radius-6 custom-shadow d-flex"
													onClick={() => history.push(`/app/ingredients/${item.ingredient.id}`)}
													style={{ cursor: "pointer", minWidth: "100%", marginLeft: "20px" }}
												>
													<div
														style={{
															position: "absolute",
															backgroundColor: "#51C5AC",
															left: 0,
															top: 30,
															color: "white",
															borderRadius: 5,
															fontSize: "0.8rem",
															padding: "3px 5px"
														}}
													>
														$ $ $
													</div>
													<Col md="3" className="d-flex align-items-center justify-content-center">
														<ReactImageFallback
															src={config.api.endpoints.ingredients.ingredientImage(
																item.ingredient.jf_display_name
															)}
															fallbackImage={getIngredientImagePlaceholder()}
															alt="Ingredient Image"
															height="60px"
															width="60px"
														/>
													</Col>
													<Col className="pr-0">
														<h5 className="font-weight-semibold">
															{item.ingredient.jf_display_name}
														</h5>
														<div className="d-flex justify-content-between">
															<div>
																<span className="font-11 color-battleship-grey">Popularity</span>
																<div className="mt-1">
																	<Users className="mr-2 color-bg-slate" size={17} />
																	<span className="h6 color-bg-blue">
																		{(Number(item.popularity) * 100).toFixed(1)}
																		{/* {Number(item.popularity).toFixed(1)} */}
																	</span>
																</div>
															</div>
															<div>
																<span className="font-11 color-battleship-grey">Similarity</span>
																<div className="mt-1">
																	<i className="mr-2 icon-nav-ingredients color-bg-slate" />
																	<span className="h6 color-bg-green">
																		{Number(item.similarity).toFixed(1)}
																	</span>
																</div>
															</div>
														</div>
													</Col>
												</div>
											</>
										)}
										title={() => <h4>Similar Ingredients</h4>}
										itemPerPage={4}
										access={profile?.company?.subscription_type}
									/>
								</Col>
							</Row>

							{/* Similar Ingredients */}
							<Row className="mt-5">
								<Col md="12">
									<Slider
										items={personas}
										title={() => (
											<h5>
												Matched Personas {isPersonaLoading && <LoaderIcon className="fa-spin" />}
											</h5>
										)}
										render={(persona: IPersona) => <PersonaItem mini persona={persona} />}
										itemPerPage={4}
										access={profile?.company?.subscription_type}
									/>
								</Col>
							</Row>
						</>
					) : (
						<></>
					)}
				</div>
			)}

			{isUserIngredient ? (
				<>
					<span className="action-button-group">
						<Button className="btn-circle btn-search btn-secondary" onClick={toggleEditForm}>
							<Edit2 size={28} />
						</Button>
					</span>

					<SideForm isOpen={editOpen} toggleForm={toggleEditForm} title="Edit Ingredient">
						<EditIngredientForm toggleForm={toggleEditForm} isOpen={editOpen} />
					</SideForm>
				</>
			) : (
				<></>
			)}

			<IngredientInfoModal
				isOpen={infoModal}
				type={infoModalType}
				country={country}
				toggleModal={setInfoModal}
			/>
		</Loader>
	);
};

const mapStateToProps = (state: IApplicationState, props: any) => {
	const id = props.match.params.id;
	return {
		role: state.user.role,
		profile: state.user.profile,
		selected: state.ingredient.selected,
		selectedStatus: state.ingredient.selectedStatus,
		isStarred: state.ingredient.starred.find((item) => item.id === id) !== undefined,
		personas: state.ingredient.selectedPersonas,
		similiarIngredients: state.ingredient.selectedSimiliarIngredients
	};
};

const mapDispatchToProps = {
	starIngredient: (id: string, isStarred: boolean) =>
		ingredientService.starIngredient(id, isStarred),
	getIngredientById: (id: string) => ingredientService.getIngredientById(id),
	getIngredientStatus: (id: string) => ingredientService.getIngredientStatus(id),
	getIngredientPersona: (id: string) => ingredientService.getIngredientPersona(id),
	getSimiliarIngredients: (id: string) => ingredientService.getSimliarIngredientsById(id),
	removeIngredient: (id: string) => ingredientService.removeIngredient(id),
	addIngredientToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	addRecentlyViewed: (id: string) => ingredientService.addRecentlyViewed(id)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IngredientDetail));
