import React, { useEffect, useRef, useState } from "react";
import { Loader, Plus, Star } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import { IApplicationState } from "../../../redux/reducers";
import ingredientService from "../../../services/ingredientService";
import searchService from "../../../services/searchService";
import config from "../../../utils/config";
import { SearchTableRowsSkeleton, SearchTableSkeleton } from "../../../utils/ghostUI";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { ImageSkeleton } from "../../../utils/ghostUI";
import { truncate } from "../../../utils/string";
import { routes } from "../../routes";
import SearchResultCard from "../components/searchResultCard";

interface IProps extends RouteComponentProps {
	searchResults: any[];
	addToPortfolio: (id: string) => Promise<any>;
	paginateSearchResults: (term: string) => Promise<any>;
	small?: boolean;
	display: string;
	searchLoading?: boolean;
	term?: string;
}

const SearchResultList: React.FC<IProps> = ({ searchResults, searchLoading, ...props }) => {
	const [addLoading, setAddLoading] = useState<{ [key: string]: boolean }>({});
	const [isNewSearch, setIsNewSearch] = useState<boolean>();
	const termRef = useRef<string>();

	/* 	Check the search term every time search state is in loading -
	 *	If the term is unchanged, but 'Load More' action has been executed,
	 *	search page should show additional ghost ui instead of temporarily
	 * 	replacing the entire list with a ghost ui table
	 */
	useEffect(() => {
		if (termRef.current == props.term) {
			setIsNewSearch(false);
			return;
		}
		termRef.current = props.term;
		setIsNewSearch(true);
	}, [searchLoading]);

	function add(id: string) {
		setTimeout(() => {
			setAddLoading({ ...addLoading, [id]: true });
			props.addToPortfolio(id).finally(() => {
				setAddLoading({ ...addLoading, [id]: false });
			});
		}, 0);
	}

	const handleIngredientNav = (id: string) => {
		// Clean up pagination before navigation
		props.paginateSearchResults("").finally(() => {
			props.history.push(routes.INGREDIENTS + "/" + id);
		});
	};

	return (
		<>
			{props.display == "grid" ? (
				searchResults.map((item: any) => {
					return <SearchResultCard key={item.id} result={item} />;
				})
			) : (
				<Table className={`search-result-list ${props.small ? "small-table" : ""}`}>
					<colgroup>
						<col className="i" />
						<col className="n" />
						<col className="rec" />
						{props.small ? <></> : <col className="m" />}
						<col className="cu" />
						<col className="co" />
						<col className="ad" />
					</colgroup>
					<thead>
						<tr>
							{!searchLoading && searchResults?.length > 0 ? (
								<>
									<th></th>
									<th>
										<span>Name</span>
									</th>
									<th></th>
									{props.small ? (
										<></>
									) : (
										<th>
											<span>Manufacturer</span>
										</th>
									)}
									<th>
										<span>Country</span>
									</th>
									<th>
										<span>Cost</span>
									</th>
									<th></th>
								</>
							) : (
								<>
									<th />
									<th />
									<th />
									<th />
									<th />
									<th />
									<th />
								</>
							)}
						</tr>
					</thead>

					{searchLoading && isNewSearch ? (
						<SearchTableSkeleton numRows={5} />
					) : (
						<tbody>
							{searchResults?.map((item: any, index: number) => {
								return (
									<tr
										key={index}
										className={index < 3 ? "recommend" : ""}
										onClick={() => handleIngredientNav(item.id)}
									>
										<td>
											<div className="left">
												<ReactImageFallback
													src={config.api.endpoints.ingredients.ingredientImage(
														item.jf_display_name
													)}
													initialImage={<ImageSkeleton className={"table-img-ghost"} />}
													fallbackImage={getIngredientImagePlaceholder()}
													alt="Ingredient Image"
													style={{ height: 40, width: 40, borderRadius: 6 }}
												/>
											</div>
										</td>
										<td
											className={"name"}
											data-tooltip={
												item.jf_display_name?.length > 12 ? item.jf_display_name : undefined
											}
										>
											{item.jf_display_name ? truncate(item.jf_display_name || "", 20) : ""}
										</td>
										<td className={index < 3 ? "top-pick" : ""}>
											{index < 3 ? (
												<>
													<span>
														<Star size={14} />
														Journey AI
													</span>
												</>
											) : (
												<> </>
											)}
										</td>
										{props.small ? (
											<></>
										) : (
											<td className={"manufacturer"}>
												{truncate(item.manufacturer?.name || "General", 15)}
											</td>
										)}
										<td>
											{
												<i
													className={`${
														item.country_data?.country?.alpha_2?.toLowerCase() || "us"
													} flag list-flag`}
												></i>
											}
										</td>
										<td>
											<span className="cost">$ $ $</span>
										</td>
										<td>
											<div className="right">
												<span
													className="add"
													onClick={(e) => {
														e.stopPropagation();
														add(item.id);
													}}
												>
													{addLoading[item.id] && addLoading[item.id] == true ? (
														<Loader className="fa-spin" size={18} color={"#fff"} />
													) : (
														<Plus size={18} color={"#3f65f1"} />
													)}
												</span>
											</div>
										</td>
									</tr>
								);
							})}
							{searchLoading && <SearchTableRowsSkeleton />}
						</tbody>
					)}
				</Table>
			)}
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	term: state.search.term,
	searchLoading: state.search.loading
});

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultList));
