import React, { useState } from "react";
import { Card, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import RegisterForm from "./form/registerForm";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { History } from "history";
import Button from "../../../components/common/button";

interface IProps {
    history: History;
}

const Register: React.FC<IProps> = (props) => {
    const [modal, setModal] = useState(false);


    function navigateToLogin() {
        setModal(false);
        props.history.push(routes.LOGIN)
    }

    return (<>
        <Card className="register">
            <RegisterForm setModal={setModal} />

            <div className="auth-action">
                <p>Already have an account?</p>
                <Link to={routes.LOGIN}>Click Here</Link>
            </div>

            <Modal className={'registration-success'} centered={true} isOpen={modal}>
                <ModalHeader>Registration successful !!</ModalHeader>
                <ModalBody>
                    <p>You have successfully registered for Journey Foods. <br />
                    Check your email and confirm your email address.</p>

                    <Button className="btn-awesome" onClick={navigateToLogin}>Awesome!</Button>
                </ModalBody>
            </Modal>
        </Card>
    </>
    )
}


export default Register;