import React, { useEffect, useState } from "react";
import { Loader, X } from "react-feather";
import { connect } from "react-redux";
import { Button, FormGroup, Input, InputGroup, Table } from "reactstrap";
import { Dispatch } from "redux";
import { IPackage } from "../../../../../interfaces/package";
import { IPaginateList } from "../../../../../interfaces/pagination";
import { IProductPackage } from "../../../../../interfaces/products";
import { search } from "../../../../../redux/actions/actionContants";
import { paginateAction } from "../../../../../redux/actions/pagination/pagination";
import { IApplicationState } from "../../../../../redux/reducers";
import { IUserState } from "../../../../../redux/reducers/userReducer";
import packagingService from "../../../../../services/packagingService";
import searchService from "../../../../../services/searchService";
import { useDebounce } from "../../../../../utils/hooks";
import PackageMini from "./packageMini";

interface IProps {
	packaging: IPaginateList<IPackage[]>;
	selected: IProductPackage | undefined;
	matchedPackages: IPaginateList<IPackage[]>;
	user: IUserState;
	searchPackaging: (term: string) => Promise<any>;
	getMatchedPackaging: (user_id: string) => Promise<any>;
	paginate: (page: number) => Promise<any>;
	setSelectedPackage: (item: IProductPackage) => void;
}

const PackageSearch: React.FC<IProps> = ({
	packaging: { list, pagination },
	matchedPackages,
	user,
	getMatchedPackaging,
	searchPackaging,
	paginate,
	setSelectedPackage,
	selected,
	...props
}) => {
	const [searchLoading, setSearchLoading] = useState<boolean>();
	const [matchedPackagingLoading, setMatchedPackagingLoading] = useState<boolean>();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searchResults, setSearchResults] = useState<IPackage[]>([]);

	useEffect(() => {
		setMatchedPackagingLoading(true);
		if (user.user_id)
			getMatchedPackaging(user.user_id).finally(() => setMatchedPackagingLoading(false));
	}, []);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		setSearchResults(searchResults.concat(list));
	}, [list]);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		search();
	}, [pagination.page]);

	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		search();
	}, [debouncedSearchTerm]);

	const search = () => {
		if (debouncedSearchTerm) {
			setSearchLoading(true);
			searchPackaging(debouncedSearchTerm).finally(() => {
				setSearchLoading(false);
			});
		} else {
			searchPackaging(""); // Reset search results on empty search term
			setSearchResults([]);
			paginate(1);
		}
	};

	const handleSearchChange = (e: any) => {
		const search = e.target.value;
		setSearchTerm(search);
	};

	const handlePackageSelect = (item: IPackage) => {
		setSelectedPackage({
			name: item.name,
			id: item.id,
			image_uri: item.image_uri || ""
		});
	};

	return (
		<div className="search-container">
			{}
			<FormGroup className="mb-0">
				<InputGroup className={"active"}>
					<Input
						value={searchTerm}
						onChange={handleSearchChange}
						type="search"
						name="search-text"
						id="search-text"
						className="border-0"
						placeholder="Search for packages..."
						autoComplete="off"
					/>
					{searchLoading ? (
						<Loader className="fa-spin mr-1" size={24} />
					) : (
						<X className="mr-1" size={24} onClick={() => setSearchTerm("")} />
					)}
				</InputGroup>
			</FormGroup>
			{searchTerm && searchResults.length > 0 && (
				<div className="search-results">
					{matchedPackages.list.slice(0, 3).map((item: IPackage, index: number) => {
						return (
							<PackageMini
								key={index}
								item={item}
								selected={item.id == selected?.id}
								matched
								handlePackageSelect={handlePackageSelect}
							/>
						);
					})}
					{searchResults.map((item: IPackage, index: number) => {
						return (
							<PackageMini
								key={index}
								item={item}
								selected={item.id == selected?.id}
								handlePackageSelect={handlePackageSelect}
							/>
						);
					})}
					{pagination.page * pagination.size < pagination.total && searchResults.length > 0 && (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								disabled={searchLoading}
								style={{ width: "max-content" }}
								onClick={() => paginate(pagination.page + 1)}
							>
								{searchLoading ? <Loader size={14} className="fa-spin" /> : "Load More"}
							</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	packaging: state.packaging.search.paginated_results,
	matchedPackages: state.packaging.packaging,
	user: state.user
});

const mapDispatchToProps = {
	getMatchedPackaging: (user_id: string) => packagingService.getAllPackages(user_id),
	searchPackaging: (term: string) => searchService.getPackagingSearchResults(term),
	paginate: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PACKAGING_PAGINATION, page));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageSearch);
