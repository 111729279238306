import React from "react";
import { withFormik, InjectedFormikProps } from "formik";
import { Form } from "reactstrap";
import { ForgotPasswordSchema } from "../../../../validations/forgotPassword";
import ForgotPasswordFormElements, { IForgotPasswordFormElements } from "./forgotPasswordFormFields";
import Button from "../../../../components/common/button";
import userService from "../../../../services/userService";
import { connect } from "react-redux";




interface IForgotPasswordProps {
    sendForgotPasswordMail: (email: string) => Promise<any>;
}



const ForgotPasswordForm: React.FC<InjectedFormikProps<IForgotPasswordProps, any>> = (props) => {


    return (

        <Form onSubmit={props.handleSubmit}>
            <ForgotPasswordFormElements {...props} />
            <Button loading={props.isSubmitting} disabled={props.isSubmitting} className="color-white"> Reset Password</Button>
        </Form>
    )
}


const WrappedForm = withFormik<IForgotPasswordProps, IForgotPasswordFormElements>({
    enableReinitialize: true,
    validationSchema: ForgotPasswordSchema,
    mapPropsToValues: () => {
        return {
            email: ""
        }
    },
    handleSubmit: async ({ email }, { props: { sendForgotPasswordMail } }) => {

        await sendForgotPasswordMail(email)

    }
});

const mapDispatchToProps = {
    sendForgotPasswordMail: (email: string) => userService.sendResetPasswordMail(email)
}

export default connect(null, mapDispatchToProps)(WrappedForm(ForgotPasswordForm));