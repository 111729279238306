import React from "react";
import { InjectedFormikProps } from "formik";
import TimePicker, {
    TimeOutput
} from "react-timekeeper";
import { FormGroup, Label } from "reactstrap";
import Input from "../../../../../components/form/Input";


export interface IEmailScheduleFormElementsValues {

    day: number;
    hour: number;
    minute: number;
}

export const WEEKDAYS = [
    {
        value: 0,
        label: "Sunday"
    }, {
        value: 1,
        label: "Monday"
    }, {
        value: 2,
        label: "Tuesday"
    }, {
        value: 3,
        label: "Wednesday"
    }, {
        value: 4,
        label: "Thursday"
    }, {
        value: 5,
        label: "Friday"
    }, {
        value: 6,
        label: "Saturday"
    },
]

const EmailScheduleFormElements: React.FC<InjectedFormikProps<{}, IEmailScheduleFormElementsValues>> = (props) => {


    const {
        values: { hour, minute, day },
        setFieldValue,
        errors,
        touched,
        handleChange,
        handleBlur
    } = props;


    function setTime(time: TimeOutput) {
        setFieldValue("hour", time.hour);
        setFieldValue("minute", time.minute);
    }
    const time = `${hour}:${minute < 10 ? `0${minute}` : minute}`
    return (
        <>


            <Input
                type="select"
                errors={errors}
                name="day"
                touched={touched}
                label="Day of week"
                handleChange={handleChange}
                handleOnBlur={handleBlur}
                value={day.toString()}
            >
                {
                    WEEKDAYS.map(item => (
                        <option value={item.value}>{item.label}</option>
                    ))
                }
            </Input>
            <hr />
            <FormGroup>
                <Label for="time">
                    Time
                </Label>
                <TimePicker
                    hour24Mode={true}
                    time={time}
                    onChange={setTime}
                />
            </FormGroup>
        </>
    )
}
export default EmailScheduleFormElements;