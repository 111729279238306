import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Row } from "reactstrap";
import { Star, Activity, Loader } from "react-feather";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import dashboardService from "../../../services/dashboardService";
import { IDashboardState } from "../../../redux/reducers/dashboardReducer";
import { IProductAnalyticsData } from "../../../interfaces/dashboard";
import AccountNotifications from "../../newDashboard/components/accountNotifications";
import Upgrade from "../../../components/common/upgrade";
import { DataSet } from "../../../components/charts/interfaces";
import LineChart from "../../../components/charts/lineChart";

interface IProps {
	getOverview: () => Promise<any>;
	getDashboardProductAnalytics: () => Promise<any>;
	dashboard: IDashboardState;
	profile: any;
}

const IngredientOverview: React.FC<IProps> = ({
	dashboard: {
		active_products_count,
		concept_products_count,
		star_ingredients_count,
		company_info,
		product_analytics
	},
	profile,
	getOverview,
	getDashboardProductAnalytics
}) => {
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		Promise.all([getOverview(), getDashboardProductAnalytics()]).finally(() => {
			setLoading(false);
		});
	}, []);

	const reduceProductData = (label: string, type: string) => {
		let dataset: DataSet = {
			label: label,
			data: [],
			fill: false,
			backgroundColor: "",
			borderColor: "#fff"
		};
		product_analytics?.forEach((d: IProductAnalyticsData) => {
			dataset.data = [...dataset.data, d.retail_ingredients];
		});
		return dataset;
	};

	return (
		<div className="ingredient-overview mt-n3">
			<Row className="mb-2">
				<div className="mt-3 mr-2">
					<AccountNotifications mini filterBy={"ingredient"} />
				</div>
				<div className="mr-2 mt-3">
					<Card className="product mb-0">
						<CardBody>
							<div>
								<CardTitle>
									<h1 className="font-weight-semibold mb-0">
										<>
											{product_analytics
												? product_analytics.length != 0
													? product_analytics[product_analytics.length - 1].retail_ingredients
													: 0
												: 0}
										</>
									</h1>
									{loading ? <Loader className="fa-spin ml-2" /> : <Activity className="ml-2" />}
								</CardTitle>
								<CardSubtitle>
									Active Product <br /> Ingredients
								</CardSubtitle>
							</div>
							<div
								className={"ml-2 mt-4 mb-2"}
								style={{ height: 165, maxHeight: 165, width: "100%" }}
							>
								<LineChart
									data={{
										labels: (product_analytics || []).map(
											(d: IProductAnalyticsData, index: number) => {
												return (index + 1).toString();
											}
										),
										datasets: [reduceProductData("Retail Ingredients", "ingredients")]
									}}
									noGridLines
									color={"#fff"}
									xLabel="Last 7 Days"
									// yStepSize={10}
								/>
							</div>
						</CardBody>
					</Card>
				</div>
				<div className="mr-2 mt-3">
					<Card className="starred mr-1 mb-0">
						<CardBody>
							<div>
								<CardTitle>
									<h1 className="font-weight-semibold mb-0">{star_ingredients_count}</h1>
									{loading ? <Loader className="fa-spin ml-2" /> : <Star className="ml-2" />}
								</CardTitle>
								<CardSubtitle>
									Starred <br /> Ingredients
								</CardSubtitle>
							</div>
						</CardBody>
					</Card>
				</div>
			</Row>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {
	return {
		dashboard: state.dashboard,
		profile: state.user.profile
	};
};

const mapDispatchToProps = {
	getOverview: () => dashboardService.getDashboardOverview(),
	getDashboardProductAnalytics: () => dashboardService.getDashboardProductAnalytics()
};
export default connect(mapStateToProps, mapDispatchToProps)(IngredientOverview);
