import React, { useEffect, useState } from "react";
import {
	Nav,
	Navbar,
	NavbarBrand,
	Collapse,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Button,
	Tooltip,
	Dropdown
} from "reactstrap";

import profilephoto from "../../../assets/images/users/1.jpg";
import {
	Power,
	Settings,
	User,
	Bell,
	LogOut,
	Globe,
	ChevronDown,
	ChevronUp,
	Package
} from "react-feather";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/

// import JfLogo from "../../../assets/images/JFIOlogo_beta.svg";
import LiveSearch from "./liveSearch";
import { connect } from "react-redux";
import userService from "../../../services/userService";
import notificationService from "../../../services/notificationService";
import { IApplicationState } from "../../../redux/reducers";
import { error } from "../../../utils/notification";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import { INotification } from "../../../interfaces/notification";
import { notification } from "../../../redux/actions/actionContants";
import Notifications from "../../../routes/Notifications/notifications";
import { Loader } from "react-feather";
import BrandDropdown from "./BrandDropdown";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { IBrand } from "../../../interfaces/company";
import ReactImageFallback from "react-image-fallback";

interface IProps {
	logout: () => void;
	profile: any;
	fetchProfile: () => Promise<any>;
	notifications: INotification[];
	getAllNotifications: () => Promise<any>;
	setSelectedBrand: (brand: IBrand) => Promise<any>;
	selected_brand: IBrand;
}

const Header: React.FC<IProps> = (props) => {
	const showMobilemenu = () => {
		document.getElementById("main-wrapper")?.classList.toggle("show-sidebar");
	};

	const [notificationDropdown, setNotificationDropdown] = useState<boolean>(false);
	const [brandDropdown, setBrandDropdown] = useState<boolean>(false);
	const [brand, setBrand] = useState<IBrand>(props.selected_brand);

	const [loadingNotifications, setLoadingNotifications] = useState<boolean>(false);

	function toggleNotificationDropdown() {
		setNotificationDropdown(!notificationDropdown);
	}

	function toggleBrandDropdown() {
		setBrandDropdown(!brandDropdown);
	}

	const handleBrandChange = (brand: IBrand) => {
		setBrand(brand);
	};

	useEffect(() => {
		props.fetchProfile();
		setLoadingNotifications(true);
		props.getAllNotifications().finally(() => setLoadingNotifications(false));
	}, []);

	useEffect(() => {
		props.setSelectedBrand(brand);
	}, [brand]);

	return (
		<header className="topbar navbarbg" data-navbarbg="skin1" style={{}}>
			<Navbar className="top-navbar d-flex justify-content-between h-100" dark expand="md">
				{/*--------------------------------------------------------------------------------*/}
				{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
				{/*--------------------------------------------------------------------------------*/}
				{/*--------------------------------------------------------------------------------*/}
				{/* Mobile View Toggler  [visible only after 768px screen]                         */}
				{/*--------------------------------------------------------------------------------*/}
				<div className="d-flex align-items-center">
					<LiveSearch />
					{/* <div className="mx-3">
                        <Button outline color="primary" className="btn-lg border-width-2 font-weight-semibold">Search Results</Button>
                    </div> */}
				</div>
				<Collapse className="navbarbg h-100" navbar data-navbarbg="skin1">
					<Nav navbar className="h-100">
						<Dropdown nav inNavbar toggle={toggleBrandDropdown} isOpen={brandDropdown}>
							<DropdownToggle nav caret>
								<div className="brand-dropdown">
									{brand.id ? (
										<Package
											className={"brand-icon"}
											height={24}
											color={"#fff"}
											style={{ backgroundColor: "#3f65f1", padding: 5, borderRadius: "50%" }}
										/>
									) : (
										<Globe className="color-bg-slate" />
									)}
									{brand.name || "All Brands"}
									{brandDropdown ? (
										<ChevronUp style={{ marginLeft: "auto" }} color={"#2c3e50"} size={16} />
									) : (
										<ChevronDown style={{ marginLeft: "auto" }} color={"#2c3e50"} size={16} />
									)}
								</div>
							</DropdownToggle>
							<BrandDropdown handleSelect={handleBrandChange} />
						</Dropdown>

						<Dropdown
							nav
							inNavbar
							toggle={toggleNotificationDropdown}
							isOpen={notificationDropdown}
						>
							<DropdownToggle nav caret>
								<div className="px-2 h-100 d-flex justify-content-center align-items-center">
									<div
										className="bg-very-light-blue rounded-circle d-flex justify-content-center align-items-center"
										style={{ width: 40, height: 40, fontSize: "1.5em" }}
									>
										<Bell className="color-bg-blue" />
									</div>
									<span className="notification-badge" style={{ backgroundColor: "red" }}>
										{loadingNotifications ? (
											<Loader className="fa-spin" size={11} />
										) : (
											<div>
												{
													props.notifications.filter(
														(n: INotification) => !n.is_read && n.message !== "New Ingredient Added"
													).length
												}
											</div>
										)}
									</span>
								</div>
							</DropdownToggle>
							<NotificationDropdown notifications={props.notifications} />
						</Dropdown>

						<UncontrolledDropdown nav inNavbar className={"account-dropdown"}>
							<DropdownToggle nav caret>
								<div
									id={"account-joyride"}
									className="rounded-circle d-flex justify-content-center align-items-center"
									style={{ backgroundColor: "#3f65f1", width: 40, height: 40 }}
								>
									{props.profile?.user_photo ? (
										<ReactImageFallback
											src={props.profile.user_photo}
											fallbackImage={<User color={"#fff"} />}
											style={{
												height: 50,
												width: 50,
												borderRadius: 50
											}}
										/>
									) : (
										<User color={"#fff"} />
									)}
								</div>
							</DropdownToggle>

							<DropdownMenu right>
								<DropdownItem>
									<Link to={routes.PROFILE}>
										<div className={"mt-2"}>
											<Settings className="mr-1 ml-1 color-bg-slate" size={18} />{" "}
											<span className="color-bg-slate">Account</span>
										</div>
									</Link>
								</DropdownItem>
								<DropdownItem onClick={props.logout}>
									<div className={"mt-2"}>
										<LogOut className="mr-1 ml-1 color-bg-slate" size={18} />{" "}
										<span className="color-bg-slate">Logout</span>
									</div>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Collapse>
			</Navbar>
		</header>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile || {},
	notifications: state.notification.all || [],
	selected_brand: state.user.selected_brand
});
const mapDispatchToProps = {
	logout: () => userService.logout(),
	fetchProfile: () => userService.getUserProfile(),
	getAllNotifications: () => notificationService.getAllNotifications(),
	setSelectedBrand: (brand: IBrand) => userService.setSelectedBrand(brand)
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
