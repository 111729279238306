import React, { useState } from "react";
import { IPackage } from "../../../../../interfaces/package";
import PackageItemPlaceholder from "../../../../../assets/images/product-packaging-placeholder.png";
import { Tooltip } from "reactstrap";

interface IProps {
	handlePackageSelect: (item: IPackage) => void;
	matched?: boolean;
	item: IPackage;
	selected: boolean;
}
const PackageMini: React.FC<IProps> = ({
	matched,
	item,
	selected,
	handlePackageSelect,
	...props
}) => {
	const [matchedTooltip, setMatchedTooltip] = useState<boolean>();

	return (
		<div
			className={`package-item-mini ${selected ? "active" : ""}`}
			onClick={() => handlePackageSelect(item)}
		>
			<img src={PackageItemPlaceholder} />
			<h5 className={"mb-0"}>{item.name}</h5>
			{matched && (
				<>
					<span id="matched">
						Matched
						<i
							style={{ color: "#fff" }}
							className="icon-icon-nav-packaging ml-2"
						/>
					</span>
					<Tooltip
						className="simple-tooltip"
						isOpen={matchedTooltip}
						placement="top"
						target="matched"
						toggle={() => setMatchedTooltip(!matchedTooltip)}
					>
						Recommended based on your packaging preferences
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default PackageMini;
