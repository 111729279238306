import React from "react";
// ui components
// import Alerts from '../views/ui-components/alert.jsx';
// import Badges from '../views/ui-components/badge.jsx';
// import Buttons from '../views/ui-components/button.jsx';
// import Cards from '../views/ui-components/cards.jsx';
// import LayoutComponent from '../views/ui-components/layout.jsx';
// import PaginationComponent from '../views/ui-components/pagination.jsx';
// import PopoverComponent from '../views/ui-components/popover.jsx';
// import TooltipComponent from '../views/ui-components/tooltip.jsx';

import { IRoutes } from "../interfaces/route";
// import Dashboard from "./dashboard";
import Dashboard from "./newDashboard";
import Ingredients from "./ingredients";
import Products from "./products";
import { routes } from "./routes";
import Personas from "./personas";
import Packaging from "./packaging";
import Admin from "./admin";
import { USER_ROLES } from "../interfaces/roles";
import Integration from "./integration";
import Analytics from "./analysis";
import Labels from "./labels";
import KnowledgeHub from "./knowledgeHub";
import Account from "./account";
import {
	Box,
	Tag,
	Users,
	BarChart2,
	User,
	Share2,
	Key,
	HelpCircle,
	Bell,
	File
} from "react-feather";
import iconPackaging from "../assets/images/icon-nav-packaging.svg";
import FeatureTeaser from "./feature-teaser/feature-teaser";
import { route } from "../redux/actions/actionContants";
import Notifications from "./Notifications";

let AppRoutes: IRoutes = [
	{
		component: Dashboard,
		path: routes.DASHBOARD,
		name: "Dashboard",
		icon: "icon-nav-dashboard",
		atBottom: false,
		submenus: []
	},
	// {
	//   path: routes.NOTIFICATIONS,
	//   name: "Notifications",
	//   icon: <Bell size={28}/>,
	//   component: Notifications,
	//   atBottom: false,
	//   submenus: [],
	// },
	{
		path: routes.INGREDIENTS,
		name: "Ingredients",
		icon: "icon-nav-ingredients",
		component: Ingredients,
		atBottom: false,
		includeSpace: true,
		submenus: [],
		permission_name: "ingredient_engine",
		upgrade_path: routes.INGREDIENTS_TEASER,
		upgrade_component: FeatureTeaser
	},
	{
		path: routes.PRODUCTS,
		name: "Products",
		icon: <Box size={28} />,
		component: Products,
		atBottom: false,
		submenus: [],
		permission_name: "product_tools",
		upgrade_path: routes.PRODUCTS_TEASER,
		upgrade_component: FeatureTeaser,
		locked_for: ["supplier"]
	},
	{
		path: routes.PACKAGING,
		name: "Packaging",
		icon: "icon-icon-nav-packaging",
		component: Packaging,
		// roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER],
		atBottom: false,
		submenus: [],
		permission_name: "packaging_engine",
		upgrade_path: routes.PACKAGING_TEASER,
		upgrade_component: FeatureTeaser,
		locked_for: ["supplier"]
	},
	{
		path: routes.PERSONAS,
		name: "Personas",
		icon: <Users size={28} />,
		component: Personas,
		roles: [
			USER_ROLES.COMPANY_ADMIN,
			USER_ROLES.COMPANY_USER,
			USER_ROLES.ADMIN,
			USER_ROLES.JF_STAFF
		],
		atBottom: false,
		submenus: [],
		upgrade_path: routes.PERSONAS_TEASER,
		permission_name: "persona_engine",
		upgrade_component: FeatureTeaser,
		locked_for: ["supplier"]
	},
	{
		path: routes.ANALYSIS,
		name: "Analytics",
		icon: <BarChart2 size={28} />,
		component: Analytics,
		// roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER],
		atBottom: false,
		includeSpace: true,
		submenus: [],
		upgrade_component: FeatureTeaser,
		upgrade_path: routes.ANALYSIS_TEASER,
		permission_name: "analysis_engine",
		locked_for: ["supplier"]
	},
	{
		path: "/app/integrations",
		name: "Integrations",
		icon: <Share2 size={28} />,
		component: Integration,
		atBottom: false,
		submenus: []
	},
	{
		path: routes.LABELS,
		name: "Documents",
		icon: <File size={28} />,
		component: Labels,
		atBottom: false,
		// roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.ADMIN],
		submenus: [],
		upgrade_path: routes.LABELS_TEASER,
		permission_name: "labels_tool",
		upgrade_component: FeatureTeaser,
		locked_for: ["supplier"]
	},
	{
		path: "/app/account",
		name: "Account",
		icon: <User size={28} />,
		component: Account,
		includeSpace: true,
		atBottom: true,
		submenus: [
			{
				name: "Profile Settings",
				route: routes.PROFILE
			},
			{
				name: "Company Settings",
				route: routes.COMPANY,
				tabRoles: [USER_ROLES.COMPANY_ADMIN]
			},
			{
				name: "Preferences",
				route: routes.PREFERENCES,
				tabRoles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_USER]
			},
			{
				name: "Billing",
				route: routes.BILLING,
				tabRoles: [USER_ROLES.COMPANY_ADMIN]
			}
		]
	},
	{
		path: routes.ADMIN,
		name: "Admin",
		icon: <Key size={28} />,
		component: Admin,
		roles: [USER_ROLES.ADMIN],
		atBottom: true,
		submenus: []
	},

	{
		path: routes.KNOWLEDGE_HUB,
		name: "Knowledge Hub",
		icon: <HelpCircle size={28} />,
		component: KnowledgeHub,
		atBottom: true,
		submenus: []
	},
	{
		path: routes.UPGRADE,
		name: "Upgrade",
		icon: "",
		component: FeatureTeaser,
		atBottom: false,
		submenus: []
	},

	{
		path: "/app",
		redirect: routes.DEFAULT,
		name: "",
		component: null,
		icon: "",
		atBottom: false,
		submenus: []
	}
];

// var ThemeRoutes = [
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: 'ti-loop',
//     component: Starter
//   },
//   {
//     path: '/alert',
//     name: 'Alerts',
//     icon: 'mdi mdi-comment-processing-outline',
//     component: Alerts
//   },
//   {
//     path: '/badge',
//     name: 'Badges',
//     icon: 'mdi mdi-arrange-send-backward',
//     component: Badges
//   },
//   {
//     path: '/button',
//     name: 'Buttons',
//     icon: 'mdi mdi-toggle-switch',
//     component: Buttons
//   },
//   {
//     path: '/card',
//     name: 'Cards',
//     icon: 'mdi mdi-credit-card-multiple',
//     component: Cards
//   },
//   {
//     path: '/grid',
//     name: 'Grid',
//     icon: 'mdi mdi-apps',
//     component: LayoutComponent
//   },
//   {
//     path: '/pagination',
//     name: 'Pagination',
//     icon: 'mdi mdi-priority-high',
//     component: PaginationComponent
//   },
//   {
//     path: '/popover',
//     name: 'Popover',
//     icon: 'mdi mdi-pencil-circle',
//     component: PopoverComponent
//   },
//   {
//     path: '/ui-components/tooltip',
//     name: 'Toltips',
//     icon: 'mdi mdi-image-filter-vintage',
//     component: TooltipComponent
//   },
//   { path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }
// ];
export default AppRoutes;
