import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowUp, Loader, Truck } from "react-feather";
import { IManufacturer } from "../../../../interfaces/products";
import searchService from "../../../../services/searchService";
import SearchResultList from "../searchResultList";
import { ISearchManufacturer } from "./manufacturerResults";

interface IProps {
	manufacturer: ISearchManufacturer;
	display: string;
}

const ManufacturerIngredients: React.FC<IProps> = ({ manufacturer, ...props }) => {
	const [ingredients, setIngredients] = useState<any[]>([]);
	const [showIngredients, setShowIngredients] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (showIngredients && ingredients?.length == 0) {
			fetchIngredients();
		}
	}, [showIngredients]);

	const fetchIngredients = () => {
		setLoading(true);
		searchService
			.getManufacturerIngredients(manufacturer.id)
			.then((res: any) => {
				setIngredients(res);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<div className="manufacturer-ingredients">
			<div className="manufacturer">
				<Truck size={24} color={"#2c3e50"} />
				<h5 style={{ maxWidth: "60%", wordWrap: "break-word" }}>{manufacturer.name}</h5>
				{showIngredients && ingredients?.length > 0 ? (
					<span
						style={{
							borderRadius: 25,
							padding: "6px 12px",
							color: "#fff",
							backgroundColor: "#3f65f1",
							marginLeft: 15
						}}
					>
						{ingredients?.length + " Results"}
					</span>
				) : (
					<></>
				)}
				<span
					className={`show ${showIngredients ? "active" : "inactive"}`}
					onClick={() => setShowIngredients(!showIngredients)}
				>
					{showIngredients ? (
						<span style={{ whiteSpace: "nowrap" }}>
							Show Less <ArrowUp size={18} />
						</span>
					) : (
						<span style={{ whiteSpace: "nowrap" }}>
							See All Ingredients <ArrowDown size={18} />
						</span>
					)}
				</span>
			</div>
			{showIngredients ? (
				<>
					{loading && ingredients?.length == 0 ? (
						<Loader className="fa-spin" />
					) : (
						<div className={`manufacturer-results ${props.display}`}>
							<SearchResultList display={props.display} small={true} searchResults={ingredients} />
						</div>
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default ManufacturerIngredients;
