import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import growth from "../../assets/images/subscription/growth.png";
import enterprise from "../../assets/images/subscription/enterprise.png";
import fresh from "../../assets/images/subscription/fresh.png";
import supplier from "../../assets/images/subscription/supplier.png";
import group from "../../assets/images/subscription/group.png";
import { ArrowDown, ArrowLeft, Check, Info, LogOut, X } from "react-feather";
import userService from "../../services/userService";
import { connect } from "react-redux";
import { IApplicationState } from "../../redux/reducers";
import SubscriptionCheckoutModal from "../../components/checkout/subscriptionModal";
import FeatureTable from "../account/billing/components/featureTable";
import StripeClimate from "../../components/checkout/stripeClimate";

interface IProps {
	logout: () => void;
}

const Subscribe: React.FC<IProps> = (props) => {
	const [viewDetails, setViewDetails] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>();

	return (
		<div className="subscribe">
			<div className="log-out" onClick={props.logout}>
				Logout <LogOut size={24} />
			</div>
			<Card className={"subscription-options"}>
				<CardBody>
					<h3 className={"mb-3"}>
						<span style={{ color: "#3f65f1" }}>Plans</span> <br /> for Every Team
					</h3>
					<Row className={"mt-4"}>
						<div className={"option"}>
							<img src={fresh} />
							<h4>Fresh</h4>
							<p className="price">
								$199 <br />
								per month
							</p>
							<p>
								<hr style={{ borderTop: "3px solid #7c4aa9", width: "100%" }} />
								Manage your brand with data.
							</p>
							<a href="mailto:sales@journeyfoods.com?subject=Enterprise Tier Subscription Inquiry">
								<Button color="secondary"> Contact Us </Button>
							</a>
						</div>
						<div className={"option"}>
							<img src={growth} />
							<h4>Growth</h4>
							<p className="price">
								$499 <br />
								per month
							</p>
							<p>
								<hr style={{ borderTop: "3px solid #e85a73", width: "100%" }} />
								Grow your products with insights.
							</p>
							<a href="mailto:sales@journeyfoods.com?subject=Enterprise Tier Subscription Inquiry">
								<Button color="secondary"> Contact Us </Button>
							</a>
						</div>
						<div style={{ width: 300, height: 400 }} className={"option"}>
							<img src={supplier} />
							<h4>Supplier</h4>
							{!viewDetails ? (
								<>
									<p className="price">
										$60 <br />
										per year
									</p>
									<p>
										<hr style={{ borderTop: "5px solid #3f65f1", width: "100%" }} />
										Bring your products to Journey Foods.
										<br />
										<br />
									</p>
								</>
							) : (
								<>
									<ul>
										<li>
											{" "}
											<Check size={16} /> <span>1 User</span>
										</li>
										<li>
											{" "}
											<Check size={16} /> <span>Essentials</span>
											<ul>
												<li> Portfolio Pages </li>
												<li> Basic Analytics </li>
											</ul>
										</li>
										<li className="d-flex flex-row">
											{" "}
											<Check className={"mt-2"} size={16} />
											<span>Import Excel and CSV data</span>
										</li>
									</ul>
								</>
							)}

							<span className="info" onClick={() => setViewDetails(!viewDetails)}>
								{!viewDetails ? (
									<>
										<Info size={18} />
										View Details
									</>
								) : (
									<>
										<ArrowLeft size={18} />
										Back
									</>
								)}
							</span>
							<Button
								style={{ marginTop: "auto", marginBottom: 15 }}
								color="primary"
								onClick={() => setModal(true)}
							>
								Select
							</Button>
						</div>
						<div className={"option"}>
							<img src={group} />
							<h4>Group</h4>
							<p className="price">
								$999 <br />
								per month
							</p>
							<p>
								<hr style={{ borderTop: "3px solid #0ed7ac", width: "100%" }} />
								Improve your portfolio.
							</p>
							<a href="mailto:sales@journeyfoods.com?subject=Enterprise Tier Subscription Inquiry">
								<Button color="secondary"> Contact Us </Button>
							</a>
						</div>
						<div className={"option"}>
							<img src={enterprise} />
							<h4>Enterprise</h4>
							<p className="price">
								Custom
								<br /> <br />
							</p>
							<p>
								<hr style={{ borderTop: "3px solid #f4a850", width: "100%" }} />
								{"Boost your R&D team and beat competition."}
							</p>
							<a href="mailto:sales@journeyfoods.com?subject=Enterprise Tier Subscription Inquiry">
								<Button color="secondary"> Contact Us </Button>
							</a>
						</div>
					</Row>
					<div className={"d-flex justify-content-center mt-3"}>
						<div className="view-features">
							<a href="#features">View All Features</a>
							<ArrowDown />
						</div>
					</div>
					<div className="notice">
						If you have already purchased a subscription directly through one of our team members,
						please contact us at <a href="mailto:hello@journeyfoods.com">hello@journeyfoods.com</a>{" "}
						to make sure that you have access to the platform.
					</div>
				</CardBody>
			</Card>
			<div id="features" style={{ padding: "0 100px", display: "flex", flexDirection: "column" }}>
				<h3 style={{ alignSelf: "center", margin: "0.5rem" }}>All Features</h3>
				<FeatureTable />
			</div>
			<div className={"d-flex align-item-center justify-content-center pb-3"}>
				<div style={{ width: 500 }}>
					<StripeClimate />
				</div>
			</div>

			<Modal isOpen={modal}>
				<ModalHeader>
					<div className={"modal-header-content"}>
						<span>Subscribe</span>
						<X
							onClick={() => {
								setModal(false);
							}}
							className={"close-modal"}
							size={24}
						></X>
					</div>
				</ModalHeader>
				<ModalBody>
					<SubscriptionCheckoutModal />
				</ModalBody>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => {};

const mapDispatchToProps = {
	logout: () => userService.logout()
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
