import { IIngredient, ISearchIngredient, ISimiliarIngredient } from "../../interfaces/ingredient";
import { ActionType } from "../actions/action";
import { ingredients } from "../actions/actionContants";
import { IPaginateList } from "../../interfaces/pagination";
import { IPersona } from "../../interfaces/persona";

export interface IIngredientState {
	ingredients: IPaginateList<IIngredient[]>;
	searchIngredients: ISearchIngredient[];
	selected: IIngredient | null;
	selectedPersonas: IPersona[];
	selectedSimiliarIngredients: ISimiliarIngredient[];
	recentlyViewed: any[];
	starred: any[];
	search: IIngredient[];
	selectedStatus: boolean;
}

const INIT_STATE: IIngredientState = {
	ingredients: {
		list: [],
		pagination: {
			page: 1,
			pages: 0,
			size: 20,
			total: 0
		}
	},
	searchIngredients: [],
	recentlyViewed: [],
	selected: null,
	starred: [],
	search: [],
	selectedPersonas: [],
	selectedSimiliarIngredients: [],
	selectedStatus: false
};

export default function (state: IIngredientState = INIT_STATE, action: ActionType) {
	// return state;
	switch (action.type) {
		case ingredients.INGREDIENTS_RESPONSE:
			return {
				...state,
				ingredients: action.payload
			};
		case ingredients.PAGINATE_INGREDIENTS:
			return {
				...state,
				ingredients: {
					...state.ingredients,
					pagination: {
						...state.ingredients.pagination,
						page: action.payload
					}
				}
			};
		case ingredients.GET_STARRED_INGREDIENTS_RESPONSE:
			return {
				...state,
				starred: action.payload
			};
		case ingredients.GET_INGREDIENT_DETAIL_RESPONSE:
			return {
				...state,
				selected: action.payload
			};
		case ingredients.GET_INGREDIENT_STATUS_RESPONSE:
			return {
				...state,
				selectedStatus: action.payload
			};
		case ingredients.GET_INGREDIENT_PERSONA_RESPONSE:
			return {
				...state,
				selectedPersonas: action.payload
			};

		case ingredients.GET_SIMILIAR_INGREDIENTS_RESPONSE:
			return {
				...state,
				selectedSimiliarIngredients: action.payload
			};
		case ingredients.GET_SIMILIAR_INGREDIENTS_RESPONSE:
			return {
				...state,
				selectedSimiliarIngredients: action.payload
			};
		case ingredients.GET_RECENTLY_VIEWED_RESPONSE:
			return {
				...state,
				recentlyViewed: action.payload.slice(0, 10)
			};
		case ingredients.INGREDIENTS_SEARCH_RESPONSE:
			return {
				...state,
				searchIngredients: action.payload
			};
		default:
			return state;
	}
}
