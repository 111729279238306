import React, { MouseEventHandler } from "react";
import { Card } from "reactstrap";
import SuitabilityBar from "../../../components/suitabilityBar";
import config from "../../../utils/config";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { History } from "history";

import { Star, Trash } from "react-feather";
import { IApplicationState } from "../../../redux/reducers";
import { connect } from "react-redux";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { ImageSkeleton } from "../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	name: string;
	style?: React.CSSProperties;
	starred: boolean;
	suitability?: number;
	rank?: number;
	mini?: boolean;
	manufacturer?: {
		name: string;
		id: string;
	};
	percent?: number;
	id?: string;
	className?: string;
	onClick?: MouseEventHandler;
	history: History;
	portfolioItem?: boolean;
	onRemoveHandler?: (ingredientId: string) => void;
}

const IngredientItem: React.FC<IProps> = (props) => {
	function handleRouteChange() {
		props.history.push(`/app/ingredients/${props.id}`);
	}

	return (
		<Card
			className={`ingredient-item ${props.mini ? "mini" : ""} mb-2 ${props.className || ""}`}
			style={props.style}
		>
			{props.starred ? (
				<Star className="star" color="#f4a850" size={16} fill="#f4a850" />
			) : (
				<Star className="star" color="#657280" size={16} />
			)}
			{props.onRemoveHandler && (
				<Trash
					size={16}
					style={{ right: "35px" }}
					onClick={() => (props.onRemoveHandler ? props.onRemoveHandler(props.id || "") : null)}
				/>
			)}
			<div className="info" onClick={props.onClick || handleRouteChange}>
				<ReactImageFallback
					src={config.api.endpoints.ingredients.ingredientImage(props.name)}
					className="ingredient-image border-radius-6"
					initialImage={<ImageSkeleton className={"table-img-ghost"} />}
					fallbackImage={getIngredientImagePlaceholder()}
				/>
				<div className="ingredient-detail">
					<h5 className="font-weight-semibold mb-0 ingredient-title">{props.name}</h5>
					{props.mini && props.portfolioItem && (
						<h6 className={"portfolio-ingredient"}>
							<i className="icon-nav-ingredients"></i>
							<span>Portfolio</span>
						</h6>
					)}
					{!props.mini && (
						<div className="ingredient-rate">
							<p className="price font-weight-normal">{props.manufacturer?.name || "N/A"}</p>
							<p className="rank font-weight-normal">Rank #{props.rank}</p>
						</div>
					)}
					{props.mini && props.percent && <p className="percent">5%</p>}
				</div>
			</div>
			{
				<SuitabilityBar
					type={"preference"}
					value={props.suitability != 0 && props.suitability != undefined ? props.suitability : 70}
				/>
			}
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState, props: any) => {
	return {
		starred: state.ingredient.starred.find((item) => item.id === props.id)
	};
};

export default connect(mapStateToProps)(withRouter(IngredientItem));
