import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { ArrowRight, HelpCircle } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, CardBody, CardFooter } from "reactstrap";
import { calculateLabels } from "../../../components/charts/sampleData";
import HelpTip from "../../../components/common/helpTip";
import Upgrade from "../../../components/common/upgrade";
import { IUser } from "../../../interfaces/user";
import { IApplicationState } from "../../../redux/reducers";
import { routes } from "../../routes";

interface IProps extends RouteComponentProps {
	profile?: IUser | undefined;
}

const product_data = {
	labels: calculateLabels(3),
	datasets: [
		{
			label: "Product Development",
			backgroundColor: "#3f65f1",
			borderColor: "#3f65f1",
			borderWidth: 2,
			data: [0, 0, 0]
		}
	]
};

const ingredient_data = {
	labels: calculateLabels(3),
	datasets: [
		{
			label: "Ingredient Discovery",
			backgroundColor: "#f4a850",
			borderColor: "#f4a850",
			borderWidth: 2,
			data: [0, 0, 0]
		}
	]
};

const bar_chart_options = {
	responsive: true,
	maintainAspectRatio: false,
	legend: {
		display: false
	},
	scales: {
		xAxes: [
			{
				gridLines: {
					display: false,
					drawBorder: false
				},
				barPercentage: 0.5
			}
		],
		yAxes: [
			{
				ticks: {
					display: false
				},
				gridLines: {
					display: false,
					drawBorder: false
				}
			}
		]
	}
	// cornerRadius: 6,
};

const BarChart: React.FC<IProps> = (props) => {
	const [overlay, setOverlay] = useState<boolean>();

	return (
		// <Card
		// 	className="bar-chart-card"
		// 	onMouseEnter={() => setOverlay(true)}
		// 	onMouseLeave={() => setOverlay(false)}
		// >
		<Card className="bar-chart-card">
			{overlay ? (
				<div className="coming-soon">
					<p>We need more data!</p>
				</div>
			) : (
				<> </>
			)}
			<CardBody>
				<div className="charts">
					<div className={"chart-container"}>
						<h4>
							<span>Product Development</span>
							<HelpTip
								id="product-development-help"
								style={{ marginLeft: 10 }}
								message={"Create and iterate on products to track development benchmarks"}
							/>
						</h4>
						{props.profile?.company.subscription_type == "supplier" ? (
							<Upgrade message="for Product Metrics" />
						) : (
							<div className="bar-chart-wrapper">
								<Bar data={product_data} options={bar_chart_options} />
							</div>
						)}
						<div
							className="view products"
							onClick={() => {
								props.history.push(routes.PRODUCTS);
							}}
						>
							View Products <ArrowRight size={16} />
						</div>
					</div>
					<div className="divider" />
					<div className="chart-container">
						<h4>
							<span>Ingredient Discovery</span>
							<HelpTip
								id="ingredient-discovery-help"
								style={{ marginLeft: 10 }}
								message={
									"Add ingredients to your portfolio and products to maximize our ingredient engine"
								}
							/>
						</h4>
						{props.profile?.company.subscription_type == "supplier" ? (
							<Upgrade message="for Ingredient Metrics" />
						) : (
							<div className="bar-chart-wrapper">
								<Bar data={ingredient_data} options={bar_chart_options} />
							</div>
						)}

						<div
							className="view ingredients"
							onClick={() => {
								props.history.push(routes.INGREDIENTS);
							}}
						>
							View Ingredients <ArrowRight size={16} />
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile
});

export default connect(mapStateToProps, null)(withRouter(BarChart));
