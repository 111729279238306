import { Api } from "./api";
import { Dispatch } from "react";
import { IApplicationState } from "../redux/reducers";
import config from "../utils/config";
import {
	getAllReportsResponseAction,
	getAllReportRequestsResponseAction
} from "../redux/actions/reports/reports";
import { info } from "../utils/notification";
import { IReportNew } from "../interfaces/report";
import { getAllRecommendationsResponseAction } from "../redux/actions/recommendations/recommendations";
import { IRecommendation } from "../interfaces/recommendation";

export enum RECOMMENDATION_STATUS {
	REQUESTED = "REQUESTED",
	RECOMMENDED = "RECOMMENDED"
}

class RecommendationService extends Api {
	getAllRecommendationRequests(status?: RECOMMENDATION_STATUS) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const params = status
					? {
							status: status
					  }
					: {};
				const response = await this.http.get(config.api.endpoints.recommendations.recommendations, {
					params
				});
				return response.data;
			} catch (error) {
				this.handleError(error, "Getting Recommendations");
			}
		};
	}

	getAllRecommendations() {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const params = {
					status: RECOMMENDATION_STATUS.RECOMMENDED
				};
				const response = await this.http.get(config.api.endpoints.recommendations.recommendations, {
					params
				});
				dispatch(getAllRecommendationsResponseAction(response.data));
			} catch (error) {
				// this.handleError(error, "Getting Recommendations");
			}
		};
	}

	addManualRecommendation(user_id: string, recommendation: any) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const params = {
					user: user_id
				};
				const body = recommendation;
				const response = await this.http.post(
					config.api.endpoints.recommendations.recommendations,
					body,
					{
						params
					}
				);
				info("Manual Recommendation Successful");
				return response.data;
			} catch (error) {
				this.handleError(error, "Adding Manual Recommendation");
			}
		};
	}

	makeRecommendation(recommendation_id: string, recommendation: IRecommendation) {
		return async (dispatch: Dispatch<any>, getState: () => IApplicationState) => {
			try {
				const body = recommendation;
				const response = await this.http.put(
					config.api.endpoints.recommendations.makeRecommendation(recommendation_id),
					body
				);
				info("Recommendation Created");
				return response.data;
			} catch (error) {
				this.handleError(error, "Recommendation Creation");
			}
		};
	}
}

export default new RecommendationService();
