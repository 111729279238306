import React, { useEffect, useState } from "react";
import { ArrowRight, Users } from "react-feather";
import { Card, CardBody } from "reactstrap";
import config from "../../../../utils/config";
import SustainabilityScore from "../../../../assets/images/sustainability-scale-icon.svg";
import SuitabilityIcon from "../../../../assets/images/suitability-score-icon2.svg";
import { IApplicationState } from "../../../../redux/reducers";
import { connect } from "react-redux";
import { IUser } from "../../../../interfaces/user";
import Upgrade from "../../../../components/common/upgrade";
import { truncate } from "../../../../utils/string";
import { routes } from "../../../routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IRecommendation } from "../../../../interfaces/recommendation";
import recommendationService from "../../../../services/recommendationService";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";
import ReactImageFallback from "react-image-fallback";
import { ImageSkeleton } from "../../../../utils/ghostUI";
import RecommendationLoader from "./recommendationLoader";

interface IProps extends RouteComponentProps {
	profile: IUser | undefined;
	recommendations: IRecommendation[];
	getAllRecommendations: () => Promise<any>;
}

const Reccomendations: React.FC<IProps> = ({
	recommendations,
	getAllRecommendations,
	...props
}) => {
	const [overlay, setOverlay] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>();

	useEffect(() => {
		setLoading(true);
		getAllRecommendations().finally(() => setLoading(false));
	}, []);

	const generateRecIcon = (rec: IRecommendation, isRec: boolean) => {
		if (rec?.objective?.length > 0) {
			switch (rec.objective[0]) {
				case "cost":
					return isRec ? "$" : "$$$";
				case "sustainability":
					return isRec ? (
						<>
							<img src={SustainabilityScore} />
							<img src={SustainabilityScore} />
							<img src={SustainabilityScore} />
						</>
					) : (
						<img src={SustainabilityScore} />
					);
				case "preference":
					return isRec ? (
						<>
							<img src={SuitabilityIcon} />
							<img src={SuitabilityIcon} />
							<img src={SuitabilityIcon} />
						</>
					) : (
						<img src={SuitabilityIcon} />
					);
				case "persona":
					return isRec ? (
						<>
							<Users size={14} color="#7c4aa9" />
							<Users size={14} color="#7c4aa9" />
							<Users size={14} color="#7c4aa9" />
						</>
					) : (
						<>
							<Users size={14} color="#7c4aa9" />
						</>
					);
				default:
					return isRec ? "$" : "$$$";
			}
		} else {
			return isRec ? "$" : "$$$";
		}
	};

	return (
		// <Card className={'recommendations'} onMouseEnter={() => setOverlay(true)} onMouseLeave={() => setOverlay(false)}>
		<Card className={"recommendations"}>
			{overlay ? (
				<div className="coming-soon">
					<p>Coming Soon</p>
				</div>
			) : (
				<> </>
			)}

			<CardBody>
				<h4>Recommendations</h4>
				{props.profile?.company.subscription_type == "supplier" ? (
					<div style={{ marginTop: 75 }}>
						<Upgrade />
					</div>
				) : (
					<div className={"rec-container"}>
						{loading ? (
							<RecommendationLoader />
						) : (
							<>
								{recommendations?.length > 0 ? (
									recommendations
										?.slice(recommendations.length - 2, recommendations.length)
										.map((rec: IRecommendation) => {
											return (
												<div
													className="rec"
													onClick={() => props.history.push(routes.PRODUCTS + "/" + rec.product.id)}
												>
													<span>
														<div className="rec-tag">{generateRecIcon(rec, false)}</div>
														{/* <img
															src={config.api.endpoints.ingredients.ingredientImage(
																rec.ingredient.jf_display_name
															)}
														/> */}
														<ReactImageFallback
															initialImage={
																<ImageSkeleton className="dash-recommendation-img-ghost" />
															}
															src={config.api.endpoints.ingredients.ingredientImage(
																rec?.ingredient?.jf_display_name
															)}
															fallbackImage={getIngredientImagePlaceholder()}
															alt="Ingredient Image"
														/>
														<p>{truncate(rec?.ingredient?.jf_display_name, 10)}</p>
													</span>
													<ArrowRight size={32} />
													<span>
														<div className="rec-tag">{generateRecIcon(rec, true)}</div>
														{/* <img
															src={config.api.endpoints.ingredients.ingredientImage(
																rec.recommended_ingredients[0].jf_display_name
															)}
														/> */}
														<ReactImageFallback
															initialImage={
																<ImageSkeleton className="dash-recommendation-img-ghost" />
															}
															src={config.api.endpoints.ingredients.ingredientImage(
																rec.recommended_ingredients[0].jf_display_name
															)}
															fallbackImage={getIngredientImagePlaceholder()}
															alt="Ingredient Image"
														/>
														<p>{truncate(rec.recommended_ingredients[0].jf_display_name, 10)}</p>
													</span>
												</div>
											);
										})
								) : (
									<div className={"pt-5"} style={{ textAlign: "center" }}>
										Set objectives on your products to start receiving ingredient recommendations!
									</div>
								)}
							</>
						)}
					</div>
				)}
				<div className="view">
					View All <ArrowRight size={16} onClick={() => props.history.push(routes.NOTIFICATIONS)} />
				</div>
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	profile: state.user.profile,
	recommendations: state.recommendations.all
});

const mapDispatchToProps = {
	getAllRecommendations: () => recommendationService.getAllRecommendations()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reccomendations));
