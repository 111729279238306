import IngredientImagePlaceholder1 from "../assets/images/ingredient-placeholders/IngredientImagePlaceholder1.png";
import IngredientImagePlaceholder2 from "../assets/images/ingredient-placeholders/IngredientImagePlaceholder2.png";
import IngredientImagePlaceholder3 from "../assets/images/ingredient-placeholders/IngredientImagePlaceholder3.png";
import React from "react";
import config from "./config";
import { ICountryData, IIngredient, IIngredientFormData } from "../interfaces/ingredient";
import { IOption } from "../components/form/Select";
import { ICountry } from "../interfaces/country";

export const IngredientNutrientsArray = [
	"df_energy_kcal",
	"df_protein",
	"df_total_lipid",
	"df_carbohydrate",
	"df_fiber",
	"df_sugar",
	"df_calcium",
	"df_vitamin_d2_d3",
	"df_vitamin_c",
	"df_cholesterol",
	"df_fatty_acid_total_saturated",
	"df_fatty_acid_total_trans",
	"df_iron",
	"df_potassium",
	"df_sodium"
];

export const IngredientCategories: { [index: string]: string[] } = {
	ingredients: [
		"Beverages",
		"Dairy",
		"Oils and Fat",
		"Plant-Based",
		"Grains",
		"Fruits & Vegetables",
		"Animal/Insect-Based",
		"Sweetener",
		"Nutrition",
		"Functional"
	],
	additives: ["Shelf-Life", "Taste", "Texture", "Color", "Processing Aids"]
};

export const getIngredientImagePlaceholder = () => {
	const images = [
		IngredientImagePlaceholder1,
		IngredientImagePlaceholder2,
		IngredientImagePlaceholder3
	];
	const placeholder = images[Math.floor(Math.random() * (2 - 0 + 1)) + 0];
	return placeholder;
};

export const formatIngredientFormData = (
	data: IIngredientFormData,
	currentIngredient: IIngredient | null
): any => {
	//Don't include market values that aren't tagged for removal & are already included in an ingredients country_data
	const updated_markets = data.available_markets.filter(
		(val: { id: string; remove_id: string }) =>
			currentIngredient?.available_markets?.find((c: ICountry) => c.id == val.id) == undefined
	);

	const { country_data, ...rest } = data;

	let formattedData = {
		...rest,
		food_group: data?.food_group?.map((option: IOption) => option.label) || [],
		available_markets: updated_markets
	};
	return formattedData;
};
