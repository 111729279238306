import React from "react";
import { Minus, Plus } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { Link } from "react-router-dom";
import { ISearchIngredient } from "../../../../interfaces/ingredient";
import config from "../../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../../utils/ingredient";

interface IProps {
	key?: number;
	item: ISearchIngredient;
	select?: () => void;
	deselect?: () => void;
	isRecommended?: boolean;
}
const IngredientItem: React.FC<IProps> = ({
	item,
	select,
	deselect,
	isRecommended = false,
	...props
}) => {
	return (
		<li key={item.id + props.key} className="ingredient-search-item">
			<ReactImageFallback
				src={config.api.endpoints.ingredients.ingredientImage(item.jf_display_name)}
				fallbackImage={getIngredientImagePlaceholder()}
				alt="Ingredient Image"
				className="mr-2 ml-2"
			/>
			<Link to={`/app/ingredients/${item.id}`}>{item.jf_display_name.slice(0, 30)}</Link>
			{isRecommended && <span className={"journey-ai-tag"}>Journey AI</span>}
			<div
				className={`${select ? " bg-very-light-blue add" : "remove"} rounded-circle`}
				onClick={select || deselect}
			>
				{deselect ? <Minus color="#e85a73" size={18} /> : <Plus color="#3f65f1" size={18} />}
			</div>
		</li>
	);
};

export default IngredientItem;
