import React, { useState } from "react";
import { ArrowRight, Download, X } from "react-feather";
import ReactImageFallback from "react-image-fallback";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { IIngredient } from "../../../interfaces/ingredient";
import { IRecommendation } from "../../../interfaces/recommendation";
import config from "../../../utils/config";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import RecommendedIngredientsTable from "./recommendIngredientsTable";
import { RecommendationActions } from "../../../components/common/recommendations";

interface IProps {
	isOpen: boolean;
	recommendation: IRecommendation;
	toggle: () => void;
}

const RecommendationModal: React.FC<IProps> = ({ isOpen, recommendation, toggle, ...props }) => {
	const [
		selectedRecommendationIngredient,
		setSelectedRecommendationIngredient
	] = useState<IIngredient>();

	const [viewActionPlan, setViewActionPlan] = useState<boolean>();

	const navBackCallback = () => {
		setViewActionPlan(false);
	};

	return (
		<Modal isOpen={isOpen} className={"notification-modal recommendation"}>
			<ModalHeader>
				{`Recommendations for ${recommendation.ingredient.jf_display_name}`}
				<X className="float-right" onClick={toggle} />
			</ModalHeader>
			<ModalBody>
				{viewActionPlan ? (
					<RecommendationActions
						recommendation={recommendation}
						navBackCallback={navBackCallback}
					/>
				) : (
					<>
						<Col>
							<Row className={"mb-3"}>
								{/* <img
							className={"mr-3"}
							src={config.api.endpoints.ingredients.ingredientImage(
								recommendation.ingredient.jf_display_name
							)}
						/> */}
								<ReactImageFallback
									src={config.api.endpoints.ingredients.ingredientImage(
										recommendation.ingredient.jf_display_name
									)}
									fallbackImage={getIngredientImagePlaceholder()}
									alt="Ingredient Image"
									className={"mr-3"}
								/>

								<div>
									<h6>Associated Tags:</h6>
									<div className="rec-tags">
										{recommendation?.tags?.map((tag: string) => {
											return <span>{tag}</span>;
										})}
									</div>
								</div>
							</Row>
						</Col>
						<RecommendedIngredientsTable recommendation={recommendation} />
						<p>
							{" "}
							Created On: <br />{" "}
							{recommendation?.created_date
								? new Date(recommendation.created_date).toString()
								: ""}{" "}
						</p>
						<div className={"action-buttons"}>
							<Button
								style={{
									padding: ".375rem",
									height: 32
								}}
								className={"mr-2 d-flex align-items-center justify-content-center"}
								color="success"
								onClick={() => setViewActionPlan(true)}
							>
								<span>Actions</span>
								<ArrowRight size={18} className={"ml-2"} />
							</Button>
							<a
								href=" https://storage.googleapis.com/journeyfoods-datasheets/CactOLean_Tech_Sheet_v1.pdf"
								target="_blank"
							>
								<Button
									color="primary"
									className="d-flex align-items-center justify-content-center"
									style={{
										padding: ".375rem",
										height: 32,
										width: 32,
										marginLeft: "auto",
										marginRight: 0
									}}
								>
									<Download size={18} color="#fff" />
								</Button>
							</a>
						</div>
					</>
				)}
			</ModalBody>
		</Modal>
	);
};

export default RecommendationModal;
