import React, { useEffect, useRef, useState } from "react";
import { Grid, List, Loader } from "react-feather";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { IIngredient } from "../../interfaces/ingredient";
import { IPaginateList } from "../../interfaces/pagination";
import { search } from "../../redux/actions/actionContants";
import { IApplicationState } from "../../redux/reducers";
import ingredientService from "../../services/ingredientService";
import { toCamelCase } from "../../utils/string";
import searchService from "../../services/searchService";
import { IUser } from "../../interfaces/user";
import Upgrade from "../../components/common/upgrade";
import { paginateAction } from "../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import SearchFilters from "./components/advancedSearchFilters";
import ManufacturerResults from "./components/manufacturerResults/manufacturerResults";
import SearchResultList from "./components/searchResultList";
import { IngredientResults, ProductResults } from "./components/portfolioResults";
import { IPackageFilters } from "../../services/packagingService";
import { IPackage } from "../../interfaces/package";
import PackagingResultList from "./components/packagingResultList";
import portfolioService from "../../services/preferenceService";
import PackageFilters, { IFilterOption } from "../packaging/packaging/components/packageFilters";
import { formatPackagingFilters } from "../../utils/packaging";

interface IProps extends RouteComponentProps {
	searchResults: any;
	paginatedSearchResults: IPaginateList<any[]>;
	term: string | undefined;
	ingredients: IPaginateList<IIngredient[]>;
	profile: IUser | undefined;
	packaging: IPaginateList<IPackage[]>;
	searchFilter: any | undefined;
	searchLoading: boolean;
	paginatePackagingResults: (page: number) => Promise<any>;
	searchPackaging: (term: string, filters?: IPackageFilters | undefined) => Promise<any>;
	addToPortfolio: (id: string) => Promise<any>;
	getPortfolioIngredients: () => Promise<any>;
	paginate: (page: number) => Promise<void>;
	paginateSearchResults: (term: string) => Promise<any>;
	getPackagingPreferences: () => Promise<any>;
}

const Search: React.FC<IProps> = ({ paginatedSearchResults: { list, pagination }, ...props }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [display, setDisplay] = useState<string>("list");
	const [searchResults, setSearchResults] = useState<any[]>(list);
	const [searchLoading, setSearchLoading] = useState<boolean>();
	const ingredientTermRef = useRef<string | undefined>();
	const packagingTermRef = useRef<string | undefined>();
	const filterRef = useRef<any | undefined>();
	const packagingFilterRef = useRef<any | undefined>([]);
	const packageResultsRef = useRef<any>([]);
	const ingredientResultsRef = useRef<any>([]);
	const [packagingSearchResults, setPackagingSearchResults] = useState<IPackage[]>(
		props.packaging.list
	);
	const [allPackagingFilterOptions, setAllPackagingFilterOptions] = useState<any>();
	const [appliedPackagingFilters, setAppliedPackagingFilters] = useState<IFilterOption[]>([]);

	useEffect(() => {
		return function cleanup() {
			props.paginate(1); //Reset search pagination on component unmount
			props.paginatePackagingResults(1);
		};
	}, []);

	// Handle Ingredient Search Result List Change
	useEffect(() => {
		if (
			ingredientTermRef.current != props.term ||
			props.searchFilter?.name != filterRef.current?.name
		) {
			ingredientTermRef.current = props.term;
			filterRef.current = props.searchFilter;
			if (ingredientResultsRef.current != list) {
				setSearchResults(list);
				ingredientResultsRef.current = list;
			}
		} else {
			if (ingredientResultsRef.current != list) {
				setSearchResults(searchResults.concat(list));
			} else {
				setSearchResults(list);
			}
			ingredientResultsRef.current = list;
		}

		setSearchLoading(false);
	}, [list]);

	// Handle Packaging Search Result List Change
	useEffect(() => {
		const packagingResults = props.packaging.list;
		if (packagingTermRef.current != props.term) {
			packagingTermRef.current = props.term;
			if (packageResultsRef.current != packagingResults)
				setPackagingSearchResults(packagingResults);
			packageResultsRef.current = packagingResults;
		} else {
			if (JSON.stringify(packageResultsRef.current) != JSON.stringify(packagingResults)) {
				setPackagingSearchResults(packagingSearchResults.concat(packagingResults));
			} else {
				setPackagingSearchResults(packagingResults);
			}
			packageResultsRef.current = packagingResults;
		}
	}, [props.packaging.list]);

	useEffect(() => {
		// liveSearch.tsx handles initial search so don't call if on first page
		if (props.term && pagination.page > 1) {
			props.paginateSearchResults(props.term);
		}
	}, [pagination.page]);

	useEffect(() => {
		if (JSON.stringify(appliedPackagingFilters) != JSON.stringify(packagingFilterRef.current)) {
			setPackagingSearchResults([]);
			props.paginatePackagingResults(1); // Reset pagination when filter is toggled
		}
		if (props.term) {
			const formattedFilters = formatPackagingFilters(appliedPackagingFilters);
			props.searchPackaging(props.term, formattedFilters);
		}
		packagingFilterRef.current = appliedPackagingFilters;
		// TODO implement checks for filter changes for packaing
	}, [props.packaging.pagination.page, appliedPackagingFilters]);

	useEffect(() => {
		setLoading(props.searchLoading);
	}, [props.searchLoading]);

	useEffect(() => {
		props.getPackagingPreferences().then((data) => {
			const temp = data.reduce((d: any, a: any) => {
				d[a.preference_type] = d[a.preference_type] || [];
				d[a.preference_type].push(a);
				return d;
			}, Object.create(null));
			setAllPackagingFilterOptions(temp);
		});
	}, []);

	const onPackagingFilterChange = (options: any) => {
		setAppliedPackagingFilters(options.map((o: any) => o.value));
	};

	return (
		<>
			<div className="search-page">
				<Col className={"pl-0 live-search-container"}>
					<Col className="pl-0 ingredient-results">
						<h3> Live Search {props.term ? " for " + toCamelCase(props.term) : ""}</h3>
						<SearchFilters />
						<div className={"display-options"}>
							<Grid
								size={24}
								className={display == "grid" ? "active display-icon" : "display-icon"}
								onClick={() => {
									setDisplay("grid");
								}}
							/>
							<List
								size={24}
								className={display == "list" ? "active display-icon" : "display-icon"}
								onClick={() => {
									setDisplay("list");
								}}
							/>
						</div>
						{props.profile?.company.subscription_type == "supplier" ? (
							<div>
								<Upgrade />
								<p style={{ textAlign: "center", marginTop: 15 }}>To discover Journey AI results</p>
							</div>
						) : (
							<>
								{/* {searchResults?.length > 0 ? ( */}
								{props.term ? (
									<div className={"mb-3"}>
										<h4>
											Ingredients:
											<span style={{ color: "#3f65f1" }}>
												{props.profile?.company.subscription_type == "supplier" ? (
													" - "
												) : loading ? (
													<Loader className="fa-spin ml-2" />
												) : (
													` ${pagination.total != 0 ? pagination.total : "No Results"}`
												)}
											</span>
										</h4>
										{props.searchFilter?.name == "manufacturer" && searchResults.length > 0 ? (
											<ManufacturerResults display={display} searchManufacturers={searchResults} />
										) : (
											<Row
												className={
													packagingSearchResults?.length > 0 && packagingFilterRef != undefined
														? "shared"
														: ""
												}
												style={{
													display:
														!props.searchLoading && searchResults?.length == 0 ? "none" : "flex"
												}}
											>
												<SearchResultList display={display} searchResults={searchResults} />
												{pagination.page * pagination.size < pagination.total &&
												searchResults.length > 0 ? (
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															width: "100%",
															paddingBottom: 15
														}}
													>
														<Button
															disabled={searchLoading}
															style={{ width: "max-content" }}
															onClick={() => {
																props.paginate(pagination.page + 1);
																setSearchLoading(true);
															}}
														>
															{searchLoading ? (
																<Loader size={14} className="fa-spin" />
															) : (
																"Load More"
															)}
														</Button>
													</div>
												) : (
													<></>
												)}
											</Row>
										)}
									</div>
								) : (
									<></>
								)}
								{props.term ? (
									<div className={"mt-3"}>
										<h4>
											Packaging:
											<span style={{ color: "#3f65f1" }}>
												{props.profile?.company.subscription_type == "supplier" ? (
													" - "
												) : loading ? (
													<Loader className="fa-spin ml-2" />
												) : (
													` ${
														props.packaging.pagination.total != 0
															? props.packaging.pagination.total
															: "No Results"
													}`
												)}
											</span>
										</h4>
										{((packagingSearchResults && packagingSearchResults.length > 0) ||
											appliedPackagingFilters.length > 0) && (
											<PackageFilters
												showTitle={false}
												filterOptions={allPackagingFilterOptions}
												onChange={onPackagingFilterChange}
											/>
										)}

										<Row className={searchResults?.length > 0 ? "shared" : ""}>
											<PackagingResultList searchResults={packagingSearchResults} />
											{props.packaging.pagination.page * props.packaging.pagination.size <
												props.packaging.pagination.total && packagingSearchResults.length > 0 ? (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														width: "100%",
														paddingBottom: 15
													}}
												>
													<Button
														disabled={searchLoading}
														style={{ width: "max-content" }}
														onClick={() => {
															props.paginatePackagingResults(props.packaging.pagination.page + 1);
															setSearchLoading(true);
														}}
													>
														{searchLoading ? <Loader size={14} className="fa-spin" /> : "Load More"}
													</Button>
												</div>
											) : (
												<></>
											)}
										</Row>
									</div>
								) : (
									<></>
								)}
							</>
						)}
					</Col>
					{/* Portfolio Results */}
					<Col className="portfolio-results mt-2">
						<h4 className={"pb-3"}>Portfolio:</h4>
						<Row className="portfolio-ingredients">
							<IngredientResults />
						</Row>
						<Row className="portfolio-products">
							<ProductResults />
						</Row>
					</Col>
				</Col>
			</div>
		</>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	searchResults: state.search.result,
	searchFilter: state.search.filter,
	paginatedSearchResults: state.search.paginated_results,
	term: state.search.term,
	ingredients: state.ingredient.ingredients,
	profile: state.user.profile,
	packaging: state.packaging.search.paginated_results,
	searchLoading: state.search.loading
});

const mapDispatchToProps = {
	addToPortfolio: (id: string) => ingredientService.addIngredientToPortfolio(id),
	getPortfolioIngredients: () => ingredientService.getIngredients(),
	paginateSearchResults: (term: string) => searchService.paginateSearch(term),
	paginate: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PAGINATION, page));
	},
	searchPackaging: (term: string, filters?: IPackageFilters | undefined) =>
		searchService.getPackagingSearchResults(term, filters),
	paginatePackagingResults: (page: number) => async (dispatch: Dispatch) => {
		dispatch(paginateAction(search.UPDATE_PACKAGING_PAGINATION, page));
	},
	getPackagingPreferences: () => portfolioService.getPackaging()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
