import React from "react";
import Partners from "../../routes/integration/images";

export enum PORTALS {
	sap = "SAP",
	microsoft = "Microsoft"
}

interface IProps {
	portal?: PORTALS;
}

const PortalWrapper: React.FC<IProps> = ({ portal = "SAP", ...props }) => {
	const getPartnerImage = () => {
		switch (portal) {
			case "SAP":
				return Partners.sap.logo;
			default:
				return "";
		}
	};
	return (
		<div className={"portal-wrapper"}>
			<div className={"partner-img"}>
				<img src={getPartnerImage()} />
			</div>
			<h5>Partner Portal</h5>
		</div>
	);
};

export default PortalWrapper;
