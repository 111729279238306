import React from "react";
import { DollarSign } from "react-feather";

export const renderCostScore = (cost: string, size: number = 18) => {
	switch (cost) {
		case "1":
			return (
				<>
					<DollarSign size={size} className="mr-2 color-bg-green" />
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
				</>
			);
		case "2":
			return (
				<>
					<DollarSign size={size} className="mr-2 color-bg-green" />
					<DollarSign size={size} className="mr-2 color-bg-green" />
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
				</>
			);
		case "3":
			return (
				<>
					<DollarSign size={size} className="mr-2 color-bg-green" />
					<DollarSign size={size} className="mr-2 color-bg-green" />
					<DollarSign size={size} className="mr-2 color-bg-green" />
				</>
			);
		default:
			return (
				<>
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
					<DollarSign size={size} className="mr-2 color-battleship-grey" />
				</>
			);
	}
};
