import React, { useEffect, useState } from "react";
import { Percent, Users } from "react-feather";
import { Card, CardBody } from "reactstrap";
import SuitabilityIcon from "../../../assets/images/suitability-score-icon2.svg";
import PackagingIcon from "../../../assets/images/icon-nav-packaging.svg";
import SustainabilityScore from "../../../assets/images/sustainability-scale-icon.svg";
import HelpTip from "../../../components/common/helpTip";

interface IProps {}

const PortfolioScore: React.FC<IProps> = (props) => {
	const [barGrow, setBarGrow] = useState<boolean>();
	const [overlay, setOverlay] = useState<boolean>();

	useEffect(() => {
		setTimeout(() => {
			setBarGrow(true);
		}, 500);
	});

	return (
		// <Card className='portfolio-score' onMouseEnter={() => setOverlay(true)} onMouseLeave={() => setOverlay(false)}>
		<Card className="portfolio-score">
			{overlay ? (
				<div className="coming-soon">
					<p>Coming Soon</p>
				</div>
			) : (
				<> </>
			)}
			<CardBody>
				<h4>
					<span>Portfolio Score</span>
					<HelpTip
						id="portfolio-score-help"
						style={{ marginLeft: 10 }}
						message={
							"Set preferences in your account settings and start developing products to improve your portfolio scores"
						}
					/>
				</h4>
				<div className="score">
					<h1 style={{ color: "#e1ecf3" }}>-</h1> {/*<Percent size={72}/> */}
				</div>
				<div className={"score-graph"}>
					<div className={"graph"}>
						<div className={`bar ' + ${barGrow ? "load" : ""}`}>
							<img src={SustainabilityScore} />
							<div style={{ height: "5%", backgroundColor: "#0ed7ac" }}></div>
						</div>
						<div className={`bar ' + ${barGrow ? "load" : ""}`}>
							<img src={PackagingIcon} />
							<div style={{ height: "5%", backgroundColor: "#3f65f1" }}></div>
						</div>
						<div className={`bar ' + ${barGrow ? "load" : ""}`}>
							<img src={SuitabilityIcon} />
							<div style={{ height: "5%", backgroundColor: "#7c4aa9" }}></div>
						</div>
						<div className={`bar ' + ${barGrow ? "load" : ""}`}>
							<Users color={"#f4a850"} />
							<div style={{ height: "5%", backgroundColor: "#f4a850" }}></div>
						</div>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default PortfolioScore;
