import React, { useEffect, useState } from "react";
import SuitabilityBar from "../../../components/suitabilityBar";
import { connect } from "react-redux";
import productService from "../../../services/productService";
import { IProduct } from "../../../interfaces/products";
import { Table, Button } from "reactstrap";
import {
	ArrowRight,
	Box,
	ShoppingCart,
	Sliders,
	ChevronDown,
	Loader as LoaderIcon
} from "react-feather";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IApplicationState } from "../../../redux/reducers";
import Loader from "../../../components/loader";
import { IPaginateList } from "../../../interfaces/pagination";
import { routes } from "../../routes";
import Pagination from "../../../components/pagination";
import { paginateAction } from "../../../redux/actions/pagination/pagination";
import { Dispatch } from "redux";
import { product } from "../../../redux/actions/actionContants";
import { IUser } from "../../../interfaces/user";
import Upgrade from "../../../components/common/upgrade";
import ProgressGradient from "../../../components/common/progressGradient";
import { IBrand } from "../../../interfaces/company";
import ReactImageFallback from "react-image-fallback";
import { getIngredientImagePlaceholder } from "../../../utils/ingredient";
import { toCamelCase } from "../../../utils/string";
import { TableSkeleton } from "../../../utils/ghostUI";

interface IProps extends RouteComponentProps {
	active: IPaginateList<IProduct[]>;
	concept: IPaginateList<IProduct[]>;
	viewType: String;
	getActiveProducts: () => Promise<any>;
	getConceptProducts: () => Promise<any>;
	allProducts?: boolean;
	paginateConceptProducts: (page: number) => void;
	paginateActiveProducts: (page: number) => void;
	profile?: IUser | undefined;
	selected_brand: IBrand;
}

const ProductList: React.FC<IProps> = ({
	concept: { list, pagination },
	paginateConceptProducts,
	paginateActiveProducts,
	...props
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [productViewType, setProductViewType] = useState<String>(props.viewType || "active");
	// Keep a reference of all brand, market, and types for retail or concept products
	const [brands, setBrands] = useState<(string | undefined)[]>([]);
	const [markets, setMarkets] = useState<(string | undefined)[]>([]);
	const [types, setTypes] = useState<(string | undefined)[]>([]);

	useEffect(() => {
		setLoading(true);
		Promise.all([props.getActiveProducts(), props.getConceptProducts()]).finally(() => {
			setLoading(false);
		});
	}, [pagination.page]);

	useEffect(() => {
		setLoading(true);
		props.getActiveProducts().finally(() => {
			setLoading(false);
		});
	}, [props.active.pagination.page]);

	useEffect(() => {
		setLoading(true);
		Promise.all([props.getActiveProducts(), props.getConceptProducts()]).finally(() =>
			setLoading(false)
		);
	}, [props.selected_brand]);

	// Update filter options when switching between retail/concept products
	useEffect(() => {
		switch (productViewType) {
			case "active":
				getProductBrands(props.active.list);
				getProductMarkets(props.active.list);
				getProductTypes(props.active.list);
				break;
			case "concept":
				getProductBrands(list);
				getProductMarkets(list);
				getProductTypes(list);
				break;
		}
	}, [props.active?.list, list, productViewType]);

	const getProductBrands = (products: IProduct[]) => {
		let all = products
			.filter((p) => p.brand?.name != null || p.brand?.name != undefined)
			.map((p) => p.brand?.name);
		let uniq = all.filter((value, index, array) => {
			return array.indexOf(value) == index;
		});
		setBrands(uniq);
	};

	const getProductMarkets = (products: IProduct[]) => {
		let all = products
			.filter((p) => p.country?.alpha_3 != null || p.country?.alpha_3 != undefined)
			.map((p) => p.country?.alpha_3);
		let uniq = all.filter((value, index, array) => {
			return array.indexOf(value) == index;
		});
		setMarkets(uniq);
	};

	const getProductTypes = (products: IProduct[]) => {
		let all = products
			.filter((p) => p.product_type?.title != null || p.product_type?.title != undefined)
			.map((p) => p.product_type?.title);
		let uniq = all.filter((value, index, array) => {
			return array.indexOf(value) == index;
		});
		setTypes(uniq);
	};

	const filterProducts = () => {
		let products = props.allProducts
			? productViewType == "active"
				? props.active.list
				: list
			: props.viewType == "active"
			? props.active.list
			: list;
		products = filterMostRecent(products);

		return products;
	};

	const filterMostRecent = (products: IProduct[]) => {
		let mostRecent: IProduct[] = [];
		products.forEach((product: IProduct) => {
			const currProduct = mostRecent.find((p: IProduct) => p.name == product.name);
			const currIndex = currProduct ? mostRecent.indexOf(currProduct) : -1;
			currProduct && currIndex > -1 && product.version > currProduct.version
				? (mostRecent[currIndex] = product)
				: mostRecent.push(product);
		});
		return mostRecent;
	};

	return (
		<div className="dash-list">
			{props.allProducts ? (
				<div style={{ paddingTop: 45 }}>
					<span className="title mb-2">
						<h4 className="mr-2 font-weight-semibold">
							{productViewType == "active" ? "Active Products" : "Concept Products"}
						</h4>
						<h4 className="count color-bg-blue font-weight-normal">
							{props.profile?.company?.subscription_type == "supplier" ? (
								"-"
							) : (
								<>
									{loading ? (
										<LoaderIcon className={"fa-spin"} />
									) : productViewType == "active" ? (
										props.active.pagination.total
									) : (
										pagination.total
									)}
								</>
							)}
						</h4>
						<span className="filter-buttons">
							<Button
								color="primary"
								onClick={() => setProductViewType("active")}
								className={`btn-round ml-2 ${productViewType === "active" ? "" : "inactive"}`}
							>
								<ShoppingCart className="mr-2" size={18} />
								Retail
							</Button>
							<Button
								color="warning"
								onClick={() => setProductViewType("concept")}
								className={`btn-round ml-2 color-white ${
									productViewType === "concept" ? "" : "inactive"
								}`}
							>
								<Box className="mr-2" size={18} />
								Concept
							</Button>
						</span>
						{productViewType == "concept" ? (
							<Pagination
								pages={pagination.pages}
								page={pagination.page}
								size={pagination.size}
								total={pagination.total}
								loading={loading}
								handlePageChange={paginateConceptProducts}
							/>
						) : (
							<Pagination
								pages={props.active.pagination.pages}
								page={props.active.pagination.page}
								size={props.active.pagination.size}
								total={props.active.pagination.total}
								loading={loading}
								handlePageChange={paginateActiveProducts}
							/>
						)}
					</span>
					{/* <span className={"filters"}>
						<span className={"filter-title"}>
							<Sliders color={"#657280"} size={24} /> Filters:{" "}
						</span>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("market")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={marketFilters.length > 0 ? "filter-menu active" : "filter-menu"}>
								Market
								<ChevronDown size={16} />
							</span>
							{filterMenu == "market" ? (
								<ul className="list-filters">
									{markets.length > 0 ? (
										markets.map((market) => {
											return market != undefined ? (
												<li
													className={marketFilters.includes(market) ? "active" : ""}
													onClick={(e) => {
														handleFilterChange(e.currentTarget.innerHTML, "market");
													}}
												>
													{market}
												</li>
											) : (
												<></>
											);
										})
									) : (
										<li>No {productViewType == "active" ? "retail" : "concept"} markets</li>
									)}
								</ul>
							) : (
								""
							)}
						</div>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("brand")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={brandFilters.length > 0 ? "filter-menu active" : "filter-menu"}>
								Brand
								<ChevronDown size={16} />
							</span>
							{filterMenu == "brand" ? (
								<ul className="list-filters">
									{brands.length > 0 ? (
										brands.map((brand) => {
											return brand != undefined ? (
												<li
													className={brandFilters.includes(brand) ? "active" : ""}
													onClick={(e) => {
														handleFilterChange(e.currentTarget.innerHTML, "brand");
													}}
												>
													{brand}
												</li>
											) : (
												<></>
											);
										})
									) : (
										<li>No {productViewType == "active" ? "retail" : "concept"} brands</li>
									)}
								</ul>
							) : (
								""
							)}
						</div>
						<div
							className="filter"
							onMouseOver={() => toggleFilterMenu("type")}
							onMouseLeave={() => toggleFilterMenu(undefined)}
						>
							<span className={typeFilters.length > 0 ? "filter-menu active" : "filter-menu"}>
								Type
								<ChevronDown size={16} />
							</span>
							{filterMenu == "type" ? (
								<ul className="list-filters">
									{types.length > 0 ? (
										types.map((type) => {
											return type != undefined ? (
												<li
													className={typeFilters.includes(type) ? "active" : ""}
													onClick={(e) => {
														handleFilterChange(e.currentTarget.innerHTML, "type");
													}}
												>
													{type}
												</li>
											) : (
												<></>
											);
										})
									) : (
										<li>No {productViewType == "active" ? "retail" : "concept"} types</li>
									)}
								</ul>
							) : (
								""
							)}
						</div>
					</span> */}
				</div>
			) : (
				<></>
			)}
			{props.profile?.company.subscription_type == "supplier" ? (
				<div className="mt-4">
					<Upgrade />
				</div>
			) : (
				<Table borderless>
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Flavor</th>
							<th>Brand</th>
							<th>Type</th>
							<th></th>
							<th></th>
							<th>Status</th>
							<th>Preference Score</th>
						</tr>
					</thead>
					{loading ? (
						<TableSkeleton />
					) : (
						<tbody>
							{/* { (productViewType === 'active' ? props.active : list).map((p, index) => {  */}
							{filterProducts().map((p, index) => {
								// console.log(p);
								return (
									<tr
										className="list-row"
										onClick={() => props.history.push(`${routes.PRODUCTS}/${p.id}`)}
										id={p.active ? "retail" : "concept"}
										key={index}
									>
										<td>
											<ReactImageFallback
												style={{ borderRadius: 6 }}
												src={p.image_uri}
												fallbackImage={getIngredientImagePlaceholder()}
												alt="Product Image"
											/>
										</td>
										<td className="product-name">
											<span>{p.name}</span>
											<span className={"label label-journey-ai"}>AI</span>
										</td>
										<td>
											<span>{p.flavor ? toCamelCase(p.flavor) : ""}</span>
										</td>
										<td>{p.brand?.name}</td>
										<td>{p.product_type?.title}</td>
										<td>{"v" + p.version}</td>
										<td>
											<i
												className={`${p.country?.alpha_2?.toLowerCase() || "us"} flag list-flag`}
											></i>
										</td>
										<td>
											<span
												className={`label label-${
													productViewType === "active" ? "retail-product" : "concept-product"
												}`}
											>
												{productViewType === "active" ? "Retail" : "Concept"}
											</span>
										</td>
										<td>
											{/* <SuitabilityBar title={' '} className="persona-suitability" type={'preference'} value={ 50 || 0} /> */}
											<ProgressGradient color={"#7c4aa9"} value={50 || 0} height={8} />
										</td>
										<td align="right">
											<span className="action">
												<ArrowRight size={17} color={"#4974f3"} className="mr-1" />
												View
											</span>
										</td>
									</tr>
								);
							})}
						</tbody>
					)}
				</Table>
			)}
		</div>
	);
};

const mapStateToProps = (state: IApplicationState) => ({
	active: state.product.active,
	concept: state.product.concept,
	profile: state.user.profile,
	selected_brand: state.user.selected_brand
});

const mapDispatchToProps = {
	getActiveProducts: () => productService.getActiveProducts(),
	getConceptProducts: () => productService.getConceptProducts(),
	paginateConceptProducts: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(product.PAGINATE_CONCEPT_PRODUCT, page));
	},
	paginateActiveProducts: (page: number) => (dispatch: Dispatch) => {
		dispatch(paginateAction(product.PAGINATE_ACTIVE_PRODUCT, page));
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductList));
